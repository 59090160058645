import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { UserService } from 'app/shared/services/user.service';
declare const window: any;

@Component({
    selector: 'app-get-started-signup-completed-started',
    templateUrl: './get-started-signup-completed.component.html',
    styleUrls: ['./get-started-signup-completed.component.scss']
})
export class GetStartedSignupCompletedComponent implements OnInit {
    locationId;
    constructor(private userService: UserService, private authService: NbAuthService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.refreshUserAuthToken();
        this.setLocationId();
    }

    setLocationId() {
        this.locationId = this.route.snapshot.queryParams?.locationId;
    }

    refreshUserAuthToken() {
        this.userService.refreshToken().subscribe(res => {
            this.authService.refreshToken('email').subscribe();
        });
    }

    onGoToMenuClick() {
        window.pendo.showGuideById('f-fofyNGMm2al1pBd_s_lyn5U78');
    }
}
