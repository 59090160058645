import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OnBoardingService } from 'app/shared/services/on-boarding.service';

@Component({
    selector: 'app-get-started-contact-info',
    templateUrl: './get-started-contact-info.component.html',
    styleUrls: ['./get-started-contact-info.component.scss']
})
export class GetStartedContactInfoComponent implements OnInit {
    @ViewChild('firstFocus') firstFocusEl: ElementRef;
    public form: UntypedFormGroup;
    public isComponentLoading = false;
    public errorMessage;
    public countryCode = this.route.snapshot.queryParams?.countryCode;
    public orgNumber = this.route.snapshot.queryParams?.orgNumber;
    public pricingPlanName = this.route.snapshot.queryParams?.pricingPlanName;

    public postModel = {
        locationDetails: {
            locationName: this.route.snapshot.queryParams?.locationName,
            locationType: this.route.snapshot.queryParams?.locationType,
            useCase: this.route.snapshot.queryParams?.useCase
        },
        contactDetails: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: ''
        },
        referralDetails: {
            referralSourceId: this.route.snapshot.queryParams?.referralSourceId
        }
    };

    constructor(
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private onboardingService: OnBoardingService
    ) {}

    ngOnInit() {
        if (
            !this.countryCode ||
            !this.orgNumber ||
            !this.postModel.locationDetails.locationName ||
            !this.postModel.locationDetails.locationType ||
            !this.postModel.locationDetails.useCase
        ) {
            this.router.navigate(['get-started']);
        }

        this.initForm();
        this.setFocus();
    }

    setFocus() {
        setTimeout(() => {
            this.firstFocusEl && this.firstFocusEl.nativeElement.focus();
        });
    }

    private initForm() {
        this.form = this.fb.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required]],
            phoneNumber: ['', [Validators.required]]
        });
    }

    goToNextStep() {
        this.isComponentLoading = true;
        this.postModel.contactDetails = this.form.getRawValue();
        this.onboardingService.signup(this.countryCode, this.orgNumber, this.pricingPlanName, this.postModel).subscribe(
            res => {
                this.isComponentLoading = false;
                this.router.navigate(['signup-requested'], { relativeTo: this.route.parent });
            },
            err => {
                this.isComponentLoading = false;
                this.errorMessage = err.error?.message ?? 'Something went wrong.';
            }
        );
        // call the API to save the data.
    }
}
