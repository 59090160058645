import { Component } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { HideAccountStatusBarDialogComponent } from '../hide-account-status-bar-dialog/hide-account-status-bar-dialog.component';

@Component({
    selector: 'hide-account-status-bar-button',
    templateUrl: './hide-account-status-bar-button.component.html',
    styleUrls: ['./hide-account-status-bar-button.component.scss']
})
export class HideAccountStatusBarButtonComponent {
    constructor(private dialogService: NbDialogService) {}

    openHideAccountStatusBarDialog() {
        this.dialogService.open(HideAccountStatusBarDialogComponent);
    }
}
