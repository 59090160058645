import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared';
import { CircularProgressIndicatorComponent } from './circular-progress-indicator.component';

@NgModule({
    imports: [SharedModule],
    declarations: [CircularProgressIndicatorComponent],
    exports: [CircularProgressIndicatorComponent]
})
export class CircularProgressIndicatorComponentModule {}
