import { Store } from '@ngrx/store';
import { OnBoardingService } from 'app/shared/services/on-boarding.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { UserService } from 'app/shared/services/user.service';
import { AuthenticatedUser } from 'app/shared/interfaces/auth/simple-user';
import { GettingStarted, selectPlan } from '../../state';
import { resetEquipment, setEquipmentFullyDigital } from '../../state/getting-started.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { Equipment } from '../../../../../shared/interfaces/onboarding';

@Component({
    selector: 'app-get-started-select-equipment',
    templateUrl: './get-started-select-equipment.component.html',
    styleUrls: ['./get-started-select-equipment.component.scss']
})
export class GetStartedSelectEquipmentComponent implements OnInit {
    public equipment: Observable<Equipment[]>;
    public user: AuthenticatedUser;
    public isAnyEquipmentSelected;
    public isEquipmentFullyDigital;
    constructor(
        private userService: UserService,
        private router: Router,
        private onboardingService: OnBoardingService,
        private route: ActivatedRoute,
        private store: Store<{ gettingStarted: GettingStarted }>
    ) {}

    async ngOnInit() {
        this.guardRoute();
        await this.setAuthenticatedUser();
        this.setEquipment();
        this.setIsAnyEquipmentSelected();

        this.store.select('gettingStarted', 'selectEquipmentFullyDigital').subscribe(res => {
            this.isEquipmentFullyDigital = res;
        });
    }

    setIsAnyEquipmentSelected() {
        this.store.select('gettingStarted', 'equipmentOrders').subscribe(res => {
            if (!res) return;
            this.isAnyEquipmentSelected = res.some(x => x.amount);
        });
    }

    handleFullyDigitalClicked() {
        if (this.isAnyEquipmentSelected) return;

        this.store.dispatch(setEquipmentFullyDigital());
    }

    guardRoute() {
        this.store.select(selectPlan).subscribe(res => {
            if (!res) this.router.navigate(['select-pricing-plan'], { relativeTo: this.route.parent });
        });
    }

    setAuthenticatedUser() {
        return this.userService
            .getAuthenticatedUser()
            .toPromise()
            .then(res => (this.user = res));
    }

    setEquipment() {
        this.equipment = this.onboardingService.getEquipment(this.user.onboardingSession.countryCode);
    }

    handleBuyClick() {
        this.router.navigate(['complete-business-details'], { relativeTo: this.route.parent });
    }

    handleSkipClick() {
        this.router.navigate(['complete-business-details'], { relativeTo: this.route.parent });
        this.store.dispatch(resetEquipment());
    }
}
