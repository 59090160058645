import { AuthenticatedUser } from '../interfaces/auth/simple-user';

export const initializePendo = (authenticatedUser?: AuthenticatedUser, locationGroupId?: number): void => {
    const { pendo } = window as any;
    if (!pendo || !authenticatedUser) {
        return;
    }

    pendo.initialize({
        visitor: {
            id: authenticatedUser.userName,
            email: authenticatedUser.userName,
            role: authenticatedUser.userType || 'UNKNOWN'
        },
        account: {
            id: locationGroupId
        }
    });
};
