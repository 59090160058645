import { NbAuthOAuth2JWTToken } from '@nebular/auth';
import jwtDecode from 'jwt-decode';

export class FavritSSOToken extends NbAuthOAuth2JWTToken {
    static NAME = 'nb:auth:favrit:token';

    getValue(): string {
        return this.payload.access_token;
    }

    getRefreshToken(): string {
        return this.payload.refresh_token;
    }
}

const hasTokenExpired = (token: string | null) => {
    if (!token?.length) {
        return true;
    }

    try {
        const decodedToken = jwtDecode<{ exp: number }>(token);
        const { exp: expirationTime } = decodedToken;
        const now = Date.now() / 1000;
        const tokenHasExpired = expirationTime < now;
        return tokenHasExpired;
    } catch (e) {
        return true;
    }
};
