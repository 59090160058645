import { HostListener, Injectable } from '@angular/core';
import {
    NbAuthIllegalTokenError,
    NbAuthRefreshableToken,
    NbAuthResult,
    NbAuthStrategyClass,
    NbOAuth2AuthStrategy,
    NbOAuth2AuthStrategyOptions,
    NbOAuth2ResponseType
} from '@nebular/auth';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FavritSSOToken } from './FavritSSOToken';

export const getRandomString = (length: number): string => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const uInt8ToAscii = (number: number): string =>
        randomChars.charAt(Math.floor((number / 255) * randomChars.length));
    const randomArray = Array.from(window.crypto.getRandomValues(new Uint8Array(length)));
    return randomArray.map(uInt8ToAscii).join('');
};

@Injectable({
    providedIn: 'root'
})
class FavritSSOStrategy extends NbOAuth2AuthStrategy {
    static setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
        return [FavritSSOStrategy, options];
    }

    buildRedirectUrl(type: 'login' | 'pin' = 'login', locationId?: string) {
        const params = {
            response_type: this.getOption('authorize.responseType'),
            client_id: this.getOption('clientId'),
            redirect_url: this.getOption('authorize.redirectUri'),
            nonce: this.generateNonce(),
            location_id: locationId,

            ...this.getOption('authorize.params')
        };

        const endpoint = this.getActionEndpoint('authorize');
        const query = this.urlEncodeParameters(this.cleanParams(params));

        const path = type === 'pin' ? 'pin' : '';

        return `${endpoint}/${path}?${query}`;
    }

    protected generateNonce() {
        const randomString = getRandomString(20);
        sessionStorage.setItem('nonce', randomString);
        return randomString;
    }

    protected buildRefreshRequestData(token: NbAuthRefreshableToken) {
        return {
            payload: {
                refresh_token: token.getRefreshToken()
            }
        };
    }

    protected redirectResults: { [key: string]: Function } = {
        [NbOAuth2ResponseType.TOKEN]: () => {
            return of(this.route.snapshot.queryParams).pipe(
                map((params: any) => {
                    return !!(params && (params['refresh-token'] || params.error));
                })
            );
        }
    };

    protected getRedirect = () => {
        const redirect = sessionStorage.getItem('redirect');

        if (!redirect) {
            return '/';
        }

        if (redirect?.startsWith('auth/')) {
            return '/';
        }

        return redirect;
    };

    protected redirectResultHandlers: { [key: string]: Function } = {
        [NbOAuth2ResponseType.TOKEN]: () => {
            sessionStorage.setItem('TOKEN_CREATED', Date.now().toString());
            return of(this.route.snapshot.queryParams).pipe(
                map((params: any) => {
                    const nonceMatches = sessionStorage.getItem('nonce') === params.nonce;
                    if (!params.error && nonceMatches) {
                        return new NbAuthResult(
                            true,
                            params,
                            this.getRedirect(),
                            [],
                            this.getOption('defaultMessages'),
                            new FavritSSOToken(
                                {
                                    access_token: params['access-token'],
                                    refresh_token: params['refresh-token']
                                },
                                'sso',
                                new Date(0)
                            )
                        );
                    }
                    return new NbAuthResult(
                        false,
                        params,
                        this.getOption('redirect.failure'),
                        this.getOption('defaultErrors'),
                        []
                    );
                }),
                catchError(err => {
                    const errors = [];
                    if (err instanceof NbAuthIllegalTokenError) {
                        errors.push(err.message);
                    } else {
                        errors.push('Something went wrong.');
                    }
                    return of(new NbAuthResult(false, err, this.getOption('redirect.failure'), errors));
                })
            );
        }
    };
}

export default FavritSSOStrategy;
