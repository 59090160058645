import { NgModule } from '@angular/core';
import { GetStartedContactUsFormContainerPrivateComponent } from './get-started-contact-us-form-container-private.component';
import { SharedModule } from 'app/shared';
import { GetStartedContactUsFormModule } from '../contact-us-form';
import { NbLayoutModule } from '@nebular/theme';

@NgModule({
    imports: [SharedModule, GetStartedContactUsFormModule, NbLayoutModule],
    declarations: [GetStartedContactUsFormContainerPrivateComponent],
    exports: [GetStartedContactUsFormContainerPrivateComponent]
})
export class GetStartedContactUsFormContainerPrivateModule {}
