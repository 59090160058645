import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CanLoadPagesGuard {
    constructor(private authService: NbAuthService, private router: Router) {}

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.isAuthenticatedOrRefresh().pipe(
            tap(authenticated => {
                if (!authenticated) {
                    sessionStorage.setItem('redirect', window.location.hash.slice(2));
                    this.router.navigate(['auth/login']);
                }
            })
        );
    }
}
