<ng-container *transloco="let t">
    <nb-layout center class="colored-layout-white fullWidth">
        <nb-layout-column>
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-sm-8 col-lg-6 m-auto">
                        <div class="mb-50">
                            <nb-progress-bar
                                [value]="50"
                                status="primary"
                                [displayValue]="true"
                                size="tiny"
                            ></nb-progress-bar>
                        </div>
                        <div class="d-flex justify-content-center">
                            <favrit-logo></favrit-logo>
                        </div>
                        <div class="text-center section-spacer">
                            <h1>Enter some details about your business</h1>
                        </div>
                        <ng-container *ngIf="form">
                            <form [formGroup]="form" (ngSubmit)="handleContinueClick()">
                                <div class="mb-50">
                                    <div class="form-group">
                                        <h4 class="mb-20">What is the legal name of your business?</h4>
                                        <label class="label" for="orgNr">{{ t('global.org_number_label') }}</label>
                                        <ng-container *ngIf="user?.onboardingSession?.orgNumber">
                                            <p id="orgNr" class="pre-filled">
                                                {{ user?.onboardingSession?.orgNumber }}
                                            </p>
                                        </ng-container>
                                        <ng-container *ngIf="!user?.onboardingSession?.orgNumber">
                                            <input
                                                type="text"
                                                id="orgNr"
                                                formControlName="orgNumber"
                                                fullWidth
                                                nbInput
                                            />
                                        </ng-container>
                                    </div>

                                    <div class="form-group">
                                        <label class="label" for="legalName">Legal name</label>
                                        <input type="text" #firstFocus formControlName="legalName" fullWidth nbInput />
                                    </div>
                                </div>
                                <div class="mb-50">
                                    <div class="form-group">
                                        <h4 class="mb-20">How should we present your business to Favrit users?</h4>
                                        <p>
                                            <strong>Don't worry</strong>, we won't show your business on
                                            <a target="_blank" href="https://favrit.com">Favrit</a> yet. You will decide
                                            when to "go live" later.
                                        </p>
                                        <label class="label" for="locationName">Establishment name</label>
                                        <input
                                            type="text"
                                            [placeholder]="
                                                user?.onboardingSession?.locationName
                                                    ? user?.onboardingSession?.locationName
                                                    : 'E.g John\'s Bar'
                                            "
                                            formControlName="locationName"
                                            fullWidth
                                            nbInput
                                        />
                                    </div>
                                    <div class="form-group mb-30">
                                        <label class="label" for="locationType">Establishment type</label>
                                        <ng-container *ngIf="user?.onboardingSession?.locationType">
                                            <ng-container *ngIf="locationTypes | async as locations">
                                                <nb-select fullWidth formControlName="locationType">
                                                    <nb-option *ngFor="let loc of locations" [value]="loc.name">{{
                                                        loc.name
                                                    }}</nb-option>
                                                </nb-select>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="!user?.onboardingSession?.locationType">
                                            <ng-container *ngIf="locationTypes | async as locations">
                                                <nb-select
                                                    fullWidth
                                                    formControlName="locationType"
                                                    placeholder="Select establishment type"
                                                >
                                                    <nb-option *ngFor="let loc of locations" [value]="loc.name">{{
                                                        loc.name
                                                    }}</nb-option>
                                                </nb-select>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="form-group mb-30">
                                        <p>
                                            Which city do you want your venue presented under on
                                            <a target="_blank" href="https://favrit.com">Favrit</a>?
                                        </p>
                                        <nb-select
                                            fullWidth
                                            class="mb-10"
                                            formControlName="localityId"
                                            placeholder="Select city"
                                        >
                                            <nb-option *ngFor="let loc of localities" [value]="loc.id">{{
                                                loc.name
                                            }}</nb-option>
                                        </nb-select>

                                        <div class="text-right">
                                            <a
                                                nbTool
                                                href="javascript:void(0)"
                                                class="text-right"
                                                nbPopoverTrigger="hover"
                                                [nbPopover]="cityPopover"
                                                >Can't find the city your venue is in?</a
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <p>
                                            When you go live we will show this address on
                                            <a target="_blank" href="https://favrit.com">Favrit</a>. We will also send
                                            any equipment you ordered in the previous step to this same address.
                                        </p>
                                        <label class="label" for="address">Address</label>
                                        <input type="text" formControlName="address" fullWidth nbInput />
                                    </div>
                                    <div class="form-group">
                                        <label class="label" for="zipCode">Zip code</label>
                                        <input type="text" formControlName="zipCode" fullWidth nbInput />
                                    </div>
                                    <div class="form-group">
                                        <label class="label" for="city">City</label>
                                        <input type="text" formControlName="city" fullWidth nbInput />
                                    </div>
                                </div>
                                <div class="mb-50">
                                    <bank-information [form]="form"></bank-information>
                                </div>
                                <div class="form-group">
                                    <button nbButton fullWidth [disabled]="form.invalid" type="submit" status="primary">
                                        Continue
                                    </button>
                                </div>
                            </form>
                        </ng-container>
                    </div>
                </div>
            </div>
        </nb-layout-column>
    </nb-layout>

    <ng-template #cityPopover>
        <div class="p-20 city-popover">
            <p>
                Simply select the city which is closest to your venue and continue the signup flow. Don't worry, you
                venue won't be visible on <a target="_blank" href="https://favrit.com">Favrit</a> until you tell us to
                "go live" later, so this is just a temporary solution to get you started for now.
            </p>
            <p>
                After you have completed the signup please get in touch with us and we will add your city and change
                your venue to the correct city. When we have changed the city of the venue we can help you go live at
                <a target="_blank" href="https://favrit.com">Favrit!</a>
            </p>
        </div>
    </ng-template>
</ng-container>
