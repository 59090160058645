import {
    selectBusinessDetails,
    selectEquipmentList,
    selectEquipmentSkipped,
    selectIsEquipmentSkippedOrSet
} from './../../state/index';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LocationType } from 'app/shared/interfaces/onboarding';
import { OnBoardingService } from 'app/shared/services/on-boarding.service';
import { Observable } from 'rxjs-compat';
import { UserService } from 'app/shared/services/user.service';
import { AuthenticatedUser } from 'app/shared/interfaces/auth/simple-user';
import { GettingStarted, selectPlan } from '../../state';
import { Store } from '@ngrx/store';
import { setCompleteBusinessDetails } from '../../state/getting-started.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalityService } from 'app/shared/services/locality.service';
import { SimpleLocality } from 'app/shared/interfaces/simple-locality';

@Component({
    selector: 'app-get-started-complete-business-details',
    templateUrl: './get-started-complete-business-details.component.html',
    styleUrls: ['./get-started-complete-business-details.component.scss']
})
export class GetStartedCompleteBusinessDetailsComponent implements OnInit {
    @ViewChild('firstFocus') firstFocusEl: ElementRef;
    public locationTypes: Observable<LocationType[]> = this.onBoardingService.getLocationTypes();
    public localities: SimpleLocality[];
    public form: UntypedFormGroup;
    public user: AuthenticatedUser;

    constructor(
        private userService: UserService,
        private fb: UntypedFormBuilder,
        private localityService: LocalityService,
        private router: Router,
        private store: Store<{ gettingStarted: GettingStarted }>,
        private onBoardingService: OnBoardingService,
        private route: ActivatedRoute
    ) {}

    async ngOnInit() {
        this.guardRoute();
        await this.setAuthenticatedUser();
        await this.setLocalities();
        this.initForm();
        this.setFocus();
    }

    setFocus() {
        setTimeout(() => {
            this.firstFocusEl && this.firstFocusEl.nativeElement.focus();
        });
    }

    setLocalities() {
        return this.localityService
            .getSimpleLocalities(this.user.onboardingSession.countryCode)
            .toPromise()
            .then(res => {
                this.localities = res;
            });
    }

    guardRoute() {
        this.store.select(selectIsEquipmentSkippedOrSet).subscribe(res => {
            if (!res) this.router.navigate(['select-pricing-plan'], { relativeTo: this.route.parent });
        });
    }

    setAuthenticatedUser() {
        return this.userService
            .getAuthenticatedUser()
            .toPromise()
            .then(res => (this.user = res));
    }

    handleContinueClick() {
        this.store.dispatch(setCompleteBusinessDetails({ model: this.form.getRawValue() }));
        this.router.navigate(['complete-signup'], { relativeTo: this.route.parent });
    }

    private initForm() {
        this.form = this.fb.group({
            orgNumber: [
                { value: this.user?.onboardingSession?.orgNumber ?? '', disabled: true },
                [Validators.required]
            ],
            legalName: ['', [Validators.required]],
            locationName: [this.user?.onboardingSession?.locationName ?? '', [Validators.required]],
            locationType: [this.user?.onboardingSession?.locationType ?? '', [Validators.required]],
            address: ['', [Validators.required]],
            zipCode: ['', [Validators.required]],
            city: ['', [Validators.required]],
            accountNumber: [''],
            localityId: ['', [Validators.required]],
            bankGiro: [''],
            bankName: ['']
        });
    }
}
