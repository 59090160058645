import { SharedModule } from 'app/shared';
import { NgModule } from '@angular/core';
import { GetStartedOrdrReferralComponent } from './get-started-ordr-referral.component';
import { FormsModule } from '@angular/forms';
import { NbLayoutModule } from '@nebular/theme';

@NgModule({
    imports: [NbLayoutModule, SharedModule, FormsModule],
    declarations: [GetStartedOrdrReferralComponent],
    exports: [GetStartedOrdrReferralComponent]
})
export class GetStartedOrdrReferralModule {}
