import { SelectEquipmentItemModule } from './select-equipment-item/index';
import { SharedModule } from './../../../../../shared/index';
import { NgModule } from '@angular/core';
import { GetStartedSelectEquipmentComponent } from './get-started-select-equipment.component';
import { NbLayoutModule, NbProgressBarModule } from '@nebular/theme';

@NgModule({
    imports: [SharedModule, NbLayoutModule, SelectEquipmentItemModule, NbProgressBarModule],
    declarations: [GetStartedSelectEquipmentComponent],
    exports: [GetStartedSelectEquipmentComponent]
})
export class GetStartedSelectEquipmentModule {}
