import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private storageSub = new Subject<StorageEvent>();

    constructor(private ngZone: NgZone) {}

    public monitorKey(key: string, storage: Storage): Observable<StorageEvent> {
        const storageEventListener = (event: StorageEvent) => {
            if (event.storageArea === storage && event.key === key) {
                this.ngZone.run(() => {
                    this.storageSub.next(event);
                });
            }
        };

        window.addEventListener('storage', storageEventListener);
        return this.storageSub.asObservable();
    }
}
