import { NgModule } from '@angular/core';
import { GetStartedSignupRequestedComponent } from './get-started-signup-requested.component';
import { NbLayoutModule } from '@nebular/theme';
import { SharedModule } from 'app/shared';

@NgModule({
    imports: [NbLayoutModule, SharedModule],
    declarations: [GetStartedSignupRequestedComponent]
})
export class GetStartedSignupRequestedModule {}
