import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-get-started-signup-requested',
    templateUrl: './get-started-signup-requested.component.html',
    styleUrls: ['./get-started-signup-requested.component.scss']
})
export class GetStartedSignupRequestedComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
