import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankInformationComponent } from './bank-information.component';

@NgModule({
    imports: [SharedModule, ReactiveFormsModule],
    declarations: [BankInformationComponent],
    exports: [BankInformationComponent]
})
export class BankInformationModule {}
