import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbRadioModule,
    NbSelectModule,
    NbDatepickerModule,
    NbSpinnerModule,
    NbTreeGridModule,
    NbActionsModule,
    NbIconModule,
    NbTabsetModule,
    NbAccordionModule,
    NbAlertModule,
    NbListModule,
    NbPopoverModule,
    NbTooltipModule,
    NbToggleModule,
    NbBadgeModule
} from '@nebular/theme';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { FavritLogoComponent } from './components/favrit-logo/favrit-logo.component';
import { FavritLogoModule } from './components/favrit-logo';

const nbModules = [
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbRadioModule,
    NbSelectModule,
    NbDatepickerModule,
    NbSpinnerModule,
    NbTreeGridModule,
    NbActionsModule,
    NbIconModule,
    NbTabsetModule,
    NbAccordionModule,
    NbAlertModule,
    NbListModule,
    NbPopoverModule,
    NbTooltipModule,
    NbToggleModule,
    NbBadgeModule,
    MatDialogModule,
    NbDateFnsDateModule
];

const localisationModules = [TranslocoModule, TranslocoLocaleModule];

@NgModule({
    exports: [CommonModule, RouterModule, HttpClientModule, nbModules, localisationModules, FavritLogoModule],
    providers: [
        // Add http interceptor?
    ]
})
export class SharedModule {}
