import { NbAuthService } from '@nebular/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-get-started-redirect',
    template: ''
})
export class GetStartedRedirectComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: NbAuthService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        const locale = this.translocoService.getActiveLang();

        this.authService.isAuthenticatedOrRefresh().subscribe(res => {
            if (res) {
                this.router.navigate(['/']);
            } else {
                this.router.navigate([`${locale}/get-started/where-did-you-hear-about-us`], {
                    queryParamsHandling: 'preserve'
                });
            }
        });
    }
}
