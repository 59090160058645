import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigatedAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import * as LocalisationActions from './localisation.actions';
import { RouterStateUrl } from '../router/custom-route-serializer';
import { of } from 'rxjs/internal/observable/of';
import { map, mergeMap, filter } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class LocalisationEffects {
    constructor(private actions: Actions, private translocoService: TranslocoService) {}

    setLocaleFromRoute = createEffect(() =>
        this.actions.pipe(
            ofType(ROUTER_NAVIGATION),
            filter((action: RouterNavigatedAction<RouterStateUrl>) => {
                const localeFromRoute = action.payload.routerState.params['locale'];
                const currentLocale = this.translocoService.getActiveLang();

                return localeFromRoute && localeFromRoute !== currentLocale;
            }),
            map((action: RouterNavigatedAction<RouterStateUrl>) => ({
                type: LocalisationActions.changeLocale.type,
                locale: action.payload.routerState.params['locale']
            }))
        )
    );

    changeLocale = createEffect(() =>
        this.actions.pipe(
            ofType(LocalisationActions.changeLocale.type),
            mergeMap((action: any) => {
                const locale = action.locale;

                if (!this.translocoService.isLang(locale)) {
                    return of({
                        type: LocalisationActions.changeLocaleFailure.type,
                        errorMessage: 'Tried to change locale to unsupported value: ' + locale
                    });
                }

                this.translocoService.setActiveLang(locale);
                return of({ type: LocalisationActions.changeLocaleSuccess.type, locale });
            })
        )
    );
}
