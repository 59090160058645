import { ProgressItem, ProgressItemType } from './progress-item/progress-item';

const mapProgressItem = (progressItem: ProgressItem): ProgressItem => {
    switch (progressItem.type) {
        case ProgressItemType.SHAREHOLDER_CHECK:
            return {
                ...progressItem,
                headline: 'Shareholder/owner check',
                description:
                    'Verifying the identity of all shareholders or ultimate beneficial owners who own 25% or more of your business'
            };
        case ProgressItemType.COMPANY_CHECK:
            return {
                ...progressItem,
                headline: 'Company check',
                description: 'Verifying your company'
            };
        case ProgressItemType.BANK_ACCOUNT_CHECK:
            return {
                ...progressItem,
                headline: 'Bank account check',
                description: 'Verifying if the bank account exists and is owned by your company'
            };
        case ProgressItemType.PHOTO_ID_CHECK:
            return {
                ...progressItem,
                headline: 'Photo ID check',
                description: 'Verifying an identity document of either the account holder or a company shareholder'
            };
    }
};

export const mapProgressItems = (items: ProgressItem[]): ProgressItem[] => items.map(item => mapProgressItem(item));
