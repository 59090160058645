import { NgModule } from '@angular/core';
import { NgxRequestPasswordComponent } from './request-password.component';
import { SharedModule } from '../../../shared';
import { FormsModule } from '@angular/forms';
import { NbAuthModule } from '@nebular/auth';

@NgModule({
    imports: [SharedModule, FormsModule, NbAuthModule],
    declarations: [NgxRequestPasswordComponent],
    exports: [NgxRequestPasswordComponent]
})
export class NgxRequestPasswordModule {}
