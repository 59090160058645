import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LocationType } from 'app/shared/interfaces/onboarding';
import { OnBoardingService } from 'app/shared/services/on-boarding.service';
import { Observable } from 'rxjs/internal/Observable';
import {
    getOrgNumberErrorMessageForSelectedCountry,
    getOrgNumberRegexForSelectedCountry
} from 'app/shared/validators/org-number-validation';

@Component({
    selector: 'app-get-started-business-details',
    templateUrl: './get-started-business-details.component.html',
    styleUrls: ['./get-started-business-details.component.scss']
})
export class GetStartedBusinessDetailsComponent implements OnInit {
    @ViewChild('firstFocus') firstFocusEl: ElementRef;
    public form: UntypedFormGroup;
    public urlHasOrgNrParam = this.route.snapshot.queryParams?.orgNr;
    public locationTypes: Observable<LocationType[]> = this.onBoardingService.getLocationTypes();
    countryCode: string;
    orgNumberRegex: string;
    orgNumberErrorMessage: string;
    orgNumberField: AbstractControl;

    constructor(
        private onBoardingService: OnBoardingService,
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        if (!this.route.snapshot.queryParams?.referralSourceId)
            this.router.navigate(['where-did-you-hear-about-us'], {
                relativeTo: this.route.parent
            });

        this.countryCode = this.route.snapshot.queryParams?.country;
        this.initForm();
        this.setFocus();
    }

    public goToNextStep() {
        this.router.navigate(['enter-contact-info'], {
            relativeTo: this.route.parent,
            queryParams: {
                countryCode: this.form.get('countryCode').value,
                orgNumber: this.form.get('orgNumber').value,
                locationName: this.form.get('locationName').value,
                locationType: this.form.get('locationType').value,
                useCase: this.form.get('useCase').value,
                pricingPlanName: this.route.snapshot.queryParams?.pricingPlanName,
                referralSourceId: this.route.snapshot.queryParams?.referralSourceId
            }
        });
    }

    private initForm() {
        this.form = this.fb.group({
            countryCode: [this.countryCode, [Validators.required]],
            orgNumber: [
                this.route.snapshot.queryParams?.orgNr,
                [Validators.required, Validators.pattern(getOrgNumberRegexForSelectedCountry(this.countryCode))]
            ],
            locationName: ['', [Validators.required]],
            locationType: ['', [Validators.required]],
            useCase: ['', [Validators.required]]
        });
        this.orgNumberField = this.form.controls.orgNumber;
        this.orgNumberErrorMessage = getOrgNumberErrorMessageForSelectedCountry(this.countryCode);
    }

    private setFocus() {
        setTimeout(() => {
            this.firstFocusEl && this.firstFocusEl.nativeElement.focus();
        });
    }
}
