import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class LocaleGuard {
    constructor(private router: Router, private translocoService: TranslocoService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const locale = route.params['locale'];
        if (this.translocoService.isLang(locale)) {
            return true;
        }

        const rerouteUrl = route.routeConfig.path.replace(':locale', this.translocoService.getDefaultLang());
        this.router.navigate([`${rerouteUrl}/get-started`]);
        return false;
    }
}
