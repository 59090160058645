import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PricingPlan } from 'app/shared/interfaces/onboarding';

@Component({
    selector: 'pricing-plan-card',
    templateUrl: './pricing-plan-card.component.html',
    styleUrls: ['./pricing-plan-card.component.scss']
})
export class PricingPlanCardComponent implements OnInit {
    @Input() pricingPlan: PricingPlan;
    @Input() type: string;
    @Output() planSelected: EventEmitter<string> = new EventEmitter<string>();
    constructor() {}

    smallPricingPlanBullets = [
        'Free support',
        'Cancel at any time',
        'Digital menu (QR-code)',
        'Self-service digital ordering',
        'Pay with mobile'
    ];

    mediumPricingPlanBullets = [
        'Free support',
        'Cancel at any time',
        'Digital menu (QR-code)',
        'Self-service digital ordering',
        'Pay with card or mobile',
        'Server ordering'
    ];

    ngOnInit() {}

    handlePlanClick(name: string) {
        this.planSelected.emit(name);
    }
}
