import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../../../../../shared/index';
import { NgModule } from '@angular/core';
import { GetStartedCompleteBusinessDetailsComponent } from './get-started-complete-business-details.component';
import { NbLayoutModule, NbProgressBarModule } from '@nebular/theme';
import { BankInformationModule } from './bank-information';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [
        SharedModule,
        NbLayoutModule,
        ReactiveFormsModule,
        NbProgressBarModule,
        BankInformationModule,
        TranslocoModule
    ],
    declarations: [GetStartedCompleteBusinessDetailsComponent]
})
export class GetStartedCompleteBusinessDetailsModule {}
