import { createAction, props } from '@ngrx/store';
import { Placement } from 'app/shared/interfaces/placement';

import { Location, LocationModelVariant } from '../../../shared/interfaces/location';

export const loadLocations = createAction('[Location] Load Locations');

export const loadLocationsSuccess = createAction(
    '[Location] Load Locations Success',
    props<{ locations: Location[] }>()
);

export const loadLocation = createAction(
    '[Location] Load Location',
    props<{ locationId: number; locationModelVariant: LocationModelVariant }>()
);

export const loadLocationSuccess = createAction('[Location] Load Location Success', props<{ location: Location }>());

export const loadLocationFailure = createAction('[Location] Load Location Failure', props<{ errorMessage: string }>());

export const updateLocationSuccess = createAction(
    '[Location] Update Location Success',
    props<{ location: Location }>()
);

export const createPlacement = createAction(
    '[Location] Create Placement',
    props<{ placement: Placement; locationId: string }>()
);

export const createPlacementSuccess = createAction(
    '[Location] Create Placement Success',
    props<{ location: Location }>()
);

export const createPlacementFailure = createAction(
    '[Location] Create Placement Failure',
    props<{ errorMessage: string }>()
);

export const requestPublishLocation = createAction(
    '[Location] Request Publish Location',
    props<{ locationId: number }>()
);

export const requestPublishLocationSuccess = createAction('[Location] Request Publish Location Success');

export const publishLocation = createAction('[Location] Publish Location', props<{ locationId: number }>());

export const publishLocationSuccess = createAction('[Location] Publish Location Success');

export const unpublishLocation = createAction('[Location] Unpublish Location', props<{ locationId: number }>());

export const unpublishLocationSuccess = createAction('[Location] Unpublish Location Success');

export const unpublishLocationFailure = createAction(
    '[Location] Unpublish Location Failure',
    props<{ errorMessage: string }>()
);

export const requestListLocation = createAction('[Location] Request List Location', props<{ locationId: number }>());

export const requestListLocationSuccess = createAction('[Location] Request List Location Success');

export const listLocation = createAction('[Location] List Location', props<{ locationId: number }>());

export const listLocationSuccess = createAction('[Location] List Location Success');

export const listLocationFailure = createAction('[Location] List Location Failure', props<{ errorMessage: string }>());

export const unlistLocation = createAction('[Location] Unlist Location', props<{ locationId: number }>());

export const unlistLocationSuccess = createAction('[Location] Unlist Location Success');

export const addPartner = createAction(
    '[Location] Add Partner',
    props<{ locationId: number; partnerLocationId: number }>()
);

export const addPartnerSuccess = createAction('[Location] Add Partner Success', props<{ location: Location }>());

export const addPartnerFailure = createAction('[Location] Add Partner Failure', props<{ errorMessage: string }>());
