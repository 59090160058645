import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OnBoardingService } from 'app/shared/services/on-boarding.service';
import { FormInitiatorEnum, LocationType, OnboardingCountry } from 'app/shared/interfaces/onboarding';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticatedUser } from 'app/shared/interfaces/auth/simple-user';
import { Subject } from 'rxjs/internal/Subject';

@Component({
    selector: 'get-started-contact-us-form',
    templateUrl: './get-started-contact-us-form.component.html',
    styleUrls: ['./get-started-contact-us-form.component.scss']
})
export class GetStartedContactUsFormComponent implements OnInit {
    @Input() user: AuthenticatedUser;
    @Input() submitErrorResponse = new Subject();
    @Output() formSubmit: EventEmitter<any> = new EventEmitter();
    public submitErrorText: string;
    public form: UntypedFormGroup;
    public locationTypes: Observable<LocationType[]> = this.onBoardingService.getLocationTypes();
    public countries: Observable<OnboardingCountry[]> = this.onBoardingService.getSupportedCountries();
    isFormSubmitting = false;

    constructor(
        private fb: UntypedFormBuilder,
        private onBoardingService: OnBoardingService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.initForm();
        this.submitErrorResponse?.subscribe(x => {
            this.isFormSubmitting = false;
            this.submitErrorText =
                'Something went wrong with the request. Double-check that the email you input is correct and try again. ';
        });
    }

    initForm() {
        this.form = this.fb.group({
            formInitiator: [
                this.route.snapshot.queryParams?.formInitiator ?? FormInitiatorEnum.UNKNOWN,
                [Validators.required]
            ],
            countryCode: [
                {
                    value: this.user?.onboardingSession?.countryCode ?? null,
                    disabled: this.user?.onboardingSession?.countryCode
                },
                [Validators.required]
            ],
            locationName: [
                {
                    value: this.user?.onboardingSession?.locationName ?? null,
                    disabled: this.user?.onboardingSession?.locationName
                },
                [Validators.required]
            ],
            locationType: [
                {
                    value: this.user?.onboardingSession?.locationType ?? null,
                    disabled: this.user?.onboardingSession?.locationType
                },
                [Validators.required]
            ],
            firstName: [
                {
                    value: this.user?.onboardingSession?.firstName ?? null,
                    disabled: this.user?.onboardingSession?.firstName
                },
                [Validators.required]
            ],
            lastName: [
                {
                    value: this.user?.onboardingSession?.lastName ?? null,
                    disabled: this.user?.onboardingSession?.lastName
                },
                [Validators.required]
            ],
            phoneNumber: [
                {
                    value: this.user?.onboardingSession?.phoneNumber ?? null,
                    disabled: this.user?.onboardingSession?.phoneNumber
                },
                [Validators.required]
            ],
            email: [
                { value: this.user?.userName ?? null, disabled: this.user?.userName },
                [Validators.required, Validators.email]
            ],
            inquiry: [null, [Validators.required]]
        });
    }

    handleSubmit() {
        this.isFormSubmitting = true;
        this.formSubmit.emit(this.form.getRawValue());
    }
}
