<div class="header-container d-print-none d-print-none">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="/" class="sidebar-toggle">
            <nb-icon class="header-umami-icon" icon="LeftPanelIcon" pack="umami" status="primary"></nb-icon>
        </a>
        <a class="logo-anchor" href="/" (click)="navigateHome()">
            <ng-container *ngIf="currentTheme === 'corporate' || currentTheme === 'default'">
                <img class="logo" src="../../../../assets/logo_dark.svg" alt="Favrit logo" />
            </ng-container>
            <ng-container *ngIf="currentTheme === 'dark' || currentTheme === 'cosmic'">
                <img class="logo" src="../../../../assets/logo_light.svg" alt="Favrit logo" />
            </ng-container>
        </a>
    </div>
    <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
        <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select>
</div>

<div class="header-container">
    <nb-actions size="small">
        <nb-action *ngIf="location && showLocationSpecificHeaderFeatures" (click)="goToOpeningHours()" class="status">
            <div class="mr-10">Status</div>
            <div class="tag" (click)="goToOpeningHours()" *ngIf="location.state === 'OPEN'">{{ location.state }}</div>
            <div class="tag danger" (click)="goToOpeningHours()" *ngIf="location.state !== 'OPEN'">
                {{ location.state }}
            </div>
        </nb-action>
        <nb-action class="hamburger-menu" [nbContextMenu]="menuItems" *ngIf="menuItems.length !== 0">
            <div class="info-container">
                <span *ngIf="location && showLocationSpecificHeaderFeatures" class="location">{{
                    location?.name
                }}</span>
                <span *ngIf="authenticatedUser?.userName" class="user">{{ authenticatedUser?.userName }}</span>
            </div>
            <nb-icon class="header-umami-icon" icon="HamburgerIcon" pack="umami" status="primary"></nb-icon>
        </nb-action>
    </nb-actions>
</div>
