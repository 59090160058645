<ng-container *transloco="let t; read: 'get_started.select_pricing_plan'">
    <nb-layout center class="colored-layout-white fullWidth">
        <nb-layout-column>
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 mx-auto">
                        <div class="d-flex justify-content-center">
                            <favrit-logo></favrit-logo>
                        </div>
                        <div class="text-center section-spacer">
                            <h1>Welcome to Favrit</h1>
                        </div>
                        <div class="d-flex justify-content-center mb-30">
                            <h2>Confirm your pricing plan</h2>
                        </div>
                        <div class="row">
                            <ng-container *ngIf="pricingPlans | async as pricingPlans">
                                <div class="col-md-4 order-1 order-md-0">
                                    <pricing-plan-card
                                        (planSelected)="handlePlanSelected($event)"
                                        [pricingPlan]="pricingPlans[0]"
                                    ></pricing-plan-card>
                                </div>
                                <div class="col-md-4 order-1 order-md-0">
                                    <pricing-plan-card
                                        (planSelected)="handlePlanSelected($event)"
                                        [pricingPlan]="pricingPlans[1]"
                                    ></pricing-plan-card>
                                </div>
                                <div class="col-md-4 order-1 order-md-0">
                                    <pricing-plan-card (planSelected)="handlePlanSelected($event)"></pricing-plan-card>
                                </div>
                            </ng-container>
                            <div class="col-md-12 order-0 order-md-1">
                                <nb-card class="information-box">
                                    <nb-card-body>
                                        <h4 class="text-center mb-30">All plans include</h4>
                                        <div class="row">
                                            <div class="col-md-7 mx-auto">
                                                <div class="row">
                                                    <div class="col-md-6 d-flex">
                                                        <div class="mr-10">
                                                            <nb-icon
                                                                status="primary"
                                                                icon="checkmark-circle-2"
                                                            ></nb-icon>
                                                        </div>
                                                        <p>
                                                            Use your own iOS equipment
                                                        </p>
                                                    </div>
                                                    <div class="col-md-6 d-flex">
                                                        <div class="mr-10">
                                                            <nb-icon
                                                                status="primary"
                                                                icon="checkmark-circle-2"
                                                            ></nb-icon>
                                                        </div>
                                                        <p>
                                                            Micro-network
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 d-flex">
                                                        <div class="mr-10">
                                                            <nb-icon status="info" icon="info-outline"></nb-icon>
                                                        </div>
                                                        <p>
                                                            All prices are per month per location.
                                                        </p>
                                                    </div>
                                                    <div class="col-md-6 d-flex">
                                                        <div class="mr-10">
                                                            <nb-icon status="info" icon="info-outline"></nb-icon>
                                                        </div>
                                                        <p>
                                                            {{ t('all_plans_include.transaction_fees.title') }}
                                                            <a href="javascript:void(0)" [nbPopover]="templateRef2"
                                                                >Learn more</a
                                                            >
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </nb-card-body>
                                </nb-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nb-layout-column>
    </nb-layout>
    <ng-template #templateRef2>
        <div class="p-10 popover-text">
            <ul class="mb-0">
                <li *ngIf="t('all_plans_include.transaction_fees.description_bullet_1')">
                    {{ t('all_plans_include.transaction_fees.description_bullet_1') }}
                </li>
                <li *ngIf="t('all_plans_include.transaction_fees.description_bullet_2')">
                    {{ t('all_plans_include.transaction_fees.description_bullet_2') }}
                </li>
                <li *ngIf="t('all_plans_include.transaction_fees.description_bullet_3')">
                    {{ t('all_plans_include.transaction_fees.description_bullet_3') }}
                </li>
            </ul>
        </div>
    </ng-template>
</ng-container>
