import { createReducer, on } from '@ngrx/store';
import { setAllTasks, updateTask } from './task.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { TaskDto } from '../../../shared/components/tasks/task/task';

export const taskFeatureKey = 'task';

export type TaskState = EntityState<TaskDto>;

export const adapter = createEntityAdapter<TaskDto>({
    selectId: task => `${task.locationId}_${task.type}`
});

const initialState = adapter.getInitialState();

export const taskReducer = createReducer<TaskState>(
    initialState,

    on(setAllTasks, (state, { tasks }) => adapter.upsertMany(tasks, state)),
    on(updateTask, (state, { task }) =>
        adapter.updateOne({ id: `${task.locationId}_${task.type}`, changes: task }, state)
    )
);
