import { UntypedFormGroup, Validators } from '@angular/forms';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LocaleId } from 'app/shared/interfaces/locale';

enum SwedenRadioValues {
    GIRO = 'GIRO',
    ACCOUNT_NUMBER_AND_BANK_NAME = 'ACCOUNT_NUMBER_AND_BANK_NAME'
}

@Component({
    selector: 'bank-information',
    templateUrl: './bank-information.component.html',
    styleUrls: ['./bank-information.component.scss']
})
export class BankInformationComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    public localeId = LocaleId;
    public locale: string;
    public swedenRadioValue: string = SwedenRadioValues.GIRO;
    public swedenRadioValues = SwedenRadioValues;

    constructor(private translocoService: TranslocoService) {}

    ngOnInit() {
        this.setLocale();
        this.setBankInformationValidators();
    }

    public handleSwedenRadioChange(value) {
        this.swedenRadioValue = value;
        this.resetBankInformationFields();
        this.setBankInformationValidators();
    }

    private resetBankInformationFields() {
        this.form.get('bankGiro').reset();
        this.form.get('bankName').reset();
        this.form.get('accountNumber').reset();
    }

    private setBankInformationValidators() {
        if (!this.swedenRadioValue) return false;
        if (this.locale === LocaleId.SV_SE) {
            if (this.swedenRadioValue === SwedenRadioValues.GIRO) {
                this.form
                    .get('bankGiro')
                    .setValidators([Validators.required, Validators.pattern(/^[0-9]{3,4}-[0-9]{4}$/)]);
                this.form.get('accountNumber').disable();
                this.form.get('bankName').disable();
                this.form.get('bankGiro').enable();
            }
            if (this.swedenRadioValue === SwedenRadioValues.ACCOUNT_NUMBER_AND_BANK_NAME) {
                this.form
                    .get('accountNumber')
                    .setValidators([Validators.required, Validators.pattern(/^[0-9]{11,15}$/)]);
                this.form.get('bankName').setValidators([Validators.required]);
                this.form.get('accountNumber').enable();
                this.form.get('bankName').enable();
                this.form.get('bankGiro').disable();
            }
        }
    }

    private setLocale() {
        this.locale = this.translocoService.getActiveLang();
    }
}
