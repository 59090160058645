import { GetStartedOrdrReferralModule } from './components/ordr-referral/index';
import { GetStartedCompleteSignupModule } from './components/complete-signup/index';
import { GetStartedCompleteBusinessDetailsModule } from './components/complete-business-details/index';
import { GetStartedSelectEquipmentModule } from './components/select-equipment/index';
import { GetStartedSelectPricingPlanModule } from './components/select-pricing-plan/index';
import { GetStartedContactInfoModule } from './components/contact-info/index';
import { GetStartedBusinessDetailsModule } from './components/business-details/index';
import { GetStartedAccountCreatedModule } from './components/account-created/index';
import { GetStartedEnterEmailModule } from './components/enter-email/index';
import { GetStartedEnterOrgNumberModule } from './components/enter-org-number/index';
import { GetStartedSignupRequestedModule } from './components/signup-requested/index';
import { GetStartedSignupCompletedModule } from './components/signup-completed/index';
import { GettingStartedRoutingModule } from './getting-started-routing.module';
import { SharedModule } from './../../../shared/index';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NbAuthJWTInterceptor, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { ContentTypeInterceptor } from 'app/shared/interceptors/content-type.interceptor';
import { StoreModule } from '@ngrx/store';
import * as fromGettingStarted from './state/getting-started.reducer';
import { GetStartedContactUsFormContainerPublicModule } from './components/contact-us/contact-us-form-container-public';
import { GetStartedContactUsFormContainerPrivateModule } from './components/contact-us/contact-us-form-container-private';
import { GetStartedContactUsConfirmationModule } from './components/contact-us/contact-us-confirmation';
import { GetStartedRedirectComponent } from './get-started-redirect.component';

@NgModule({
    imports: [
        SharedModule,
        GettingStartedRoutingModule,
        GetStartedEnterOrgNumberModule,
        GetStartedEnterEmailModule,
        GetStartedOrdrReferralModule,
        GetStartedAccountCreatedModule,
        GetStartedBusinessDetailsModule,
        GetStartedContactInfoModule,
        GetStartedSignupRequestedModule,
        GetStartedSelectPricingPlanModule,
        GetStartedSelectEquipmentModule,
        GetStartedCompleteBusinessDetailsModule,
        GetStartedCompleteSignupModule,
        GetStartedSignupCompletedModule,
        GetStartedContactUsFormContainerPublicModule,
        GetStartedContactUsFormContainerPrivateModule,
        GetStartedContactUsConfirmationModule,
        StoreModule.forFeature('gettingStarted', fromGettingStarted.reducer)
    ],
    declarations: [GetStartedRedirectComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true },
        {
            provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
            useValue: req => false
        }
    ]
})
export class GettingStartedModule {}
