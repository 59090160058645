<ng-container *ngIf="!disableCTA">
    <h1 id="title" class="title">Forgot Password</h1>
    <p class="sub-title">Enter your username and we’ll send a link to reset your password</p>

    <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
        <p class="alert-title"><b>Oh snap!</b></p>
        <ul class="alert-message-list">
            <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
        </ul>
    </nb-alert>

    <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
        <p class="alert-title"><b>Hooray!</b></p>
        <ul class="alert-message-list">
            <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
        </ul>
    </nb-alert>

    <form (ngSubmit)="requestPass()" #requestPassForm="ngForm" aria-labelledby="title">
        <div class="form-control-group">
            <label class="label" for="input-email">Enter your email:</label>
            <input
                nbInput
                (input)="onEmailInput($event)"
                [(ngModel)]="user.username"
                #username="ngModel"
                id="input-email"
                name="username"
                placeholder="Email"
                autofocus
                fullWidth
                [status]="username.dirty ? (username.invalid ? 'danger' : 'success') : ''"
                [required]="getConfigValue('forms.validation.email.required')"
                [attr.aria-invalid]="username.invalid && username.touched ? true : null"
            />
            <ng-container *ngIf="username.invalid && username.touched">
                <p class="error-message" *ngIf="username.errors?.required">
                    Email is required!
                </p>
                <p class="error-message" *ngIf="username.errors?.pattern">
                    Email should be the real one!
                </p>
            </ng-container>
        </div>

        <button
            nbButton
            fullWidth
            status="primary"
            [disabled]="submitted || !requestPassForm.valid || disableCTA"
            [class.btn-pulse]="submitted"
        >
            Request password
        </button>
    </form>

    <section class="sign-in-or-up" aria-label="Sign in or sign up">
        <p><a class="text-link" routerLink="../login">Back to login</a></p>
    </section>
</ng-container>

<div *ngIf="disableCTA">
    <div class="text-center">
        <h1>You've got mail!</h1>
        <div>Reset password instructions have been sent to your email.</div>
    </div>
</div>
