import { SharedModule } from 'app/shared';
import { NgModule } from '@angular/core';
import { HideAccountStatusBarDialogComponent } from './hide-account-status-bar-dialog.component';

@NgModule({
    imports: [SharedModule],
    declarations: [HideAccountStatusBarDialogComponent],
    exports: [HideAccountStatusBarDialogComponent]
})
export class HideAccountStatusBarDialogModule {}
