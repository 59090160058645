import { selectEquipmentList, selectPlan } from '../../state/index';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthenticatedUser } from 'app/shared/interfaces/auth/simple-user';
import { UserService } from 'app/shared/services/user.service';
import { Store } from '@ngrx/store';
import { GettingStarted, selectBusinessDetails, selectGettingStartedState } from '../../state';
import { setAgreementSignee } from '../../state/getting-started.actions';
import { OnBoardingService } from 'app/shared/services/on-boarding.service';
import { CompleteBusinessDetails, Equipment, EquipmentOrder, PricingPlan } from 'app/shared/interfaces/onboarding';

@Component({
    selector: 'app-get-started-complete-signup',
    templateUrl: './get-started-complete-signup.component.html',
    styleUrls: ['./get-started-complete-signup.component.scss']
})
export class GetStartedCompleteSignupComponent implements OnInit {
    public form: UntypedFormGroup;
    public user: AuthenticatedUser;
    public isComponentLoading = true;
    public equipmentList: Equipment[];
    public orderedEquipmentList: EquipmentOrder[];
    public totalPrice = 0;
    public businessDetails: CompleteBusinessDetails;
    public selectedPricingPlan;
    public pricingPlans: PricingPlan[];

    constructor(
        private router: Router,
        private store: Store<{ gettingStarted: GettingStarted }>,
        private onboardingService: OnBoardingService,
        private userService: UserService,
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute
    ) {}

    async ngOnInit() {
        this.guardRouteAndSetBusinessDetails();
        this.setEquipmentOrders();
        await this.setAuthenticatedUser();
        await this.setEquipment();
        await this.setPricingPlans();
        this.setTotalPriceForEquipment();
        this.setSelectedPricingPlanPrice();
        this.initForm();
        this.isComponentLoading = false;
    }

    setSelectedPricingPlanPrice() {
        this.store.select(selectPlan).subscribe(res => {
            if (!res) return;
            const pricingPlanInList = this.pricingPlans.find(plan => plan.name === res);

            this.selectedPricingPlan = {
                name: res,
                currency: pricingPlanInList.currency,
                percentageOfMonthlyRevenue: pricingPlanInList.percentageOfMonthlyRevenue,
                price: pricingPlanInList.price
            };
        });
    }

    setPricingPlans() {
        return this.onboardingService
            .getPricingPlans(this.user.onboardingSession.countryCode)
            .toPromise()
            .then(res => (this.pricingPlans = res));
    }

    setTotalPriceForEquipment() {
        this.orderedEquipmentList.forEach(orderedEquipment => {
            this.equipmentList.forEach(equipment => {
                if (orderedEquipment.equipmentId === equipment.id) {
                    this.totalPrice += equipment.price * orderedEquipment.amount;
                }
            });
        });
    }

    setEquipmentOrders() {
        this.store.select(selectEquipmentList).subscribe(res => {
            if (!res) return;
            this.orderedEquipmentList = res;
        });
    }

    guardRouteAndSetBusinessDetails() {
        this.store.select(selectBusinessDetails).subscribe(res => {
            if (!res) return false;

            this.businessDetails = res;
            if (!res.orgNumber) this.router.navigate(['select-pricing-plan'], { relativeTo: this.route.parent });
        });
    }

    setAuthenticatedUser() {
        return this.userService
            .getAuthenticatedUser()
            .toPromise()
            .then(res => (this.user = res));
    }

    handleContinueClick() {
        this.isComponentLoading = true;
        this.store.dispatch(setAgreementSignee({ model: this.form.getRawValue() }));
        this.completeSignup();
    }

    private setEquipment() {
        return this.onboardingService
            .getEquipment(this.user.onboardingSession.countryCode)
            .toPromise()
            .then(res => (this.equipmentList = res));
    }

    private async completeSignup() {
        let postModel: GettingStarted;
        await this.store.select(selectGettingStartedState).subscribe(res => {
            postModel = { ...res, equipmentOrders: res.equipmentOrders.filter(e => e.amount > 0) };
            delete postModel.selectEquipmentSkipped;
        });

        this.onboardingService
            .completeSignup(this.user.onboardingSession.countryCode, this.user.onboardingSession.orgNumber, postModel)
            .subscribe(
                res => {
                    this.isComponentLoading = false;

                    this.router.navigate(['signup-completed'], {
                        relativeTo: this.route.parent,
                        queryParams: {
                            locationId: res.locationId
                        }
                    });
                },
                err => {
                    this.isComponentLoading = false;
                }
            );
    }

    private initForm() {
        this.form = this.fb.group({
            email: new UntypedFormControl(
                {
                    value: this.user.userName ?? '',
                    disabled: this.user.userName ? true : false
                },
                Validators.required
            ),
            firstName: new UntypedFormControl(
                {
                    value: this.user.onboardingSession.firstName ?? '',
                    disabled: this.user.onboardingSession.firstName ? true : false
                },
                Validators.required
            ),
            lastName: new UntypedFormControl(
                {
                    value: this.user.onboardingSession.lastName ?? '',
                    disabled: this.user.onboardingSession.lastName ? true : false
                },
                Validators.required
            ),
            phoneNumber: new UntypedFormControl(
                {
                    value: this.user.onboardingSession.phoneNumber ?? '',
                    disabled: this.user.onboardingSession.phoneNumber ? true : false
                },
                Validators.required
            )
        });
    }
}
