import { SharedModule } from './../../../../../shared/index';
import { NbLayoutModule } from '@nebular/theme';
import { NgModule } from '@angular/core';
import { GetStartedContactInfoComponent } from './get-started-contact-info.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [NbLayoutModule, SharedModule, ReactiveFormsModule],
    declarations: [GetStartedContactInfoComponent],
    exports: [GetStartedContactInfoComponent]
})
export class GetStartedContactInfoModule {}
