import { SharedModule } from './../../../../../../shared/index';
import { NgModule } from '@angular/core';
import { GetStartedContactUsFormContainerPublicComponent } from './get-started-contact-us-form-container-public.component';
import { GetStartedContactUsFormModule } from '../contact-us-form';
import { NbLayoutModule } from '@nebular/theme';

@NgModule({
    imports: [SharedModule, GetStartedContactUsFormModule, NbLayoutModule],
    declarations: [GetStartedContactUsFormContainerPublicComponent],
    exports: [GetStartedContactUsFormContainerPublicComponent]
})
export class GetStartedContactUsFormContainerPublicModule {}
