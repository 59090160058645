import { NgModule } from '@angular/core';
import { NgxResetPasswordComponent } from './reset-password.component';
import { SharedModule } from '../../../shared';
import { FormsModule } from '@angular/forms';
import { NbAuthModule } from '@nebular/auth';

@NgModule({
    imports: [SharedModule, FormsModule, NbAuthModule],
    declarations: [NgxResetPasswordComponent],
    exports: [NgxResetPasswordComponent]
})
export class NgxResetPasswordModule {}
