import { createAction, props } from '@ngrx/store';

export const changeLocale = createAction('[Localisation] Change Locale', props<{ locale: string }>());

export const changeLocaleSuccess = createAction('[Localisation] Change Locale Success', props<{ locale: string }>());

export const changeLocaleFailure = createAction(
    '[Localisation] Change Locale Failure',
    props<{ errorMessage: string }>()
);
