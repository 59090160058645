<nb-card class="custom-dialog">
    <nb-card-header class="d-flex justify-content-between">
        <h1>Are you sure?</h1>
        <nb-icon icon="close-outline" status="basic" (click)="onCancel()"></nb-icon>
    </nb-card-header>

    <nb-card-body>
        <p>
            You still have tasks left to do on your location. Are you sure you would like to hide the account status
            bar?
        </p>
        <p>You will still be able to find the account status bar in Company Profile within Settings.</p>
    </nb-card-body>

    <nb-card-footer class="d-flex justify-content-end">
        <button class="ml-auto" nbButton (click)="onCancel()">Cancel</button>
        <button class="ml-3" nbButton type="submit" status="primary" (click)="onHideBar()">Hide bar</button>
    </nb-card-footer>
</nb-card>
