import { addEquipment, removeEquipment } from './../../../state/getting-started.actions';
import { Equipment } from './../../../../../../shared/interfaces/onboarding';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GettingStarted, selectEquipmentById, selectEquipmentList } from '../../../state';

@Component({
    selector: 'select-equipment-item',
    templateUrl: './select-equipment-item.component.html',
    styleUrls: ['./select-equipment-item.component.scss']
})
export class SelectEquipmentItemComponent implements OnInit {
    @Input() equipment: Equipment;
    @Input() disabled: boolean;
    isItemCartOpen = false;
    stateAmountCounter = 0;

    constructor(private store: Store<{ gettingStarted: GettingStarted }>) {}

    ngOnInit() {
        this.setEquipmentAmount();
        this.setStateAmountCounter();
    }

    setEquipmentAmount() {
        this.equipment['amount'] = 0;
    }

    addToCart() {
        this.store.dispatch(
            addEquipment({ equipment: { equipmentId: this.equipment.id, amount: this.equipment.amount } })
        );
        this.setStateAmountCounter();
        this.isItemCartOpen = true;
    }

    removeFromCart() {
        this.store.dispatch(
            removeEquipment({ equipment: { equipmentId: this.equipment.id, amount: this.equipment.amount } })
        );
        this.setStateAmountCounter();
    }

    setStateAmountCounter() {
        this.store.select(selectEquipmentById, { id: this.equipment.id }).subscribe(res => {
            if (res && 'amount' in res) {
                this.stateAmountCounter = res.amount;
                this.isItemCartOpen = true;
            }
        });
    }
}
