import { SharedModule } from './../../../../../shared/index';
import { NgModule } from '@angular/core';
import { GetStartedEnterEmailComponent } from './get-started-enter-email.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NbLayoutModule } from '@nebular/theme';

@NgModule({
    imports: [NbLayoutModule, SharedModule, ReactiveFormsModule],
    declarations: [GetStartedEnterEmailComponent],
    exports: [GetStartedEnterEmailComponent]
})
export class GetStartedEnterEmailModule {}
