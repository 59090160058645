<nb-layout center class="colored-layout-white fullWidth">
    <nb-layout-column>
        <div class="container">
            <div class="row">
                <div class="col mx-auto">
                    <div class="mb-50 col-md-10 col-lg-6 mx-auto p-0">
                        <nb-progress-bar
                            [value]="75"
                            status="primary"
                            [displayValue]="true"
                            size="tiny"
                        ></nb-progress-bar>
                    </div>
                    <div class="d-flex justify-content-center">
                        <favrit-logo></favrit-logo>
                    </div>
                    <div class="text-center enter section-spacer">
                        <h1>Let's make it official</h1>
                        <p class="intro-text text-center m-auto">
                            Once you have confirmed the rest of your account information and your order, we are ready to
                            complete your signup
                        </p>
                    </div>
                    <ng-container *ngIf="form">
                        <form [formGroup]="form" (ngSubmit)="handleContinueClick()">
                            <div class="mb-50">
                                <div class="form-group">
                                    <label class="label" for="email">Email</label>
                                    <ng-container *ngIf="user?.userName">
                                        <p id="email" class="pre-filled">
                                            {{ user?.userName }}
                                        </p>
                                    </ng-container>
                                    <ng-container *ngIf="!user?.userName">
                                        <input type="text" formControlName="email" fullWidth nbInput />
                                    </ng-container>
                                </div>
                                <div class="form-group">
                                    <label class="label" for="firstName">First name</label>
                                    <ng-container *ngIf="user?.onboardingSession?.firstName">
                                        <p id="firstName" class="pre-filled">
                                            {{ user?.onboardingSession?.firstName }}
                                        </p>
                                    </ng-container>
                                    <ng-container *ngIf="!user?.onboardingSession?.firstName">
                                        <input type="text" formControlName="firstName" fullWidth nbInput />
                                    </ng-container>
                                </div>
                                <div class="form-group">
                                    <label class="label" for="lastName">Last name</label>
                                    <ng-container *ngIf="user?.onboardingSession?.lastName">
                                        <p id="lastName" class="pre-filled">
                                            {{ user?.onboardingSession?.lastName }}
                                        </p>
                                    </ng-container>
                                    <ng-container *ngIf="!user?.onboardingSession?.lastName">
                                        <input type="text" formControlName="lastName" fullWidth nbInput />
                                    </ng-container>
                                </div>
                                <div class="form-group">
                                    <label class="label" for="phoneNumber">Phone number</label>
                                    <ng-container *ngIf="user?.onboardingSession?.phoneNumber">
                                        <p id="phoneNumber" class="pre-filled">
                                            {{ user?.onboardingSession?.phoneNumber }}
                                        </p>
                                    </ng-container>
                                    <ng-container *ngIf="!user?.onboardingSession?.phoneNumber">
                                        <input type="text" formControlName="phoneNumber" fullWidth nbInput />
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-md-10 col-lg-6 mx-auto p-0 mb-50">
                                <nb-card class="information-box">
                                    <nb-card-body>
                                        <h2 class="text-center mb-30">To sum it up</h2>
                                        <div class="row">
                                            <div class="col-sm-10 col-xl-8 mx-auto p-0 border-bottom mb-30">
                                                <ng-container *ngIf="orderedEquipmentList?.length">
                                                    <!-- equipment here -->
                                                    <ng-container *ngFor="let equipment of orderedEquipmentList">
                                                        <get-started-equipment-summary-item
                                                            [equipment]="equipment"
                                                            [equipmentList]="equipmentList"
                                                        >
                                                        </get-started-equipment-summary-item>
                                                    </ng-container>

                                                    <!-- if equipment, show shipping fee -->
                                                    <div class="col d-flex justify-content-between mb-20">
                                                        <div class="label">Shipping fees</div>
                                                        <div class="price">Free</div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="!orderedEquipmentList?.length">
                                                    <!-- if no equipment, show following -->
                                                    <div class="col d-flex justify-content-between mb-20">
                                                        <div class="label">No equipment</div>
                                                        <div class="price">Free</div>
                                                    </div>
                                                </ng-container>

                                                <div class="col d-flex justify-content-between mb-20">
                                                    <div class="label">Sign up fees</div>
                                                    <div class="price">Free</div>
                                                </div>
                                                <div class="col d-flex justify-content-between my-20">
                                                    <div class="h5">Total</div>
                                                    <div class="price">
                                                        <span class="amount h5">{{ totalPrice }}</span>
                                                        <span class="currency">{{ equipmentList[0].currency }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-10 col-xl-8 mx-auto">
                                                <!-- if equipment, show shipping address -->
                                                <ng-container *ngIf="orderedEquipmentList?.length">
                                                    <div class="d-flex">
                                                        <div class="mr-10 py-1">
                                                            <nb-icon status="info" icon="info-outline"></nb-icon>
                                                        </div>
                                                        <p>
                                                            Your equipment will be sent to: <br />
                                                            <strong
                                                                >{{ businessDetails.address }},
                                                                {{ businessDetails.zipCode }}
                                                                {{ businessDetails.city }}</strong
                                                            >
                                                        </p>
                                                    </div>
                                                </ng-container>
                                                <div class="d-flex">
                                                    <div class="mr-10 py-1">
                                                        <nb-icon status="info" icon="info-outline"></nb-icon>
                                                    </div>
                                                    <p>
                                                        You will be charged
                                                        <strong
                                                            >{{ selectedPricingPlan.price }}
                                                            {{ selectedPricingPlan.currency }} +
                                                            {{
                                                                selectedPricingPlan.percentageOfMonthlyRevenue
                                                            }}%</strong
                                                        >
                                                        of your revenue per location per month. You can cancel at any
                                                        time with 1 months notice.
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="form-group col-10 mt-10 mb-30">
                                                <nb-checkbox #confirm>
                                                    I confirm that I start this subscription on behalf of my business
                                                    and that I have read and accepted the
                                                    <a
                                                        href="https://static.favrit.com/Merchant+agreements/Favrit+Merchant+Agreement+SMB.pdf"
                                                        target="_blank"
                                                        rel="noopener"
                                                        >merchant agreement</a
                                                    >.
                                                </nb-checkbox>
                                            </div>

                                            <div class="form-group col-10 mb-30">
                                                <button
                                                    nbButton
                                                    fullWidth
                                                    [nbSpinner]="isComponentLoading"
                                                    nbSpinnerStatus="primary"
                                                    [disabled]="form.invalid || !confirm.checked || isComponentLoading"
                                                    type="submit"
                                                    status="primary"
                                                >
                                                    Complete signup
                                                </button>
                                            </div>
                                        </div>
                                    </nb-card-body>
                                </nb-card>
                            </div>
                        </form>
                    </ng-container>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
