<ng-container *transloco="let t">
    <nb-layout center class="colored-layout-white">
        <nb-layout-column>
            <div class="container h-100">
                <div class="row h-100">
                    <div class="col-md-12 m-auto h-100">
                        <div class="d-flex align-items-center flex-column justify-content-center h-100">
                            <div class="section-spacer d-flex justify-content-center">
                                <favrit-logo></favrit-logo>
                            </div>
                            <div class="text-center section-spacer">
                                <h1>
                                    Enter some more details <br />
                                    about your business
                                </h1>
                            </div>
                            <div class="d-flex justify-content-center">
                                <div class="form-container-wrapper">
                                    <form [formGroup]="form" (ngSubmit)="goToNextStep()">
                                        <div *ngIf="!urlHasOrgNrParam" class="form-group">
                                            <label class="label" for="orgNumber">{{
                                                t('global.org_number_label')
                                            }}</label>
                                            <input
                                                #firstFocus
                                                type="text"
                                                formControlName="orgNumber"
                                                nbInput
                                                fullWidth
                                            />
                                            <div
                                                *ngIf="orgNumberField.invalid && orgNumberField.touched"
                                                class="text-danger"
                                            >
                                                <small *ngIf="orgNumberField.errors">
                                                    {{ orgNumberErrorMessage }}
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label class="label" for="locationName">Establishment name</label>
                                            <input
                                                type="text"
                                                formControlName="locationName"
                                                nbInput
                                                fullWidth
                                                placeholder="E.g John's bar"
                                            />
                                        </div>

                                        <div class="form-group">
                                            <label class="label" for="locationType">Establishment type</label>
                                            <ng-container *ngIf="locationTypes | async as locations">
                                                <nb-select
                                                    fullWidth
                                                    formControlName="locationType"
                                                    placeholder="Select establishment type"
                                                >
                                                    <nb-option *ngFor="let loc of locations" [value]="loc.name">{{
                                                        loc.name
                                                    }}</nb-option>
                                                </nb-select>
                                            </ng-container>
                                        </div>

                                        <div class="form-group">
                                            <label class="label" for="useCase"
                                                >What do you want to use Favrit for?</label
                                            >
                                            <textarea formControlName="useCase" fullWidth rows="5" nbInput></textarea>
                                        </div>

                                        <div class="form-group">
                                            <button
                                                nbButton
                                                [disabled]="form.invalid"
                                                status="primary"
                                                type="submit"
                                                fullWidth
                                            >
                                                CONTINUE
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nb-layout-column>
    </nb-layout>
</ng-container>
