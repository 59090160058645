<nb-layout center class="colored-layout-white">
    <nb-layout-column>
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-md-12 m-auto h-100">
                    <div class="d-flex align-items-center flex-column justify-content-center h-100">
                        <div class="form-container-wrapper">
                            <div class="section-spacer d-flex justify-content-center ">
                                <favrit-logo></favrit-logo>
                            </div>
                            <div class="text-center section-spacer">
                                <h1>Check your email and create a new password</h1>
                                <p>
                                    We have send a reset password link to <strong>{{ email }}</strong
                                    >. Click the link to set up your own password and get access to Favrit!
                                </p>
                            </div>
                            <div class="d-flex flex-column justify-content-center">
                                <div class="mb-30 text-center">
                                    Cant find the email? Check your spam folder!
                                </div>
                                <button nbButton status="primary" [routerLink]="['/']">
                                    Click here to sign in
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
