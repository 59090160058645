import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../../../../../shared/index';
import { NbLayoutModule } from '@nebular/theme';
import { NgModule } from '@angular/core';
import { GetStartedBusinessDetailsComponent } from './get-started-business-details.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [NbLayoutModule, SharedModule, ReactiveFormsModule, TranslocoModule],
    declarations: [GetStartedBusinessDetailsComponent],
    exports: [GetStartedBusinessDetailsComponent]
})
export class GetStartedBusinessDetailsModule {}
