<nb-layout center class="colored-layout-white fullWidth">
    <nb-layout-column>
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-8 col-lg-6 m-auto">
                    <get-started-contact-us-form
                        [submitErrorResponse]="submitErrorResponse"
                        (formSubmit)="handleFormSubmit($event)"
                    ></get-started-contact-us-form>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
