import { SharedModule } from './../../../../../shared/index';
import { NbLayoutModule } from '@nebular/theme';
import { NgModule } from '@angular/core';
import { GetStartedAccountCreatedComponent } from './get-started-account-created.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [NbLayoutModule, SharedModule, FormsModule],
    declarations: [GetStartedAccountCreatedComponent],
    exports: [GetStartedAccountCreatedComponent]
})
export class GetStartedAccountCreatedModule {}
