import { ExtraOptions, RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { NgModule } from '@angular/core';
import { NbAuthComponent } from '@nebular/auth';
import { AuthGuard } from './shared/services/auth-guard.service';
import { NgxResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { NgxRequestPasswordComponent } from './pages/auth/request-password/request-password.component';
import { GetStartedContactUsFormContainerPublicComponent } from './pages/public/getting-started/components/contact-us/contact-us-form-container-public/get-started-contact-us-form-container-public.component';
import { CanLoadPagesGuard } from './shared/services/can-load-pages.guard';
import { LocaleGuard } from './shared/locale-guard.service';
import { Login } from './pages/auth/oauth-login/Login.component';
import { Callback } from './pages/auth/oauth-login/Callback.component';
import { Logout } from './pages/auth/oauth-login/Logout.component';

const routes: Routes = [
    {
        path: 'location',
        canActivate: [AuthGuard],
        canLoad: [CanLoadPagesGuard],
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: ':locale',
        canActivate: [LocaleGuard],
        children: [
            {
                path: 'get-started',
                loadChildren: () =>
                    import('./pages/public/getting-started/getting-started.module').then(m => m.GettingStartedModule)
            },
            {
                path: 'contact-us',
                component: GetStartedContactUsFormContainerPublicComponent
            }
        ]
    },
    { path: 'contact-us', redirectTo: 'en-gb/contact-us', pathMatch: 'full' },
    {
        path: 'auth',
        component: NbAuthComponent,
        children: [
            {
                path: '',
                component: Login
            },
            {
                path: 'login',
                component: Login
            },
            {
                path: 'callback',
                component: Callback
            },
            {
                path: 'logout',
                component: Logout
            },
            // {
            //   path: 'register',
            //   component: NbRegisterComponent
            // },
            // {
            //   path: 'logout',
            //   component: NbLogoutComponent
            // },
            {
                path: 'request-password',
                component: NgxRequestPasswordComponent
            },
            {
                path: 'password-reset/:reference/:token',
                component: NgxResetPasswordComponent
            }
        ]
    },
    { path: '', redirectTo: 'location', pathMatch: 'full' },
    { path: '**', redirectTo: 'location' }
];

const config: ExtraOptions = {
    useHash: true,
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
