import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthenticatedUser } from 'app/shared/interfaces/auth/simple-user';
import { OnBoardingService } from 'app/shared/services/on-boarding.service';
import { UserService } from 'app/shared/services/user.service';
import { Subject } from 'rxjs/internal/Subject';

@Component({
    selector: 'get-started-contact-us-form-container-private',
    templateUrl: './get-started-contact-us-form-container-private.component.html',
    styleUrls: ['./get-started-contact-us-form-container-private.component.scss']
})
export class GetStartedContactUsFormContainerPrivateComponent implements OnInit {
    public user: AuthenticatedUser;
    submitErrorResponse = new Subject();

    constructor(
        private userService: UserService,
        private onboardingService: OnBoardingService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.setAuthenticatedUser();
    }

    setAuthenticatedUser() {
        return this.userService
            .getAuthenticatedUser()
            .toPromise()
            .then(res => (this.user = res));
    }

    handleFormSubmit(form) {
        this.onboardingService.contactUs(form).subscribe(res => {
            this.router.navigate(['contact-us-confirmation'], { relativeTo: this.route.parent });
        });
    }
}
