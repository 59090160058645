import { createReducer, on } from '@ngrx/store';
import { setMenuItems, setOrderLogSearch } from './pages.actions';
import { PagesState } from '.';

export const initialState: PagesState = {
    menuItems: null,
    orderLogSearch: null
};

export const pagesReducer = createReducer(
    initialState,

    on(setMenuItems, (state, { menuItems }) => ({
        ...state,
        menuItems: menuItems
    })),
    on(setOrderLogSearch, (state, { orderLogSearch }) => ({
        ...state,
        orderLogSearch: orderLogSearch
    }))
);
