import { NgModule } from '@angular/core';

import { SharedModule } from 'app/shared';
import { CircularProgressIndicatorComponentModule } from '../circular-progress-indicator';
import { AccountStatusBarComponent } from './account-status-bar.component';
import { TasksAccordionComponentModule } from './account-status-accordion';
import { HideAccountStatusBarButtonModule } from './hide-account-status-bar-button';

@NgModule({
    imports: [
        SharedModule,
        CircularProgressIndicatorComponentModule,
        TasksAccordionComponentModule,
        HideAccountStatusBarButtonModule
    ],
    declarations: [AccountStatusBarComponent],
    exports: [AccountStatusBarComponent]
})
export class TasksBarModule {}
