// TODO: Find out which imports and inputs we do and don't need
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'account-status-accordion',
    templateUrl: './account-status-accordion.component.html',
    styleUrls: ['./account-status-accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountStatusAccordionComponent {
    @Input() displayHideButton: boolean;
    @Input() headline: string;
    @Input() icon: string;
    @Input() showCompletedIcon = false;
    @Input() bodyTemplateRef?: TemplateRef<any>;
    @Input() isDisabled = false;
    @Input() percentage: number;
    @Input() tasksLeft: number;
    @Output() collapsedChange = new EventEmitter<boolean>();

    onCollapsedChange(isCollapsed): void {
        if (this.collapsedChange) {
            this.collapsedChange.emit(isCollapsed);
        }
    }
}
