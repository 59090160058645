import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../../../../../shared/index';
import { NgModule } from '@angular/core';
import { GetStartedEnterOrgNumberComponent } from './get-started-enter-org-number.component';
import { NbLayoutModule } from '@nebular/theme';

@NgModule({
    imports: [NbLayoutModule, SharedModule, ReactiveFormsModule],
    declarations: [GetStartedEnterOrgNumberComponent],
    exports: [GetStartedEnterOrgNumberComponent]
})
export class GetStartedEnterOrgNumberModule {}
