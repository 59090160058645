import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export class BaseService {
    public handleError(err: HttpErrorResponse) {
        let errorMessage = '';

        if (err.error?.message?.length > 0) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, Message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(err);
    }
}
