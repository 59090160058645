import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = {};

        if (req.method.toUpperCase() === 'PATCH') {
            headers['Content-Type'] = 'application/json-patch+json; charset=utf-8';
        } else if (!req.headers.has('Content-Type')) {
            headers['Content-Type'] = 'application/json; charset=utf-8';
        } else if (req.url.includes('refresh-token-admin')) {
            headers['Content-Type'] = 'application/json; charset=utf-8';
        }

        return next.handle(req.clone({ setHeaders: headers }));
    }
}
