import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouterState } from '../../../store/router/custom-route-serializer';
import * as fromTask from './task.reducer';
import { TaskStatus } from '../../../shared/components/tasks/task/task';

export const { selectEntities } = fromTask.adapter.getSelectors();
export const selectTaskState = createFeatureSelector<fromTask.TaskState>(fromTask.taskFeatureKey);

export const selectCurrentTasks = createSelector(
    createSelector(selectTaskState, selectEntities),
    selectRouterState,
    (entities, router) => {
        const routerLocationId = router?.state && router.state.params.locationId;
        if (!routerLocationId) return;

        return Object.entries(entities)
            .filter(([key, value]) => key.startsWith(`${routerLocationId}_`))
            .map(t => t[1]);
    }
);

export const selectIncompleteTasks = createSelector(selectCurrentTasks, tasks =>
    tasks.filter(task => task.status !== TaskStatus.COMPLETED)
);

export const selectCompleteTasks = createSelector(selectCurrentTasks, tasks =>
    tasks.filter(task => task.status === TaskStatus.COMPLETED)
);
