export interface ProgressItem {
    headline?: string;
    description?: string;
    type: ProgressItemType;
    status: ProgressItemStatus;
}

export enum ProgressItemType {
    SHAREHOLDER_CHECK = 'SHAREHOLDER_CHECK',
    COMPANY_CHECK = 'COMPANY_CHECK',
    BANK_ACCOUNT_CHECK = 'BANK_ACCOUNT_CHECK',
    PHOTO_ID_CHECK = 'PHOTO_ID_CHECK'
}

export enum ProgressItemStatus {
    NOT_STARTED = 'NOT_STARTED',
    PENDING = 'PENDING',
    INVALID_DATA = 'INVALID_DATA',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}

export enum ProgressItemProgressStatus {
    NOT_STARTED = 'Not started',
    PENDING = 'Pending',
    INVALID_DATA = 'Invalid data',
    COMPLETED = 'Completed',
    FAILED = 'Failed',
    UNKNOWN = 'Unknown'
}
