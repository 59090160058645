import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { Location, LocationAddress, LocationSetting, PaymentMethod, SettingsType } from '../interfaces/location';
import { LocalizedText } from '../interfaces/localized-text';
import { Booking, PartialDiscard, PartialRefundRequest, RefundResultDto } from '../interfaces/booking';
import { Location as AngularLocation } from '@angular/common';
import { Router } from '@angular/router';
import { WS_BASE } from '../constants/urls';
import { NbToastrService } from '@nebular/theme';
import { Placement, PlacementQueueItem, PlacementSearchItem } from '../interfaces/placement';
import { Patch } from '../interfaces/patch';
import { Store } from '@ngrx/store';
import { LocationState } from 'app/pages/pages-state/location/location.reducer';
import { selectCurrentLocation } from 'app/pages/pages-state/location/location.selectors';
import { Subscription } from 'rxjs';
import { TaskDtoOld } from '../interfaces/task-dto-old';
import { updateLocationSuccess } from 'app/pages/pages-state/location/location.actions';
import { LocationDto } from '../../pages/integration/accounting/interfaces/integration';
import { TaskDto } from '../components/tasks/task/task';
import { RegisterTipRequestDto, RegisterTipResponseDto } from './requests/tips';
import {
    DigitalBillFeatureToggle,
    FeatureToggle,
    EPOSPaymentMethodsFeatureToggle,
    PrintingFeatureToggle
} from '../interfaces/feature-toggle/feature-toggle-types';
import { TaxGroup } from '../interfaces/tax-group';
import { BannerDto } from '../interfaces/banner';
import {
    DeactivateGiftCardRequest,
    GiftCard,
    GiftCardListPage,
    GiftCardResponse,
    GiftCardType
} from '../interfaces/gift-card';
import { Picture } from '../interfaces/picture';
import { SoftpayTerminal } from '../interfaces/softpayTerminal';

const refundOptions = {
    headers: new HttpHeaders({
        'X-Refund-Authorization': '724f3365-4b8b-4b8b-9c03-42e53d8ac68a'
    })
};

@Injectable({
    providedIn: 'root'
})
export class OrdrService implements OnDestroy {
    locationDefaultLanguage: string;
    private currentLocationSub: Subscription;

    constructor(
        private toaster: NbToastrService,
        private angularLocation: AngularLocation,
        private router: Router,
        private http: HttpClient,
        private locationStore: Store<LocationState>
    ) {
        this.subscribeToCurrentLocation();
    }

    ngOnDestroy() {
        this.currentLocationSub?.unsubscribe();
    }

    public subscribeToCurrentLocation() {
        this.currentLocationSub = this.locationStore.select(selectCurrentLocation).subscribe(currentLocation => {
            if (currentLocation) {
                this.locationDefaultLanguage = currentLocation?.defaultLanguage;
            }
        });
    }

    getLocation(locationId: string | number): Observable<Location> {
        return this.http
            .get<Location>(WS_BASE + '/v1/locations/' + locationId)
            .pipe(retry(3), catchError(this.handleError));
    }

    getLocationInfo(locationId: string): Observable<Location> {
        return this.http
            .get<Location>(`${WS_BASE}/v1/locations/${locationId}/info/`)
            .pipe(retry(3), catchError(this.handleError));
    }

    getAccessibleLocations(): Observable<Location[]> {
        return this.http
            .get<Location[]>(WS_BASE + '/v1/locations/accessible')
            .pipe(retry(3), catchError(this.handleError));
    }

    getBooking(reference: string, locationId: number): Observable<Booking> {
        // const url = `${WS_BASE}/v1/bookings/reference/${encodeURI(reference)}?locationId=${locationId}`;
        const url = `${WS_BASE}/v1/bookings/reference/${encodeURI(reference)}/details`;
        return this.http.get<Booking>(url).pipe(
            retry(1),
            catchError((err: HttpErrorResponse) =>
                throwError(
                    err.status === 404
                        ? { message: 'Order not found ("' + reference + '")' }
                        : { message: 'Technical error: ' + err.error.message }
                )
            )
        );
    }

    getTaxGroups(locationId: number): Observable<TaxGroup[]> {
        const url = `${WS_BASE}/v1/tax-groups/locations/${locationId}`;
        return this.http.get<TaxGroup[]>(url).pipe(catchError(this.handleError));
    }

    getPlacements(
        locationId: number,
        placementId: number,
        includePreOrders: boolean,
        userTypeFilter: 'BOTH' | 'GUEST' | 'WAITER'
    ) {
        const userTypeParam = userTypeFilter && userTypeFilter !== 'BOTH' ? `&guestOrWaiter=${userTypeFilter}` : '';
        let url = `${WS_BASE}/v1/placements/list/${locationId}`;
        if (placementId !== undefined && placementId) {
            url += '?placementId=' + placementId;
        }
        url.includes('?') ? (url += `&limit=${200}`) : (url += `?limit=${200}`);
        url += '&t=' + new Date().getTime();
        url += userTypeParam;
        if (includePreOrders) url += '&includePreOrders=true';

        return this.http.get<PlacementQueueItem[]>(url).pipe(catchError(this.handleError));
    }

    placementsSearch(locationId: number, searchString: string) {
        const url = `${WS_BASE}/v1/order-logs/list/${locationId}?search=${searchString}`;
        return this.http.get<PlacementSearchItem[]>(url).pipe(catchError(this.handleError));
    }

    patchPlacementStatus(orderReference: string, placementQueueItemId: number, status: string) {
        const url = `${WS_BASE}/v1/placements/${orderReference}/${placementQueueItemId}/status/${status}`;
        return this.http.patch<any>(url, {}).pipe(catchError(this.handleError));
    }

    notifyReadyForPickup(placementQueueItemId: number) {
        const url = `${WS_BASE}/v1/placements/item/${placementQueueItemId}/notifications`;
        return this.http
            .post<any>(url, { type: 'PICKUP' })
            .pipe(catchError(this.handleError));
    }

    postSmsConfirmation(reference: string, locationId: number) {
        const url = `${WS_BASE}/v1/bookings/smsConfirmation/${reference}`;
        return this.http.post<any>(url, locationId).pipe(catchError(this.handleError));
    }

    markAsProcessed(placementQueueItemId: number) {
        const url = `${WS_BASE}/v1/placements/item/${placementQueueItemId}/processed`;
        return this.http.patch<any>(url, {}).pipe(catchError(this.handleError));
    }

    attachPlacement(placementId: number, locationId: string): Observable<Location> {
        const url = WS_BASE + '/v1/locations/' + locationId + '/placements';
        return this.http.put<Location>(url, placementId).pipe(retry(0), catchError(this.handleError));
    }

    createPlacement(locationId: string, placement: Placement): Observable<Location> {
        const url = WS_BASE + '/v1/locations/' + locationId + '/placements';
        return this.http.post<Location>(url, placement).pipe(retry(0), catchError(this.handleError));
    }

    patchLocation(patch: any[], locationId: string | number): Observable<Location> {
        const url = WS_BASE + '/v1/locations/' + locationId;
        return this.http.patch<Location>(url, patch).pipe(
            retry(0),
            tap(patchResult => this.locationStore.dispatch(updateLocationSuccess({ location: patchResult }))),
            catchError(this.handleError)
        );
    }

    patchPaymentMethods(patch: any[], locationId: string | number, paymentMethodId: number): Observable<Location> {
        const url = WS_BASE + '/v1/locations/' + locationId + '/payment-methods/' + paymentMethodId;
        return this.http.patch<Location>(url, patch).pipe(retry(0), catchError(this.handleError));
    }

    postPaymentMethod(locationId: string | number, body: any): Observable<Location> {
        const url = WS_BASE + '/v1/locations/' + locationId + '/payment-methods';
        return this.http.post<Location>(url, body).pipe(retry(0), catchError(this.handleError));
    }

    getPaymentMethods(locationId: string | number): Observable<PaymentMethod[]> {
        return this.http
            .get<PaymentMethod[]>(`${WS_BASE}/v1/locations/${locationId}/payment-methods`)
            .pipe(catchError(this.handleError));
    }

    getTasks(locationId: number | string): Observable<TaskDto[]> {
        return this.http
            .get<TaskDto[]>(`${WS_BASE}/v1/locations/${locationId}/tasks-all`)
            .pipe(catchError(this.handleError));
    }

    getPublicationTask(locationId: number): Observable<TaskDtoOld> {
        return this.http
            .get<TaskDtoOld>(`${WS_BASE}/v1/locations/${locationId}/publication-task`)
            .pipe(catchError(this.handleError));
    }

    requestPublishLocation(locationId: string): Observable<any> {
        const url = WS_BASE + '/v1/locations/' + locationId + '/request-publish';
        return this.http.post(url, {}).pipe(retry(0), catchError(this.handleError));
    }

    publishLocation(locationId: string): Observable<any> {
        const url = WS_BASE + '/v1/locations/' + locationId + '/publish';
        return this.http.post(url, {}).pipe(retry(0), catchError(this.handleError));
    }

    unpublishLocation(locationId: string): Observable<any> {
        const url = WS_BASE + '/v1/locations/' + locationId + '/unpublish';
        return this.http.post(url, {}).pipe(retry(0), catchError(this.handleError));
    }

    requestListLocation(locationId: string): Observable<any> {
        const url = WS_BASE + '/v1/locations/' + locationId + '/request-list';
        return this.http.post(url, {}).pipe(retry(0), catchError(this.handleError));
    }

    getListingTask(locationId: number): Observable<TaskDtoOld> {
        return this.http
            .get<TaskDtoOld>(`${WS_BASE}/v1/locations/${locationId}/listing-task`)
            .pipe(catchError(this.handleError));
    }

    listLocation(locationId: string): Observable<any> {
        const url = WS_BASE + '/v1/locations/' + locationId + '/list';
        return this.http.post(url, {}).pipe(retry(0), catchError(this.handleError));
    }

    unlistLocation(locationId: string): Observable<any> {
        const url = WS_BASE + '/v1/locations/' + locationId + '/unlist';
        return this.http.post(url, {}).pipe(retry(0), catchError(this.handleError));
    }

    getPaymentsTask(locationId: number): Observable<TaskDtoOld> {
        return this.http
            .get<TaskDtoOld>(`${WS_BASE}/v1/locations/${locationId}/payments-task`)
            .pipe(catchError(this.handleError));
    }

    getPayoutsTask(locationId: number): Observable<TaskDtoOld> {
        return this.http
            .get<TaskDtoOld>(`${WS_BASE}/v1/locations/${locationId}/payouts-task`)
            .pipe(catchError(this.handleError));
    }

    patchBooking(patch: any[], id: number): Observable<Booking> {
        const url = WS_BASE + '/v1/bookings/' + id;
        return this.http.patch<Booking>(url, patch).pipe(retry(0), catchError(this.handleError));
    }

    postBookingDetails(model, id: number, locationId: number): Observable<Booking> {
        const url = `${WS_BASE}/v1/bookings/${id}/details?locationId=${locationId}`;
        return this.http.post<Booking>(url, model).pipe(retry(0), catchError(this.handleError));
    }

    patchLocalizedText(patch: any[], id: number): Observable<LocalizedText> {
        const url = WS_BASE + '/v1/localized-texts/' + id;
        return this.http.patch<LocalizedText>(url, patch).pipe(retry(0), catchError(this.handleError));
    }

    partialRefundOrder(
        orderReference: string,
        locationId: number,
        model: PartialRefundRequest
    ): Observable<RefundResultDto> {
        const url = WS_BASE + `/payments/v3/locations/${locationId}/bookings/${orderReference}/partial-refund`;
        return this.http.post<RefundResultDto>(url, model).pipe(retry(1), catchError(this.handleError));
    }

    fullRefundOrder(orderReference: string, locationId: number, terminalId: string): Observable<RefundResultDto> {
        const url = WS_BASE + `/payments/v3/locations/${locationId}/bookings/${orderReference}/refund`;
        return this.http
            .post<RefundResultDto>(url, { terminalId })
            .pipe(retry(1), catchError(this.handleError));
    }

    fullRefundSplitPayment(orderReference: string, locationId: number): Observable<void> {
        const url = WS_BASE + `/payments/v4/locations/${locationId}/bookings/${orderReference}/refund`;
        return this.http.post<void>(url, {}).pipe(retry(1), catchError(this.handleError));
    }

    partialDiscardOrder(orderReference, model: PartialDiscard) {
        const url = WS_BASE + `/v1/bookings/${orderReference}/discard`;
        return this.http.post(url, model).pipe(retry(1), catchError(this.handleError));
    }

    sendReceipt(reference: string, name: string, mail: string): Observable<void> {
        const url = WS_BASE + '/public/v1/mails/receipt';
        return this.http
            .post<void>(url, { reference, name, mail }, refundOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    printReceipt(bookingReference: string, placementId: number, receiptType: 'SALES_RECEIPT' | 'PROFORMA_RECEIPT') {
        const url = WS_BASE + `/v2/bookings/${bookingReference}/print-receipt`;
        return this.http
            .post(url, { placementId, copies: 1, type: receiptType })
            .pipe(retry(1), catchError(this.handleError));
    }

    getVisitingAddressForLocation(locationId: string) {
        const url = WS_BASE + `/admin/v1/addresses/locations/${locationId}/POS`;
        return this.http.get<LocationAddress>(url).pipe(catchError(this.handleError));
    }

    updateVisitingAddress(id: number, value: LocationAddress) {
        const url = WS_BASE + `/admin/v1/addresses/${id}`;
        return this.http.put<LocationAddress>(url, value).pipe(retry(0), catchError(this.handleError));
    }

    addVisitingAddress(value: LocationAddress) {
        const url = WS_BASE + `/admin/v1/addresses`;
        return this.http.post<LocationAddress>(url, value).pipe(retry(0), catchError(this.handleError));
    }

    saveLocationSettings(setting: LocationSetting): Observable<LocationSetting> {
        const url = `${WS_BASE}/v1/location-settings/`;
        return this.http.post<LocationSetting>(url, setting).pipe(catchError(this.handleError));
    }

    getLocationSettings(type: SettingsType, locationId: string): Observable<LocationSetting> {
        const url = `${WS_BASE}/v1/location-settings/types/${type}/locations/${locationId}`;
        return this.http.get<LocationSetting>(url);
    }

    patchLocationSettings(patchData: Patch[], id: string): Observable<LocationSetting> {
        const url = `${WS_BASE}/v1/location-settings/${id}/`;
        return this.http.patch<LocationSetting>(url, patchData).pipe(catchError(this.handleError));
    }

    getOrganizationNo(locationId: number): Observable<Location> {
        const url = `${WS_BASE}/v1/locations/` + locationId + '/organizationnumber';
        return this.http.get<LocationDto>(url).pipe(catchError(this.handleError));
    }

    registerTip(registerTipDto: RegisterTipRequestDto, locationId: number): Observable<RegisterTipResponseDto> {
        const url = `${WS_BASE}/v2/locations/${locationId}/tip`;
        return this.http.post<RegisterTipResponseDto>(url, registerTipDto).pipe(retry(0), catchError(this.handleError));
    }

    getSumTipToday(locationId: number, paymentMethodType: string): Observable<RegisterTipResponseDto> {
        const url = `${WS_BASE}/v2/locations/${locationId}/tip?paymentMethod=${paymentMethodType}`;
        return this.http.get<RegisterTipResponseDto>(url).pipe(catchError(this.handleError));
    }

    getEposFeatureState(locationId: number): Observable<FeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/epos`;
        return this.http.get<FeatureToggle>(url).pipe(catchError(this.handleError));
    }

    toggleEpos(locationId: number, activate: boolean): Observable<FeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/epos/toggle`;
        return this.http
            .post<FeatureToggle>(url, { activate })
            .pipe(catchError(this.handleError));
    }

    getLocationFoodCourtFeatureState(locationId: number): Observable<FeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/food-court`;
        return this.http.get<FeatureToggle>(url).pipe(catchError(this.handleError));
    }

    toggleIsFoodCourtHostLocation(locationId: number, active: boolean): Observable<FeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/food-court/toggle`;
        return this.http
            .post<FeatureToggle>(url, { active })
            .pipe(catchError(this.handleError));
    }

    toggleAllUsersMustVerify(locationId: number, activate: boolean): Observable<FeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/verify-all-users/toggle`;
        return this.http
            .post<FeatureToggle>(url, { activate })
            .pipe(catchError(this.handleError));
    }

    getServiceChargeFeatureState(locationId: number): Observable<FeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/service-charge`;
        return this.http.get<FeatureToggle>(url).pipe(catchError(this.handleError));
    }

    toggleServiceCharge(locationId: number, activate: boolean): Observable<FeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/service-charge/toggle`;
        return this.http
            .post<FeatureToggle>(url, { activate })
            .pipe(catchError(this.handleError));
    }

    getGiftCardTypesByLocation(locationId: number): Observable<GiftCardResponse<GiftCardType[]>> {
        const url = `${WS_BASE}/v1/gift-card/locations/${locationId}/gift-card-types`;
        return this.http.get<GiftCardResponse<GiftCardType[]>>(url).pipe(catchError(this.handleError));
    }

    getGiftCardType(giftCardTypeId: string): Observable<GiftCardResponse<GiftCardType>> {
        const url = `${WS_BASE}/v1/gift-card/gift-card-types/${giftCardTypeId}`;
        return this.http.get<GiftCardResponse<GiftCardType>>(url).pipe(catchError(this.handleError));
    }

    getGiftCardsByType(
        giftCardTypeId: string,
        limit?: number,
        search?: string,
        fromDate?: string,
        toDate?: string
    ): Observable<GiftCardResponse<GiftCardListPage>> {
        const limitValue = limit || 20;
        let url = `${WS_BASE}/v1/gift-card/gift-card-types/${giftCardTypeId}/cards?limit=${limitValue}`;
        if (search) url += `&search=` + encodeURIComponent(search);
        if (fromDate) url += `&from=${fromDate}`;
        if (toDate) url += `&to=${toDate}`;
        return this.http.get<GiftCardResponse<GiftCardListPage>>(url).pipe(catchError(this.handleError));
    }

    getGiftCardDetails(giftCardId: string): Observable<GiftCardResponse<GiftCard>> {
        const url = `${WS_BASE}/v1/gift-card/cards/${giftCardId}`;
        return this.http.get<GiftCardResponse<GiftCard>>(url).pipe(catchError(this.handleError));
    }

    postResendGiftCardById(giftCardId: string): Observable<GiftCardResponse<boolean>> {
        const url = `${WS_BASE}/v1/gift-card/cards/${giftCardId}/resend`;
        return this.http.post<GiftCardResponse<boolean>>(url, {}).pipe(retry(0), catchError(this.handleError));
    }

    postDeactivateGiftCardById(
        giftCardId: string,
        model: DeactivateGiftCardRequest
    ): Observable<GiftCardResponse<GiftCard>> {
        const url = `${WS_BASE}/v1/gift-card/cards/${giftCardId}/deactivate`;
        return this.http.post<GiftCardResponse<GiftCard>>(url, model).pipe(retry(0), catchError(this.handleError));
    }

    postUpdateGiftCardType(
        name: string,
        locationIds: number[],
        expirationYears: number,
        id: string,
        displayPicture?: Picture,
        logoPicture?: Picture
    ): Observable<GiftCardResponse<GiftCardType>> {
        const model = { id, name, locationIds, expirationYears, displayPicture, logoPicture };
        const url = `${WS_BASE}/v1/gift-card/gift-card-types`;
        return this.http.post<GiftCardResponse<GiftCardType>>(url, model).pipe(retry(0), catchError(this.handleError));
    }

    deleteGiftCardType(id: string): Observable<GiftCardResponse<string>> {
        const url = `${WS_BASE}/v1/gift-card/gift-card-types/${id}`;
        return this.http.delete<GiftCardResponse<string>>(url).pipe(retry(0), catchError(this.handleError));
    }

    downloadGiftCardReport(giftCardTypeId: string, from: string, to: string) {
        let url = `${WS_BASE}/v1/gift-card/report/gift-card-types/${giftCardTypeId}`;
        if (from) {
            url += `?from=${encodeURIComponent(from)}`;
            if (to) {
                url += `&to=${encodeURIComponent(to)}`;
            }
        }

        return this.http
            .get(url, {
                responseType: 'blob'
            })
            .pipe(catchError(this.handleError));
    }

    getDigitalBillFeatureState(locationId: number): Observable<DigitalBillFeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/digital-bill`;
        return this.http.get<DigitalBillFeatureToggle>(url).pipe(catchError(this.handleError));
    }

    getAllUsersMustVerifyFeatureState(locationId: number): Observable<FeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/verify-all-users`;
        return this.http.get<FeatureToggle>(url).pipe(catchError(this.handleError));
    }

    getPrintingVersion2FeatureState(locationId: number): Observable<FeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/printing-version`;
        return this.http.get<FeatureToggle>(url).pipe(catchError(this.handleError));
    }

    getSoftpayTerminalsForLocation(locationId: number): Observable<SoftpayTerminal[]> {
        const url = `${WS_BASE}/locations/${locationId}/softpay-terminals`;

        return this.http.get<SoftpayTerminal[]>(url).pipe(catchError(this.handleError));
    }

    togglePrintingVersion2(locationId: number, active: boolean): Observable<FeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/printing-version/toggle`;
        return this.http
            .post<FeatureToggle>(url, { active })
            .pipe(catchError(this.handleError));
    }

    toggleDigitalBill(
        locationId: number,
        waiterCanInitiate: boolean,
        guestCanInitiate: boolean,
        guestCanAdd: boolean,
        guestCanPay: boolean
    ): Observable<DigitalBillFeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/digital-bill/toggle`;
        return this.http
            .post<DigitalBillFeatureToggle>(url, { waiterCanInitiate, guestCanInitiate, guestCanAdd, guestCanPay })
            .pipe(catchError(this.handleError));
    }

    getEPOSPaymentMethodsFeatureState(locationId: number): Observable<EPOSPaymentMethodsFeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/payment-methods`;
        return this.http.get<EPOSPaymentMethodsFeatureToggle>(url).pipe(catchError(this.handleError));
    }

    toggleEPOSPaymentMethodsFeatureState(
        locationId: number,
        cashEnabled: boolean,
        invoiceEnabled: boolean,
        integratedTerminalsEnabled: boolean,
        mobileTerminalsEnabled: boolean
    ): Observable<EPOSPaymentMethodsFeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/payment-methods/toggle`;
        return this.http
            .post<EPOSPaymentMethodsFeatureToggle>(url, {
                cashEnabled,
                invoiceEnabled,
                integratedTerminalsEnabled,
                mobileTerminalsEnabled
            })
            .pipe(catchError(this.handleError));
    }

    getPrintingFeatureToggleState(locationId: number): Observable<PrintingFeatureToggle> {
        const url = `${WS_BASE}/v1/locations/${locationId}/features/printing`;
        return this.http.get<PrintingFeatureToggle>(url).pipe(catchError(this.handleError));
    }

    getBanner(locationId: number): Observable<BannerDto> {
        const url = `${WS_BASE}/v1/locations/${locationId}/banner`;
        return this.http.get<BannerDto>(url).pipe(catchError(this.handleError));
    }

    togglePaymentDeadlineBanner(locationId: number, enable: boolean) {
        const url = `${WS_BASE}/v1/locations/${locationId}/banner/payment-deadline`;

        if (enable) {
            return this.http.post(url, {}).pipe(catchError(this.handleError));
        }
        return this.http.delete(url).pipe(catchError(this.handleError));
    }

    selectLocalizedText(list: LocalizedText[]) {
        if (list?.length) {
            const localizedText = list.find(text => this.locationDefaultLanguage === text.language);
            return localizedText || list.find(text => 'no' === text.language) || list[0];
        }
        return {
            language: 'no',
            text: ''
        };
    }

    goBack() {
        this.angularLocation.back();
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else if (err.error) {
            if (err.error.errorMessage) {
                errorMessage = `${err.error.errorMessage}`;
            } else if (err.error.message) {
                errorMessage = `${err.error.message}`;
            }
        } else {
            errorMessage = `Server returned code: ${err.status}, Message is: ${err.message}`;
        }
        this.toaster && this.toaster.danger('Something went wrong. Try again later.', 'Oh no!');

        console.error(errorMessage);
        return throwError(errorMessage);
    }
}
