import {
    CompleteBusinessDetails,
    Equipment,
    AgreementSignee,
    EquipmentOrder
} from './../../../../shared/interfaces/onboarding';
import { createAction, props } from '@ngrx/store';

export const setPricingPlan = createAction('[GettingStarted] SetPricingPlan', props<{ planId: string }>());
export const setCompleteBusinessDetails = createAction(
    '[GettingStarted] SetCompleteBusinessDetails',
    props<{ model: CompleteBusinessDetails }>()
);
export const setAgreementSignee = createAction(
    '[GettingStarted] SetAgreementSignee',
    props<{ model: AgreementSignee }>()
);
export const addEquipment = createAction('[GettingStarted] AddEquipment', props<{ equipment: EquipmentOrder }>());
export const removeEquipment = createAction('[GettingStarted] RemoveEquipment', props<{ equipment: EquipmentOrder }>());
export const resetEquipment = createAction('[GettingStarted] ResetEquipment');
export const setEquipmentFullyDigital = createAction('[GettingStarted] SetEquipmentFullyDigital');
// We need to create a selector that checks if equipment is set or skipped... combine 2 selectors https://ngrx.io/guide/store/selectors
