import { createAction, props } from '@ngrx/store';
import { NbMenuItem } from '@nebular/theme';
import { PlacementQueueItem } from '../../shared/interfaces/placement';

export const setMenuItems = createAction('[Pages Component] SetMenuItems', props<{ menuItems: NbMenuItem[] }>());

export const setOrderLogSearch = createAction(
    '[Pages Component] SetOrderLogSearch',
    props<{ orderLogSearch: { searchString: string; result: PlacementQueueItem[] } }>()
);
