export interface OnboardingCountry {
    code: string;
    name: string;
    onboardingType: OnboardingTypeEnum;
}

export interface OnboardingOrganizationStatus {
    onboardingType: OnboardingTypeEnum;
    orgNumber: string;
}

export enum OnboardingTypeEnum {
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC'
}

export interface ReferralSource {
    id: string;
    label: string;
}
export interface LocationType {
    name: string;
}

export interface PricingPlan {
    name: string;
    price: number;
    percentageOfMonthlyRevenue: number;
    currency: string;
}

export interface Equipment {
    id: string;
    name: string;
    description: string;
    price: number;
    currency: string;
    amount: number;
}

export interface EquipmentOrder {
    equipmentId: string;
    amount: number;
}

export interface EquipmentCart {
    id: string;
    amount: number;
}

export interface CompleteBusinessDetails {
    orgNumber?: string;
    legalName?: string;
    locationName?: string;
    locationType?: string;
    address?: string;
    zipCode?: string;
    city?: string;
    accountNumber?: string;
    localityId?: number;
    bankGiro?: string;
    bankName?: string;
}

export interface AgreementSignee {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export interface LocationStateCardProps {
    headerText: string;
    bodyText: string;
    toolTipText: string;
    buttonText: string;
    stateText: string;
    statusIndicatorClass: string;
    disableButton?: boolean;
    completionProgress: number;
    tasks?: PublicationTask[];
    onClick?: () => void;
}

export interface PublicationTask {
    icon: string;
    title: string;
    text: string;
    completed: boolean;
    onClick?: () => void;
}

export enum FormInitiatorEnum {
    LANDING_PAGE_CONTACT_US_BUTTON = 'LANDING_PAGE_CONTACT_US_BUTTON',
    LANDING_PAGE_ENTERPRISE_PRICING_PLAN = 'LANDING_PAGE_ENTERPRISE_PRICING_PLAN',
    ONBOARDING_FLOW_ENTERPRISE_PRICING_PLAN = 'ONBOARDING_FLOW_ENTERPRISE_PRICING_PLAN',
    UNKNOWN = 'UNKNOWN'
}
