export interface SSOConfig {
    authenticationServiceUrl: string;
    redirectUrl: string;
}

type Env = 'local' | 'local-qa' | 'qa' | 'prod' | unknown;

const config = {
    local: {
        v2: {
            authenticationServiceUrl: 'https://login.qa.favrit.com',
            redirectUrl: 'http://localhost:4200/#/auth/callback'
        },
        v3: {
            authenticationServiceUrl: 'https://login.qa.favrit.com/v3',
            redirectUrl: 'http://localhost:4200/#/auth/callback'
        }
    },
    'local-qa': {
        v2: {
            authenticationServiceUrl: 'https://login.qa.favrit.com',
            //authenticationServiceUrl: 'http://localhost:3005',
            redirectUrl: 'http://localhost:4200/#/auth/callback'
        },
        v3: {
            authenticationServiceUrl: 'https://login.qa.favrit.com/v3',
            redirectUrl: 'http://localhost:4200/#/auth/callback'
        }
    },
    qa: {
        v2: {
            authenticationServiceUrl: 'https://login.qa.favrit.com',
            redirectUrl: 'https://admin.qa.favrit.com/#/auth/callback'
        },
        v3: {
            authenticationServiceUrl: 'https://login.qa.favrit.com/v3',
            redirectUrl: 'https://admin.qa.favrit.com/#/auth/callback'
        }
    },
    prod: {
        v2: {
            authenticationServiceUrl: 'https://login.favrit.com',
            redirectUrl: 'https://admin.favrit.com/#/auth/callback'
        },
        v3: {
            authenticationServiceUrl: 'https://login.favrit.com/v3',
            redirectUrl: 'https://admin.favrit.com/#/auth/callback'
        }
    }
};

export const getConfig = (env: Env): SSOConfig => {
    switch (env) {
        case 'local':
        case 'local-qa':
        case 'qa':
            return config[env]['v3'];
        case 'prod':
            return config[env]['v2'];
        default:
            return null;
    }
};
