import { NbLayoutModule, NbProgressBarModule } from '@nebular/theme';
import { SharedModule } from '../../../../../shared/index';
import { NgModule } from '@angular/core';
import { GetStartedSignupCompletedComponent } from './get-started-signup-completed.component';

@NgModule({
    imports: [SharedModule, NbLayoutModule, NbProgressBarModule],
    declarations: [GetStartedSignupCompletedComponent]
})
export class GetStartedSignupCompletedModule {}
