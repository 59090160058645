import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OnBoardingService } from 'app/shared/services/on-boarding.service';
import { OnboardingTypeEnum } from 'app/shared/interfaces/onboarding';
import {
    getOrgNumberErrorMessageForSelectedCountry,
    orgNumberRegex
} from 'app/shared/validators/org-number-validation';

@Component({
    selector: 'app-get-started-enter-org-number',
    templateUrl: './get-started-enter-org-number.component.html',
    styleUrls: ['./get-started-enter-org-number.component.scss']
})
export class GetStartedEnterOrgNumberComponent implements OnInit {
    @ViewChild('firstFocus') firstFocus: ElementRef;
    public form: UntypedFormGroup;
    public isComponentLoading = false;
    public errorMessage;
    orgNumberRegex = orgNumberRegex.NO;
    orgNumberErrorMessage: string;
    orgNumberField: AbstractControl;

    constructor(
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private onboardingService: OnBoardingService
    ) {}

    ngOnInit() {
        if (!this.route.snapshot.queryParams?.country)
            this.router.navigate(['where-did-you-hear-about-us'], { relativeTo: this.route.parent });
        this.initForm();
        this.setFocus();
    }

    setFocus() {
        setTimeout(() => {
            this.firstFocus && this.firstFocus.nativeElement.focus();
        });
    }

    initForm() {
        this.form = this.fb.group({
            orgNumber: ['', Validators.required]
        });
        this.orgNumberField = this.form.controls.orgNumber;
        this.orgNumberErrorMessage = getOrgNumberErrorMessageForSelectedCountry('NO');
    }

    goToNextStep() {
        this.isComponentLoading = true;
        this.onboardingService
            .getIsOrganizationStatus(this.route.snapshot.queryParams?.country, this.form.get('orgNumber').value)
            .subscribe(
                res => {
                    this.isComponentLoading = false;
                    if (res.onboardingType === OnboardingTypeEnum.AUTOMATIC) {
                        this.router.navigate(['enter-email'], {
                            relativeTo: this.route.parent,
                            queryParams: {
                                country: this.route.snapshot.queryParams?.country,
                                orgNr: this.form.get('orgNumber').value,
                                pricingPlanName: this.route.snapshot.queryParams?.pricingPlanName,
                                referralSourceId: this.route.snapshot.queryParams?.referralSourceId
                            }
                        });
                    }
                    if (res.onboardingType === OnboardingTypeEnum.MANUAL) {
                        this.router.navigate(['enter-business-details'], {
                            relativeTo: this.route.parent,
                            queryParams: {
                                country: this.route.snapshot.queryParams?.country,
                                orgNr: this.form.get('orgNumber').value,
                                pricingPlanName: this.route.snapshot.queryParams?.pricingPlanName,
                                referralSourceId: this.route.snapshot.queryParams?.referralSourceId
                            }
                        });
                    }
                },
                err => {
                    this.isComponentLoading = false;
                    this.errorMessage = err.error?.message ?? 'Something went wrong.';
                }
            );
    }
}
