import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { AppState } from 'app/pages/pages-state';
import { LocationState } from 'app/pages/pages-state/location/location.reducer';
import { selectCurrentLocation } from 'app/pages/pages-state/location/location.selectors';
import { TaskState } from 'app/pages/pages-state/task/task.reducer';
import { selectCurrentTasks } from 'app/pages/pages-state/task/task.selectors';
import { TaskStatus } from 'app/shared/components/tasks/task/task';
import { selectCurrentUrl } from 'app/store/router/custom-route-serializer';
import { Subscription } from 'rxjs';

declare const window: any;

@Component({
    selector: 'ngx-one-column-layout',
    styleUrls: ['./one-column.layout.scss'],
    template: `
        <nb-layout>
            <nb-layout-header class="d-print-none" fixed>
                <ngx-header></ngx-header>
            </nb-layout-header>
            <nb-sidebar class="menu-sidebar d-print-none" tag="menu-sidebar" responsive start>
                <ng-content select="nb-menu"></ng-content>
            </nb-sidebar>

            <nb-layout-column (click)="closeMenu()">
                <account-status-bar
                    [style.display]="showAccountStatusBar ? 'flex' : 'none'"
                    class="flex-row"
                    [displayHideButton]="!isOrganizationRoute"
                ></account-status-bar>

                <ng-content select="router-outlet"></ng-content>
            </nb-layout-column>

            <nb-layout-footer class="d-print-none" [fixed]="true">
                <ngx-footer></ngx-footer>
            </nb-layout-footer>
        </nb-layout>
    `
})
export class OneColumnLayoutComponent implements OnInit, OnDestroy {
    private currentUrlSub: Subscription;
    private currentTasksSub: Subscription;
    private currentLocationSub: Subscription;
    locationId: string;
    showAccountStatusBar = false;
    isAccountStatusBarStateHidden = false;
    isStatusBarTasksDone: boolean = undefined;
    currentUrl: string;
    isOrganizationRoute: boolean;

    constructor(
        private locationStore: Store<LocationState>,
        private tasksStore: Store<TaskState>,
        private appStore: Store<AppState>,
        private sidebarService: NbSidebarService
    ) {}

    ngOnInit() {
        this.subscribeToCurrentLocation();
        this.subscribeToCurrentUrl();
        this.subscribeToCurrentTasks();
    }

    ngOnDestroy() {
        this.currentLocationSub?.unsubscribe();
        this.currentUrlSub?.unsubscribe();
        this.currentTasksSub?.unsubscribe();
    }

    public subscribeToCurrentLocation() {
        this.currentLocationSub = this.locationStore.select(selectCurrentLocation).subscribe(currentLocation => {
            if (currentLocation) {
                this.locationId = currentLocation.id.toString();

                if (currentLocation.settings) {
                    const settings = currentLocation.settings;

                    this.isAccountStatusBarStateHidden = settings.hideAccountStatusBar;
                } else {
                    this.isAccountStatusBarStateHidden = false;
                }

                this.evaluateShowAccountStatusBar();
            }
        });
    }

    public closeMenu() {
        this.sidebarService.compact('menu-sidebar');
    }

    subscribeToCurrentUrl() {
        this.currentUrlSub = this.appStore.select(selectCurrentUrl).subscribe(url => {
            this.currentUrl = url;
            this.isOrganizationRoute = this.currentUrl.startsWith(`/location/${this.locationId}/organization`);
            this.evaluateShowAccountStatusBar();
        });
    }

    subscribeToCurrentTasks() {
        this.currentTasksSub = this.tasksStore.select(selectCurrentTasks).subscribe(currentTasks => {
            if (currentTasks) {
                this.isStatusBarTasksDone = !currentTasks.some(
                    task => task.includeInAccountStatus && task.status !== TaskStatus.COMPLETED
                );
                this.evaluateShowAccountStatusBar();
            }
        });
    }

    evaluateShowAccountStatusBar() {
        if (this.isStatusBarTasksDone === undefined || !this.currentUrl) {
            return;
        }

        const isInternalRoute = this.currentUrl.startsWith('/location/internal');
        const isChangeLocationsRoute = this.currentUrl.startsWith('/location/locations');

        let isEligibleRoute = !(isInternalRoute || isChangeLocationsRoute);

        if (this.isAccountStatusBarStateHidden) {
            isEligibleRoute = this.isOrganizationRoute;
        }

        this.showAccountStatusBar = isEligibleRoute && !this.isStatusBarTasksDone;
    }
}
