import { createReducer, on } from '@ngrx/store';
import {
    addEquipment,
    setPricingPlan,
    removeEquipment,
    resetEquipment,
    setCompleteBusinessDetails,
    setAgreementSignee,
    setEquipmentFullyDigital
} from './getting-started.actions';
import { GettingStarted } from '.';

export const initialState: GettingStarted = {
    pricingPlanName: null,
    equipmentOrders: [],
    selectEquipmentSkipped: false,
    selectEquipmentFullyDigital: false,
    businessDetails: {
        locationName: '',
        accountNumber: '',
        address: '',
        city: '',
        legalName: '',
        locationType: '',
        orgNumber: '',
        zipCode: ''
    },
    agreementSignee: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: ''
    }
};

const gettingStartedReducer = createReducer(
    initialState,

    on(setPricingPlan, (state, { planId }) => ({
        ...state,
        pricingPlanName: planId
    })),
    on(resetEquipment, state => ({
        ...state,
        selectEquipmentSkipped: true,
        equipmentOrders: []
    })),
    on(setCompleteBusinessDetails, (state, { model }) => ({
        ...state,
        businessDetails: model
    })),
    on(setAgreementSignee, (state, { model }) => ({
        ...state,
        agreementSignee: model
    })),
    on(addEquipment, (state, { equipment }) => {
        const itemInStateIndex = state.equipmentOrders.findIndex(x => x.equipmentId === equipment.equipmentId);

        if (itemInStateIndex !== -1) {
            const objectCopy = { ...state.equipmentOrders[itemInStateIndex] };
            objectCopy.amount += 1;

            let arrayCopy = [...state.equipmentOrders];
            arrayCopy.splice(itemInStateIndex, 1);

            return {
                ...state,
                equipmentOrders: [...arrayCopy, objectCopy]
            };
        }

        return {
            ...state,
            selectEquipmentSkipped: false,
            equipmentOrders: [...state.equipmentOrders, { ...equipment, amount: 1 }]
        };
    }),
    on(removeEquipment, (state, { equipment }) => {
        const itemInStateIndex = state.equipmentOrders.findIndex(x => x.equipmentId === equipment.equipmentId);

        if (itemInStateIndex === -1) {
            return state;
        }

        const objectCopy = { ...state.equipmentOrders[itemInStateIndex] };
        objectCopy.amount = objectCopy.amount > 0 ? objectCopy.amount - 1 : 0;

        let arrayCopy = [...state.equipmentOrders];
        arrayCopy.splice(itemInStateIndex, 1);

        return {
            ...state,
            equipmentOrders: [...arrayCopy, objectCopy]
        };
    }),
    on(setEquipmentFullyDigital, state => {
        return {
            ...state,
            selectEquipmentFullyDigital: !state.selectEquipmentFullyDigital
        };
    })
);

export function reducer(state, action) {
    return gettingStartedReducer(state, action);
}
