import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticatedUser } from 'app/shared/interfaces/auth/simple-user';
import { environment } from 'environments/environment';
import { filter } from 'rxjs/operators';
import { Userpilot } from 'userpilot';
import { Location } from 'app/shared/interfaces/location';

@Injectable()
export class AnalyticsService {
    constructor(private router: Router) {}

    initiate() {
        const userpilotToken =
            environment.environment === 'qa' || environment.environment === 'local-qa'
                ? 'STG-NX-f4b682c9'
                : 'NX-f4b682c9';
        Userpilot.initialize(userpilotToken);
    }

    identifyUser(authenticatedUser?: AuthenticatedUser, location?: Location) {
        if (!authenticatedUser || !authenticatedUser.userName) {
            return;
        }

        Userpilot.identify(authenticatedUser.userName, {
            email: authenticatedUser.userName,
            role: authenticatedUser.userType || 'UNKNOWN',
            company: {
                id: location?.locationGroupId,
                locationId: location?.locationId,
                name: location?.name
            }
        });
    }

    trackPageViews() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            Userpilot.reload();
        });
    }
}
