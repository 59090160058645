import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { PreviousRouteService } from 'app/pages/service/previousRouteService';
import FavritSSOStrategy from 'app/shared/auth/FavritSSOStrategy';
import { getConfig } from 'app/shared/auth/SSOConfig';
import { environment } from 'environments/environment';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'applogout',
    template: ''
})
export class Logout {
    constructor(
        private ssoStrategy: FavritSSOStrategy,
        private activatedRoute: ActivatedRoute,
        private previousRouteService: PreviousRouteService
    ) {}

    async ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.redirect(params);
        });
    }

    public redirect(queryParams: Params) {
        sessionStorage.removeItem('auth_app_token');
        localStorage.removeItem('auth_app_token');
        const type = queryParams['type'];

        if (type === 'CHANGE_USER') {
            const locationId = queryParams['location_id'];
            sessionStorage.setItem('redirect', '/location/' + locationId);
            const redirectUrl = this.ssoStrategy.buildRedirectUrl('pin', locationId);
            window.location.href = redirectUrl;
            return;
        }

        const logoutUrl = getConfig(environment.environment).authenticationServiceUrl + '/logout';
        window.location.href = logoutUrl;
    }
}
