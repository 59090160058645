import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';
import { AccountStatusAccordionComponent } from './account-status-accordion.component';
import { CircularProgressIndicatorComponentModule } from '../../circular-progress-indicator';
import { HideAccountStatusBarButtonModule } from '../hide-account-status-bar-button';

@NgModule({
    imports: [SharedModule, CircularProgressIndicatorComponentModule, HideAccountStatusBarButtonModule],
    declarations: [AccountStatusAccordionComponent],
    exports: [AccountStatusAccordionComponent]
})
export class TasksAccordionComponentModule {}
