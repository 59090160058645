<nb-layout center class="colored-layout-white">
    <nb-layout-column>
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-md-12 m-auto h-100">
                    <div class="d-flex align-items-center flex-column justify-content-center h-100">
                        <div class="form-container-wrapper">
                            <div class="section-spacer d-flex justify-content-center ">
                                <favrit-logo></favrit-logo>
                            </div>
                            <div class="text-center section-spacer">
                                <h1>Thank you for getting in touch!</h1>
                                <p class="mb-30">Someone from our will get in touch with you very soon.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
