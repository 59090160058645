<nb-card fullWidth class="bg-gray h-100">
    <nb-card-body class="d-flex flex-column">
        <ng-container *ngIf="pricingPlan; else customPlan">
            <div class="mb-20 price">
                <h4 class="plan-name" *ngIf="pricingPlan.name === 'SMALL'">Basic</h4>
                <h4 class="plan-name" *ngIf="pricingPlan.name === 'MEDIUM'">Pro</h4>
                <div class="mb-1">
                    <div>
                        <span class="h2">{{ pricingPlan.price }} </span> <strong>{{ pricingPlan.currency }}</strong
                        ><span>/MONTH</span>
                    </div>
                </div>
                <div>
                    <nb-icon status="primary" icon="plus-outline"></nb-icon>
                    <strong>{{ pricingPlan.percentageOfMonthlyRevenue }}%</strong> <span> OF MONTHLY revenue</span>
                </div>
            </div>

            <div class="mb-30 ml-40">
                <ng-container *ngIf="pricingPlan.name === 'SMALL'">
                    <div *ngFor="let smallBullet of smallPricingPlanBullets" class="d-flex mb-10">
                        <div class="mr-10">
                            <nb-icon status="primary" icon="checkmark-circle-2"></nb-icon>
                        </div>
                        {{ smallBullet }}
                    </div>
                </ng-container>
                <ng-container *ngIf="pricingPlan.name === 'MEDIUM'">
                    <div *ngFor="let mediumBullet of mediumPricingPlanBullets" class="d-flex mb-10">
                        <div class="mr-10">
                            <nb-icon status="primary" icon="checkmark-circle-2"></nb-icon>
                        </div>
                        {{ mediumBullet }}
                    </div>
                </ng-container>
            </div>
            <div class="mt-auto">
                <button nbButton fullWidth (click)="handlePlanClick(pricingPlan.name)" status="primary">
                    Choose plan
                </button>
            </div>
        </ng-container>
    </nb-card-body>
</nb-card>

<!-- Custom plan -->
<ng-template #customPlan>
    <div class="h-100 d-flex flex-column">
        <div class="mb-20 price">
            <h4 class="plan-name">Enterprise</h4>
            <div class="mb-1">
                <div><span class="h2">CUSTOM </span></div>
            </div>
        </div>

        <div class="mb-30">
            Do you have more than one location? Or have a festival, event, or similar?
            <br />
            <br />
            Get in touch and we'll make a plan tailored for your needs
        </div>

        <div class="mt-auto">
            <a nbButton fullWidth (click)="handlePlanClick('CUSTOM')" status="primary">Contact us</a>
        </div>
    </div>
</ng-template>
