<ng-container *transloco="let t; read: 'contact_us'">
    <div class="d-flex justify-content-center mb-50">
        <favrit-logo></favrit-logo>
    </div>
    <div class="text-center mb-50">
        <h1>{{ t('headline') }}</h1>
        <p>{{ t('description') }}</p>
    </div>

    <form [formGroup]="form" (ngSubmit)="handleSubmit()">
        <div class="form-group" *ngIf="form.get('countryCode').enabled">
            <label class="label" for="countryCode">{{ t('form.country_label') }}</label>
            <ng-container *ngIf="countries | async as countries">
                <nb-select
                    fullWidth
                    id="countryCode"
                    [placeholder]="t('form.country_placeholder')"
                    formControlName="countryCode"
                >
                    <nb-option [value]="country.code" *ngFor="let country of countries">{{ country.name }} </nb-option>
                </nb-select>
            </ng-container>
        </div>
        <div class="form-group" *ngIf="form.get('locationName').enabled">
            <label class="label" for="locationName">{{ t('form.location_name_label') }}</label>
            <input
                type="text"
                id="locationName"
                formControlName="locationName"
                [placeholder]="t('form.location_name_placeholder')"
                nbInput
                fullWidth
            />
        </div>
        <div class="form-group" *ngIf="form.get('locationType').enabled">
            <label class="label" for="locationType">{{ t('form.location_type_label') }}</label>
            <ng-container *ngIf="locationTypes | async as locations">
                <nb-select fullWidth id="locationType" formControlName="locationType">
                    <nb-option *ngFor="let loc of locations" [value]="loc.name">{{ loc.name }}</nb-option>
                </nb-select>
            </ng-container>
        </div>
        <div class="form-group">
            <label class="label" for="inquiry">{{ t('form.enquiry_label') }}</label>
            <textarea nbInput id="inquiry" formControlName="inquiry" fullWidth rows="5"></textarea>
        </div>

        <div class="form-group" *ngIf="form.get('firstName').enabled">
            <label class="label" for="firstname">{{ t('form.first_name_label') }}</label>
            <input type="text" id="firstname" formControlName="firstName" nbInput fullWidth />
        </div>
        <div class="form-group" *ngIf="form.get('lastName').enabled">
            <label class="label" for="lastname">{{ t('form.last_name_label') }}</label>
            <input type="text" id="lastname" formControlName="lastName" nbInput fullWidth />
        </div>
        <div class="form-group" *ngIf="form.get('email').enabled">
            <label class="label" for="email">
                {{ t('form.email_label') }}
            </label>
            <input type="text" id="email" formControlName="email" nbInput fullWidth />
        </div>
        <div class="form-group mb-50" *ngIf="form.get('phoneNumber').enabled">
            <label class="label" for="phone">{{ t('form.phone_number_label') }}</label>
            <input type="text" id="phone" formControlName="phoneNumber" nbInput fullWidth />
        </div>

        <div class="form-group">
            <button
                fullWidth
                nbButton
                [disabled]="form.invalid || isFormSubmitting"
                [nbSpinner]="isFormSubmitting"
                nbSpinnerStatus="primary"
                status="primary"
                type="submit"
            >
                {{ t('form.submit_button_text') }}
            </button>
        </div>
        <nb-alert *ngIf="submitErrorText" status="danger">{{ submitErrorText }}</nb-alert>
    </form>
</ng-container>
