import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-get-started-contact-us-confirmation',
    templateUrl: './get-started-contact-us-confirmation.component.html',
    styleUrls: ['./get-started-contact-us-confirmation.component.scss']
})
export class GetStartedContactUsConfirmationComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
