<nb-layout center class="colored-layout-white fullWidth mx-auto">
    <nb-layout-column>
        <div class="container">
            <div class="row">
                <div class="col-md-10 col-lg-6 m-auto">
                    <div class="mb-50">
                        <nb-progress-bar
                            [value]="25"
                            status="primary"
                            [displayValue]="true"
                            size="tiny"
                        ></nb-progress-bar>
                    </div>
                    <div class="d-flex justify-content-center">
                        <favrit-logo></favrit-logo>
                    </div>
                    <div class="text-center section-spacer">
                        <h1>Do you need any equipment?</h1>
                    </div>
                    <div class="d-flex text-center mx-auto flex-column justify-content-center mb-30 intro-text">
                        <p>
                            Our system is digital and can run on your existing tablet or computer. It can also be paired
                            with a ticket printer. If you want to use a printer, you will have to order it from us. Fill
                            out the equipment you want below.
                        </p>
                    </div>
                    <div class="items d-flex  mx-auto flex-column justify-content-center mb-30 items-container">
                        <nb-card
                            [ngClass]="{ 'is-fully-digital': isEquipmentFullyDigital }"
                            (click)="handleFullyDigitalClicked()"
                        >
                            <nb-card-body>
                                <div *ngIf="isAnyEquipmentSelected" class="disabled"></div>
                                <div class="cursor">
                                    <div class="item-info d-flex">
                                        <div>
                                            <h5 [ngClass]="{ 'text-color-primary': isEquipmentFullyDigital }">
                                                Fully digital
                                            </h5>
                                            <p>
                                                You can use Favrit fully digitally, without buying any equipment. Favrit
                                                can be used in the browser on any device.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </nb-card-body>
                        </nb-card>

                        <ng-container *ngFor="let item of equipment | async">
                            <select-equipment-item
                                [disabled]="isEquipmentFullyDigital"
                                [equipment]="item"
                            ></select-equipment-item>
                        </ng-container>
                        <div class="mb-30"></div>
                        <button
                            [disabled]="!isAnyEquipmentSelected"
                            *ngIf="!isEquipmentFullyDigital"
                            nbButton
                            class="mb-10"
                            (click)="handleBuyClick()"
                            status="primary"
                        >
                            Buy equipment
                        </button>
                        <button
                            nbButton
                            *ngIf="isEquipmentFullyDigital"
                            (click)="handleSkipClick()"
                            class="mb-10"
                            status="primary"
                        >
                            Go fully digital
                        </button>
                        <button nbButton (click)="handleSkipClick()" ghost>Skip this step for now</button>
                    </div>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
