import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { LoadCallState, CreateCallState, LoadState, CreateState } from 'app/store/shared';

import { Location } from '../../../shared/interfaces/location';
import * as LocationActions from './location.actions';

export const locationFeatureKey = 'location';

export interface LocationState extends EntityState<Location> {
    loadLocationsState: LoadCallState;
    loadLocationState: LoadCallState;
    createPlacementState: CreateCallState;
}

export const adapter = createEntityAdapter<Location>();

export const initialState = adapter.getInitialState({
    loadLocationsState: LoadState.INIT,
    loadLocationState: LoadState.INIT,
    createPlacementState: CreateState.INIT
});

export const locationReducer = createReducer<LocationState>(
    initialState,

    on(LocationActions.loadLocations, (state, action) => ({
        ...state,
        loadLocationsState: LoadState.LOADING
    })),
    on(LocationActions.loadLocationsSuccess, (state, action) => {
        const updatedState = adapter.upsertMany(action.locations, state);

        return {
            ...updatedState,
            loadLocationsState: LoadState.LOADED
        };
    }),
    on(LocationActions.loadLocation, (state, action) => ({
        ...state,
        loadLocationState: LoadState.LOADING
    })),
    on(LocationActions.loadLocationSuccess, (state, { location }) => {
        const updatedState = adapter.upsertOne(location, state);

        return {
            ...updatedState,
            loadLocationState: LoadState.LOADED
        };
    }),
    on(LocationActions.loadLocationFailure, (state, { errorMessage }) => ({
        ...state,
        loadLocationState: { errorMessage: errorMessage } as LoadCallState
    })),
    on(LocationActions.updateLocationSuccess, (state, action) => adapter.upsertOne(action.location, state)),
    on(LocationActions.createPlacement, (state, action) => ({
        ...state,
        createPlacementState: CreateState.CREATING
    })),
    on(LocationActions.createPlacementSuccess, (state, { location }) => {
        const updatedState = adapter.updateOne({ id: location.id, changes: location }, state);

        return {
            ...updatedState,
            createPlacementState: CreateState.CREATED
        };
    }),
    on(LocationActions.createPlacementFailure, (state, action) => ({
        ...state,
        createPlacementState: { errorMessage: action.errorMessage } as CreateCallState
    }))
);
