<nb-layout center class="colored-layout-white">
    <nb-layout-column>
        <div class="container m-auto h-100">
            <div class="row h-100">
                <div class="col-md-12 m-auto h-100">
                    <div class="d-flex align-items-center flex-column justify-content-center h-100">
                        <div class="section-spacer d-flex justify-content-center">
                            <favrit-logo></favrit-logo>
                        </div>
                        <div class="text-center section-spacer">
                            <h1>
                                Enter the org.number <br />
                                of your business
                            </h1>
                            <p>
                                You can find the org. number by searching for your business in
                                <a href="https://www.brreg.no/" target="_blank">Brønnøysundregistrene</a>
                            </p>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="form-container-wrapper">
                                <form [formGroup]="form" (ngSubmit)="goToNextStep()">
                                    <div class="form-group">
                                        <label class="label" for="orgNumber">Org. number</label>
                                        <ng-container>
                                            <input
                                                type="text"
                                                #firstFocus
                                                nbInput
                                                formControlName="orgNumber"
                                                [pattern]="orgNumberRegex"
                                                fullWidth
                                            />
                                            <div
                                                *ngIf="orgNumberField.invalid && orgNumberField.touched"
                                                class="text-danger"
                                            >
                                                <small *ngIf="orgNumberField.errors">
                                                    {{ orgNumberErrorMessage }}
                                                </small>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="form-group">
                                        <button
                                            nbButton
                                            [nbSpinner]="isComponentLoading"
                                            nbSpinnerStatus="primary"
                                            [disabled]="form.invalid || isComponentLoading"
                                            status="primary"
                                            type="submit"
                                            fullWidth
                                        >
                                            CONTINUE
                                        </button>
                                    </div>
                                </form>
                                <nb-alert *ngIf="errorMessage" status="danger">{{ errorMessage }}</nb-alert>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
