import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: 'https://7d32818729a1447287b0f807399af69e@sentry.io/2159511',
    environment: environment.environment,
    ignoreErrors: [/Server returned code: 403/]
});

// Here we can add meta data to sentry if we want
Sentry.configureScope(scope => {
    scope.setTag('application', 'ordr-admin');
});

@Injectable()
export class SentryErrorHandler {
    constructor() {}
    handleError(error) {
        if (
            environment.environment === 'local' ||
            environment.environment === 'local-qa' ||
            environment.environment === 'dev'
        ) {
            console.error(error);
        }
        if (environment.enable_sentry) {
            Sentry.captureException(error.originalError || error);
        }
    }
}
