import { OnBoardingService } from 'app/shared/services/on-boarding.service';
import {
    listLocation,
    requestPublishLocation,
    unlistLocation,
    unpublishLocation
} from 'app/pages/pages-state/location/location.actions';
import { LocationState } from 'app/pages/pages-state/location/location.reducer';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { OrdrService } from 'app/shared/services/ordr.service';

@Injectable({
    providedIn: 'root'
})
export class TaskActionService {
    constructor(
        private onboardingService: OnBoardingService,
        private ordrService: OrdrService,
        private locationStore: Store<LocationState>
    ) {}

    async startKyc(locationId: number): Promise<void> {
        try {
            const response = await this.onboardingService.getOnboardingUrl(locationId).toPromise();
            if (response.url) {
                window.open(response.url, '_newtab');
            }
        } catch (e) {
            alert('An error occurred when trying to redirect to Payment Service Provider');
        }
    }

    publishLocation(locationId: number): void {
        this.locationStore.dispatch(requestPublishLocation({ locationId }));
    }

    unpublishLocation(locationId: number): void {
        this.locationStore.dispatch(unpublishLocation({ locationId }));
    }

    listLocation(locationId: number): void {
        this.locationStore.dispatch(listLocation({ locationId }));
    }

    unlistLocation(locationId: number): void {
        this.locationStore.dispatch(unlistLocation({ locationId }));
    }
}
