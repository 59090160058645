import { mapLocaleToCountryCode } from '../helpers/localisation-helpers';
import { LocaleId } from '../interfaces/locale';

export const orgNumberRegex = {
    NO: /^\d{9}$/,
    SE: /^\d{6}-\d{4}$/,
    UK: /^\d{8}$/,
    DK: /^\d{8}$/,
    default: /^[\d-]+$/
};

export const getOrgNumberRegexForSelectedCountry = (countryCode: string): RegExp => {
    switch (countryCode) {
        case 'NO':
            return orgNumberRegex.NO;
        case 'SE':
            return orgNumberRegex.SE;
        case 'UK':
            return orgNumberRegex.UK;
        case 'DK':
            return orgNumberRegex.DK;
        default:
            return orgNumberRegex.default;
    }
};

export const getOrgNumberRegexForCountryFromLocale = (locale: LocaleId): RegExp => {
    const countryCode = mapLocaleToCountryCode(locale);
    return getOrgNumberRegexForSelectedCountry(countryCode);
};

export const getOrgNumberErrorMessageForSelectedCountry = (countryCode: string): string => {
    switch (countryCode) {
        case 'NO':
            return 'Org. number must consist of 9 digits';
        case 'SE':
            return 'Org. number must consist of 6 digits, a dash and another 4 digits';
        case 'UK':
            return 'Company number must consist of 8 digits';
        case 'DK':
            return 'CVR number must consist of 8 digits';
        default:
            return 'Invalid org. number';
    }
};

export const getOrgNumberTranslation = (countryCode: string): string => {
    switch (countryCode) {
        case 'UK':
            return 'Company number';
        case 'DK':
            return 'CVR number';
        default:
            return `<abbr title="Organization">Org.</abbr> number`;
    }
};
