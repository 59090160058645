import { Injectable } from '@angular/core';
import { NbAuthToken, NbAuthTokenParceler, NbTokenStorage } from '@nebular/auth';

@Injectable()
export class FavritSSOTokenStorage extends NbTokenStorage {
    protected key = 'auth_app_token';

    constructor(private parceler: NbAuthTokenParceler) {
        super();
    }

    get(): NbAuthToken {
        let raw = sessionStorage.getItem(this.key);

        if (!raw) {
            raw = localStorage.getItem(this.key);
        }

        return this.parceler.unwrap(raw);
    }

    set(token: NbAuthToken): void {
        const raw = this.parceler.wrap(token);
        sessionStorage.setItem(this.key, raw);
    }

    clear(): void {
        sessionStorage.removeItem(this.key);
    }
}
