import { Equipment, EquipmentOrder } from '../../../../../../shared/interfaces/onboarding';
import { Component, Input, OnInit } from '@angular/core';

interface EquipmentOrderSummary extends EquipmentOrder {
    price: number;
    currency: string;
    name: string;
}

@Component({
    selector: 'get-started-equipment-summary-item',
    templateUrl: './get-started-equipment-summary-item.component.html',
    styleUrls: ['./get-started-equipment-summary-item.component.scss']
})
export class GetStartedEquipmentSummaryItemComponent implements OnInit {
    @Input() equipment: EquipmentOrder;
    @Input() equipmentList: Equipment[];
    equipmentToDisplay: EquipmentOrderSummary;

    ngOnInit() {
        this.expandEquipmentInformation();
    }

    expandEquipmentInformation() {
        const equipmentInList = this.equipmentList.find(e => e.id === this.equipment.equipmentId);
        this.equipmentToDisplay = {
            ...this.equipment,
            price: equipmentInList.price,
            name: equipmentInList.name,
            currency: equipmentInList.currency
        };
    }
}
