import { SharedModule } from './../../../../../../shared/index';
import { NgModule } from '@angular/core';
import { GetStartedContactUsConfirmationComponent } from './get-started-contact-us-confirmation.component';
import { NbLayoutModule } from '@nebular/theme';

@NgModule({
    imports: [SharedModule, NbLayoutModule],
    declarations: [GetStartedContactUsConfirmationComponent]
})
export class GetStartedContactUsConfirmationModule {}
