import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectCurrentTasks } from '../../../pages/pages-state/task/task.selectors';
import { TaskState } from 'app/pages/pages-state/task/task.reducer';
import { TaskMapperService } from '../tasks/task/task-mapper.service';
import { Task, ProgressStatus } from '../tasks/task/task';

@Component({
    selector: 'account-status-bar',
    templateUrl: './account-status-bar.component.html',
    styleUrls: ['./account-status-bar.component.scss']
})
export class AccountStatusBarComponent implements OnInit, OnDestroy {
    @Input() displayHideButton: boolean;
    tasks: Task[] = [];
    tasksCount: number;
    completedTasksCount: number;
    completedTasksPercentage: number;
    private tasksSubscription: Subscription;
    isLoading = true;

    constructor(private tasksStore: Store<TaskState>, private taskMapper: TaskMapperService) {}

    ngOnInit() {
        this.subscribeToTasks();
    }

    ngOnDestroy() {
        this.tasksSubscription?.unsubscribe();
    }

    subscribeToTasks() {
        this.tasksSubscription = this.tasksStore.select(selectCurrentTasks).subscribe(taskDtos => {
            if (!taskDtos || taskDtos.length === 0) {
                return;
            }

            this.isLoading = false;
            this.tasks = taskDtos
                .filter(taskDto => taskDto.includeInAccountStatus)
                .map(taskDto => this.taskMapper.mapToTask(taskDto));
            this.tasksCount = this.tasks.length;

            this.completedTasksCount = this.tasks.filter(task => task.status === ProgressStatus.COMPLETED).length;
            this.completedTasksPercentage = this.completedTasksCount / this.tasksCount;
        });
    }

    public getCompletedTasksPercentage(): number {
        return this.completedTasksPercentage;
    }
}
