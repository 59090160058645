<nb-card
    class="task-bar-wrapper flex-row col-12 d-none d-md-flex"
    [nbSpinner]="isLoading"
    nbSpinnerSize="large"
    nbSpinnerStatus="primary"
>
    <div class="account-status d-flex flex-row align-items-center col">
        <circular-progress-indicator [percentage]="completedTasksPercentage"></circular-progress-indicator>

        <div class="account-status-copy px-3">
            <h2>Account status</h2>
            <small class="card-description">
                {{ tasksCount - completedTasksCount }}
                action<span *ngIf="tasksCount - completedTasksCount !== 1">s</span>
                outstanding
            </small>
        </div>
    </div>

    <div *ngFor="let task of tasks" class="d-flex flex-fill col px-0">
        <div *ngIf="task.status === 'Completed'" class="task text-center complete d-flex col align-items-center">
            <div class="mx-auto">
                <nb-icon status="primary" icon="checkmark-outline"></nb-icon>
                <h3>{{ task.title }}</h3>
                <small
                    ><em>{{ task.status }}</em></small
                >
            </div>
        </div>
        <div
            [routerLink]="task.navigationLink"
            *ngIf="task.status === 'View task'"
            class="task text-center incomplete col d-flex align-items-center"
        >
            <div class="mx-auto">
                <nb-icon status="danger" icon="info"></nb-icon>
                <h3>{{ task.title }}</h3>
                <small
                    ><a [routerLink]="task.navigationLink">{{ task.status }}</a></small
                >
            </div>
        </div>

        <div
            [routerLink]="task.navigationLink"
            *ngIf="task.status === 'Pending' || task.status === 'Ongoing'"
            class="task text-center incomplete col d-flex align-items-center"
        >
            <div class="mx-auto">
                <nb-icon status="warning" icon="info"></nb-icon>
                <h3>{{ task.title }}</h3>
                <small
                    ><a [routerLink]="task.navigationLink">{{ task.status }}</a></small
                >
            </div>
        </div>
    </div>
    <ng-container *ngIf="displayHideButton">
        <hide-account-status-bar-button></hide-account-status-bar-button>
    </ng-container>
</nb-card>

<account-status-accordion
    class="d-flex d-md-none w-100 position-relative"
    [ngClass]="{ 'mt-2 mx-1': displayHideButton }"
    [percentage]="completedTasksPercentage"
    [tasksLeft]="tasksCount - completedTasksCount"
    [nbSpinner]="isLoading"
    nbSpinnerSize="large"
    nbSpinnerStatus="primary"
    [displayHideButton]="displayHideButton"
>
    <div *ngFor="let task of tasks" class="d-flex flex-fill col px-0 task-wrapper">
        <div *ngIf="task.status === 'Completed'" class="task text-center complete col">
            <nb-icon status="primary" icon="checkmark-outline"></nb-icon>
            <h3>{{ task.title }}</h3>
            <small
                ><em>{{ task.status }}</em></small
            >
        </div>

        <div
            [routerLink]="task.navigationLink"
            *ngIf="task.status === 'Pending' || task.status === 'Ongoing'"
            class="task text-center incomplete col"
        >
            <nb-icon status="danger" icon="info"></nb-icon>
            <h3>{{ task.title }}</h3>
            <small
                ><a [routerLink]="task.navigationLink">{{ task.status }}</a></small
            >
        </div>

        <div
            [routerLink]="task.navigationLink"
            *ngIf="task.status === 'View task'"
            class="task text-center incomplete col"
        >
            <nb-icon status="warning" icon="info"></nb-icon>
            <h3>{{ task.title }}</h3>
            <small
                ><a [routerLink]="task.navigationLink">{{ task.status }}</a></small
            >
        </div>
    </div>
</account-status-accordion>
