import { SharedModule } from 'app/shared';
import { NgModule } from '@angular/core';
import { HideAccountStatusBarButtonComponent } from './hide-account-status-bar-button.component';
import { HideAccountStatusBarDialogModule } from '../hide-account-status-bar-dialog';

@NgModule({
    imports: [SharedModule, HideAccountStatusBarDialogModule],
    declarations: [HideAccountStatusBarButtonComponent],
    exports: [HideAccountStatusBarButtonComponent]
})
export class HideAccountStatusBarButtonModule {}
