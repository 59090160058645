import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';
import { OnboardingCountry, OnboardingTypeEnum, ReferralSource } from 'app/shared/interfaces/onboarding';
import { Observable } from 'rxjs';
import { OnBoardingService } from '../../../../../shared/services/on-boarding.service';
import { mapLocaleToCountryCode } from 'app/shared/helpers/localisation-helpers';
import { LocaleId } from 'app/shared/interfaces/locale';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-get-started-ordr-referral',
    templateUrl: './get-started-ordr-referral.component.html',
    styleUrls: ['./get-started-ordr-referral.component.scss']
})
export class GetStartedOrdrReferralComponent implements OnInit {
    public referralSources: Observable<ReferralSource[]> = this.onboardingService.getReferralSources();
    public supportedCountries: OnboardingCountry[] = [];
    public selectedReferralSource: ReferralSource;
    public locale;
    public countryCode: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private onboardingService: OnBoardingService,
        private transloco: TranslocoService
    ) {}

    ngOnInit() {
        this.setSupportedCountries();
    }

    goToNextStep() {
        const locale = this.transloco.getActiveLang() as LocaleId;
        const countryCode = mapLocaleToCountryCode(locale);
        const matchingCountry = this.supportedCountries.find(c => c.code === countryCode);

        if (matchingCountry.onboardingType === OnboardingTypeEnum.MANUAL) {
            this.router.navigate(['enter-business-details'], {
                relativeTo: this.route.parent,
                queryParams: {
                    country: countryCode,
                    pricingPlanName: this.route.snapshot.queryParams?.pricingPlanName,
                    referralSourceId: this.selectedReferralSource.id
                }
            });
        }

        if (matchingCountry.onboardingType === OnboardingTypeEnum.AUTOMATIC) {
            this.router.navigate(['enter-org-number'], {
                relativeTo: this.route.parent,
                queryParams: {
                    country: countryCode,
                    pricingPlanName: this.route.snapshot.queryParams?.pricingPlanName,
                    referralSourceId: this.selectedReferralSource.id
                }
            });
        }
    }

    private setSupportedCountries() {
        this.onboardingService.getSupportedCountries().subscribe(res => (this.supportedCountries = res));
    }
}
