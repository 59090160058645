import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnBoardingService } from 'app/shared/services/on-boarding.service';

@Component({
    selector: 'app-get-started-account-created',
    templateUrl: './get-started-account-created.component.html',
    styleUrls: ['./get-started-account-created.component.scss']
})
export class GetStartedAccountCreatedComponent implements OnInit {
    public email: string = this.route.snapshot.queryParams?.email;

    constructor(private route: ActivatedRoute, private router: Router, private onboardingService: OnBoardingService) {}

    ngOnInit() {}
}
