import { SharedModule } from './../../../../../../shared/index';
import { NgModule } from '@angular/core';
import { SelectEquipmentItemComponent } from './select-equipment-item.component';
import { StoreModule } from '@ngrx/store';
import * as fromGettingStarted from './../../../state/getting-started.reducer';

@NgModule({
    imports: [SharedModule, StoreModule.forFeature('gettingStarted', fromGettingStarted.reducer)],
    declarations: [SelectEquipmentItemComponent],
    exports: [SelectEquipmentItemComponent]
})
export class SelectEquipmentItemModule {}
