<nb-accordion class="w-100">
    <nb-accordion-item (collapsedChange)="onCollapsedChange($event)">
        <nb-accordion-item-header>
            <div class="accordion-headline w-100">
                <div class="account-status d-flex flex-row align-items-center">
                    <circular-progress-indicator [percentage]="percentage"></circular-progress-indicator>
                    <div class="text-center mx-auto header-copy">
                        <h2>Account status</h2>
                        <small class="card-description">
                            {{ tasksLeft }}
                            action<span *ngIf="tasksLeft !== 1">s</span>
                            outstanding
                        </small>
                    </div>
                </div>
            </div>
        </nb-accordion-item-header>

        <nb-accordion-item-body>
            <ng-content></ng-content>
        </nb-accordion-item-body>
    </nb-accordion-item>
</nb-accordion>

<ng-container *ngIf="displayHideButton">
    <hide-account-status-bar-button></hide-account-status-bar-button>
</ng-container>
