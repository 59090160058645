import { ReferralSource } from './../interfaces/onboarding';
import { GettingStarted } from './../../pages/public/getting-started/state/index';
import { WS_BASE } from './../constants/urls';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SimpleUser } from '../interfaces/auth/simple-user';
import { BaseService } from './base-service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs';
import { LocationGroup } from '../interfaces/location-group';
import { CreateLocationGroupRequest, CreateLocationRequest } from './requests/onboarding/location-management';
import {
    Equipment,
    LocationType,
    OnboardingCountry,
    OnboardingOrganizationStatus,
    PricingPlan
} from '../interfaces/onboarding';
import { OnboardingUrlResponse } from '../interfaces/onboarding-url-response';
import { CreateUserPublicDto } from '../interfaces/create-user-public-dto';

const ON_BOARDING_BASE = WS_BASE + '/v1/onboarding';
@Injectable({
    providedIn: 'root'
})
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export class OnBoardingService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    public createLocationGroup(request: CreateLocationGroupRequest) {
        const url = `${ON_BOARDING_BASE}/location-groups`;
        return this.http.post<LocationGroup>(url, request).pipe(catchError(this.handleError));
    }

    public createLocation(locationGroupId: number, request: CreateLocationRequest) {
        const url = `${ON_BOARDING_BASE}/location-groups/${locationGroupId}/locations`;
        return this.http.post<LocationGroup>(url, request).pipe(catchError(this.handleError));
    }

    public createUserWithOnboarding(countryCode: string, orgNr: string, email: string) {
        const url = `${ON_BOARDING_BASE}/countries/${countryCode}/organisations/${orgNr}/users`;
        return this.http.post<SimpleUser>(url, { email });
    }

    public getSupportedCountries(): Observable<OnboardingCountry[]> {
        return this.http
            .get<OnboardingCountry[]>(`${WS_BASE}/public/v1/onboarding/countries`)
            .pipe(catchError(this.handleError));
    }

    public getReferralSources(): Observable<ReferralSource[]> {
        return this.http
            .get<ReferralSource[]>(`${WS_BASE}/public/v1/onboarding/referral-sources`)
            .pipe(catchError(this.handleError));
    }

    public getIsOrganizationStatus(countryCode: string, orgNumber: number) {
        return this.http.get<OnboardingOrganizationStatus>(
            `${WS_BASE}/public/v1/onboarding/countries/${countryCode}/organisations/${orgNumber}`
        );
    }

    public createUser(
        countryCode: string,
        orgNumber: string,
        pricingPlanName: string,
        createUserPublicDto: CreateUserPublicDto
    ) {
        let url = `${WS_BASE}/public/v1/onboarding/countries/${countryCode}/organisations/${orgNumber}/users`;

        if (pricingPlanName) {
            url += `?pricingPlanName=${pricingPlanName}`;
        }

        return this.http.post(url, createUserPublicDto);
    }

    public signup(countryCode: string, orgNumber: string, pricingPlanName: string, postModel) {
        const url = `${WS_BASE}/public/v1/onboarding/countries/${countryCode}/organisations/${orgNumber}/signup-requests?pricingPlanName=${pricingPlanName}`;
        return this.http.post(url, postModel);
    }

    public completeSignup(countryCode, orgNumber, postModel: GettingStarted) {
        const url = `${WS_BASE}/v1/onboarding/countries/${countryCode}/organisations/${orgNumber}/agreements`;
        return this.http.post<any>(url, postModel);
    }

    public contactUs(postModel) {
        const url = `${WS_BASE}/public/v1/onboarding/contact-forms`;
        return this.http.post<any>(url, postModel);
    }

    public getLocationTypes() {
        const url = `${WS_BASE}/public/v1/onboarding/location-types`;
        return this.http.get<LocationType[]>(url);
    }

    public getPricingPlans(countryCode: string) {
        const url = `${WS_BASE}/public/v1/onboarding/countries/${countryCode}/pricing-plans`;
        return this.http.get<PricingPlan[]>(url);
    }

    public getEquipment(countryCode: string) {
        const url = `${WS_BASE}/public/v1/onboarding/countries/${countryCode}/equipment`;
        return this.http.get<Equipment[]>(url);
    }

    public getOnboardingUrl(locationId: number) {
        const url = `${WS_BASE}/v1/onboarding/locations/${locationId}/onboarding-url`;
        return this.http.get<OnboardingUrlResponse>(url);
    }

    public createAccountHolder(locationId: number) {
        const url = `${WS_BASE}/v1/onboarding/locations/${locationId}/account-holders`;
        return this.http.post(url, {}).pipe(catchError(this.handleError));
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, Message is: ${err.message}`;
        }
        // eslint-disable-next-line no-console
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}
