<nb-layout center class="colored-layout-white fullWidth">
    <nb-layout-column>
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-8 col-lg-6 m-auto">
                    <ng-container *ngIf="user">
                        <get-started-contact-us-form
                            [user]="user"
                            [submitErrorResponse]="submitErrorResponse"
                            (formSubmit)="handleFormSubmit($event)"
                        >
                        </get-started-contact-us-form>
                    </ng-container>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
