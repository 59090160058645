import { LocaleId } from '../interfaces/locale';

export const mapCountryCodeToLocale = (countryCode: string): LocaleId => {
    switch (countryCode) {
        case 'NO':
            return LocaleId.NB_NO;
        case 'DK':
            return LocaleId.DA_DK;
        case 'UK':
            return LocaleId.EN_GB;
        case 'SE':
            return LocaleId.SV_SE;
        case 'FI':
            return LocaleId.FI_FI;
        default:
            return LocaleId.EN_GB;
    }
};

export const mapLocaleToCountryCode = (locale: LocaleId): string => {
    switch (locale) {
        case LocaleId.NB_NO:
            return 'NO';
        case LocaleId.DA_DK:
            return 'DK';
        case LocaleId.EN_GB:
            return 'UK';
        case LocaleId.SV_SE:
            return 'SE';
        case LocaleId.FI_FI:
            return 'FI';
        default:
            throw new Error(`Encountered an unhandled locale when mapping to country code. Locale was: ${locale}`);
    }
};
