<nb-layout center class="colored-layout-white fullWidth">
    <nb-layout-column>
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-8 col-lg-6 m-auto">
                    <div class="mb-50">
                        <nb-progress-bar [value]="100" status="primary" [displayValue]="true"></nb-progress-bar>
                    </div>
                    <div class="d-flex justify-content-center">
                        <favrit-logo></favrit-logo>
                    </div>
                    <div class="text-center enter section-spacer">
                        <h1>Great! You're now ready to start using Favrit</h1>
                        <p class="intro-text text-center m-auto">
                            We recommend you start by creating your menu. When you're ready you can tell us to go live
                            and we will show your business on
                            <a href="https://favrit.com" target="_blank" rel="noopener">Favrit</a>. Contact us if you
                            have any questions!
                        </p>
                    </div>
                    <div class="text-center">
                        <button
                            nbButton
                            status="primary"
                            [routerLink]="['/location/' + locationId + '/menus/']"
                            (click)="onGoToMenuClick()"
                        >
                            Click here to setup your menu
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
