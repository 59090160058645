import {
    AgreementSignee,
    CompleteBusinessDetails,
    Equipment,
    EquipmentOrder
} from './../../../../shared/interfaces/onboarding';
import { createSelector } from '@ngrx/store';

export interface GettingStarted {
    pricingPlanName: string;
    selectEquipmentSkipped: boolean;
    selectEquipmentFullyDigital: boolean;
    equipmentOrders: EquipmentOrder[];
    businessDetails: CompleteBusinessDetails;
    agreementSignee: AgreementSignee;
}

export interface GettingStartedState {
    gettingStarted: GettingStarted;
}

export const selectGettingStartedState = (state: GettingStartedState) => state.gettingStarted;

export const selectPlan = createSelector(selectGettingStartedState, (state: GettingStarted) => state.pricingPlanName);
export const selectEquipmentSkipped = createSelector(
    selectGettingStartedState,
    (state: GettingStarted) => state.selectEquipmentSkipped
);
export const selectBusinessDetails = createSelector(
    selectGettingStartedState,
    (state: GettingStarted) => state.businessDetails
);
export const selectEquipmentList = createSelector(
    selectGettingStartedState,
    (state: GettingStarted) => state.equipmentOrders
);

export const selectEquipmentById = createSelector(selectGettingStartedState, (state: GettingStarted, props) =>
    state.equipmentOrders.find(x => x.equipmentId === props.id)
);

export const selectIsEquipmentSkippedOrSet = createSelector(
    selectEquipmentSkipped,
    selectEquipmentList,
    (selectEquipmentSkipped: boolean, selectEquipmentList: EquipmentOrder[]) => {
        if (selectEquipmentSkipped || selectEquipmentList.length) {
            return true;
        } else {
            return false;
        }
    }
);
