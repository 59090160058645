import { SharedModule } from './../../../../../../shared/index';
import { NgModule } from '@angular/core';
import { PricingPlanCardComponent } from './pricing-plan-card.component';

@NgModule({
    imports: [SharedModule],
    declarations: [PricingPlanCardComponent],
    exports: [PricingPlanCardComponent]
})
export class PricingPlanCardModule {}
