import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccountingControllerService } from './api/accountingController.service';
import { AddOrderToDigitalBillControllerService } from './api/addOrderToDigitalBillController.service';
import { AddressControllerService } from './api/addressController.service';
import { BookingControllerService } from './api/bookingController.service';
import { BookingDiscardControllerService } from './api/bookingDiscardController.service';
import { BookingVerificationControllerService } from './api/bookingVerificationController.service';
import { BulkUpdateControllerService } from './api/bulkUpdateController.service';
import { CloudinaryControllerService } from './api/cloudinaryController.service';
import { CountryControllerService } from './api/countryController.service';
import { CreateDigitalBillControllerService } from './api/createDigitalBillController.service';
import { CustomerAdminControllerService } from './api/customerAdminController.service';
import { CustomerRegistryControllerService } from './api/customerRegistryController.service';
import { CustomerSearchControllerService } from './api/customerSearchController.service';
import { CustomizationControllerService } from './api/customizationController.service';
import { DeliveryMethodControllerService } from './api/deliveryMethodController.service';
import { DigitalBillInvitationControllerService } from './api/digitalBillInvitationController.service';
import { DiscountCodeControllerService } from './api/discountCodeController.service';
import { DiscountControllerService } from './api/discountController.service';
import { GetDigitalBillControllerService } from './api/getDigitalBillController.service';
import { GetDigitalBillVerificationControllerService } from './api/getDigitalBillVerificationController.service';
import { GuestOrderControllerService } from './api/guestOrderController.service';
import { GuestStatisticsControllerService } from './api/guestStatisticsController.service';
import { InhouseLocationControllerService } from './api/inhouseLocationController.service';
import { InhousePlacementControllerService } from './api/inhousePlacementController.service';
import { InvoiceReportControllerService } from './api/invoiceReportController.service';
import { LinkDigitalBillControllerService } from './api/linkDigitalBillController.service';
import { LocalityControllerService } from './api/localityController.service';
import { LocationGroupControllerService } from './api/locationGroupController.service';
import { LocationGroupPublicControllerService } from './api/locationGroupPublicController.service';
import { LocationManagementControllerService } from './api/locationManagementController.service';
import { LocationOnboardingControllerService } from './api/locationOnboardingController.service';
import { LocationPaymentMethodControllerService } from './api/locationPaymentMethodController.service';
import { LocationPublicControllerService } from './api/locationPublicController.service';
import { LocationSettingControllerService } from './api/locationSettingController.service';
import { LocationV1ControllerService } from './api/locationV1Controller.service';
import { LocationV2ControllerService } from './api/locationV2Controller.service';
import { OnboardingControllerService } from './api/onboardingController.service';
import { OrderHistoryControllerService } from './api/orderHistoryController.service';
import { OrderLogControllerService } from './api/orderLogController.service';
import { PasswordResetControllerService } from './api/passwordResetController.service';
import { PayDigitalBillControllerService } from './api/payDigitalBillController.service';
import { PaymentMethodControllerService } from './api/paymentMethodController.service';
import { PermissionControllerService } from './api/permissionController.service';
import { PlacementQueueControllerService } from './api/placementQueueController.service';
import { PrintReceiptControllerService } from './api/printReceiptController.service';
import { PrinterControllerService } from './api/printerController.service';
import { PrinterQueueControllerService } from './api/printerQueueController.service';
import { QrCodeControllerService } from './api/qrCodeController.service';
import { RatingControllerService } from './api/ratingController.service';
import { RatingPublicControllerService } from './api/ratingPublicController.service';
import { ReceiptControllerService } from './api/receiptController.service';
import { RefreshTokenControllerService } from './api/refreshTokenController.service';
import { RegisterTipControllerService } from './api/registerTipController.service';
import { ReportsControllerService } from './api/reportsController.service';
import { RoleControllerService } from './api/roleController.service';
import { SelfServiceControllerService } from './api/selfServiceController.service';
import { SendPaymentRemindersControllerService } from './api/sendPaymentRemindersController.service';
import { SettledDigitalBillControllerService } from './api/settledDigitalBillController.service';
import { SimpleLocalityControllerService } from './api/simpleLocalityController.service';
import { SmsNotificationControllerService } from './api/smsNotificationController.service';
import { StatisticsControllerService } from './api/statisticsController.service';
import { TaxGroupsControllerService } from './api/taxGroupsController.service';
import { TeamDataBookingControllerService } from './api/teamDataBookingController.service';
import { TeamDataBookingOrderControllerService } from './api/teamDataBookingOrderController.service';
import { ToggleDigitalBillControllerService } from './api/toggleDigitalBillController.service';
import { ToggleEposControllerService } from './api/toggleEposController.service';
import { ToggleLocationFoodCourtControllerService } from './api/toggleLocationFoodCourtController.service';
import { ToggleNewPaymentFlowControllerService } from './api/toggleNewPaymentFlowController.service';
import { TogglePaymentMethodsControllerService } from './api/togglePaymentMethodsController.service';
import { TogglePrintingControllerService } from './api/togglePrintingController.service';
import { TogglePrintingVersionControllerService } from './api/togglePrintingVersionController.service';
import { ToggleServiceChargeControllerService } from './api/toggleServiceChargeController.service';
import { ToggleSplitBillControllerService } from './api/toggleSplitBillController.service';
import { ToggleVerifyAllUsersControllerService } from './api/toggleVerifyAllUsersController.service';
import { UnsettledDigitalBillControllerService } from './api/unsettledDigitalBillController.service';
import { UpdateBookingDetailsControllerService } from './api/updateBookingDetailsController.service';
import { UpdateOpeningHoursControllerService } from './api/updateOpeningHoursController.service';
import { UserControllerService } from './api/userController.service';
import { UserLocationRoleControllerService } from './api/userLocationRoleController.service';
import { UserVerificationControllerService } from './api/userVerificationController.service';
import { VersionControllerService } from './api/versionController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
