import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared';
import { NbLayoutModule, NbProgressBarModule } from '@nebular/theme';
import { NgModule } from '@angular/core';
import { GetStartedCompleteSignupComponent } from './get-started-complete-signup.component';
import { GetStartedEquipmentSummaryItemModule } from './get-started-equipment-summary-item';

@NgModule({
    imports: [
        SharedModule,
        NbLayoutModule,
        ReactiveFormsModule,
        NbProgressBarModule,
        GetStartedEquipmentSummaryItemModule
    ],
    declarations: [GetStartedCompleteSignupComponent]
})
export class GetStartedCompleteSignupModule {}
