import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouterState } from 'app/store/router/custom-route-serializer';
import { selectError } from 'app/store/shared';
import * as fromLocation from './location.reducer';

export const { selectAll, selectEntities } = fromLocation.adapter.getSelectors();

export const selectLocationState = createFeatureSelector<fromLocation.LocationState>(fromLocation.locationFeatureKey);

export const selectLocations = createSelector(selectLocationState, selectAll);

export const selectLoadLocationsState = createSelector(selectLocationState, state => state.loadLocationsState);

export const selectLocation = (id: string | number) =>
    createSelector(createSelector(selectLocationState, selectEntities), entities => entities[id]);

export const selectCurrentLocation = createSelector(
    createSelector(selectLocationState, selectEntities),
    selectRouterState,
    (entities, router) => {
        return router?.state && entities[router.state.params.locationId];
    }
);

export const selectLoadLocationError = createSelector(selectLocationState, state =>
    selectError(state.loadLocationState)
);

export const selectCreatePlacementState = createSelector(selectLocationState, state => state.createPlacementState);

export const selectCreatePlacementError = createSelector(selectLocationState, state =>
    selectError(state.createPlacementState)
);
