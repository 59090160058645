<nb-layout center class="colored-layout-white">
    <nb-layout-column>
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-md-12 m-auto h-100">
                    <div class="d-flex align-items-center flex-column justify-content-center h-100">
                        <div class="section-spacer d-flex justify-content-center">
                            <favrit-logo></favrit-logo>
                        </div>
                        <div class="text-center section-spacer">
                            <h1>Enter your account information</h1>
                            <p>
                                The email you enter will become the admin user of your Favrit account. We suggest using
                                the email address you use at work.
                            </p>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="form-container-wrapper">
                                <form [formGroup]="form">
                                    <div class="form-group">
                                        <label class="label" for="firstName">First name</label>
                                        <input type="text" #firstFocus formControlName="firstName" nbInput fullWidth />
                                    </div>
                                    <div class="form-group">
                                        <label class="label" for="lastName">Last name</label>
                                        <input type="text" formControlName="lastName" nbInput fullWidth />
                                    </div>
                                    <div class="form-group">
                                        <label class="label" for="email">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            formControlName="email"
                                            placeholder="name@work-email.com"
                                            nbInput
                                            fullWidth
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label class="label" for="phoneNumber">Phone number</label>
                                        <input type="text" formControlName="phoneNumber" nbInput fullWidth />
                                    </div>

                                    <div class="form-group">
                                        <button
                                            nbButton
                                            status="primary"
                                            [nbSpinner]="isComponentLoading"
                                            nbSpinnerStatus="primary"
                                            [disabled]="form.invalid || isComponentLoading"
                                            (click)="goToNextStep()"
                                            fullWidth
                                        >
                                            CONTINUE
                                        </button>
                                    </div>
                                    <nb-alert *ngIf="errorMessage" status="danger">{{ errorMessage }}</nb-alert>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
