import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import jwtDecode from 'jwt-decode';

const hasTokenExpired = (token: string | null) => {
    if (!token?.length) {
        return true;
    }

    try {
        const decodedToken = jwtDecode<{ exp: number }>(token);
        const { exp: expirationTime } = decodedToken;
        const now = Date.now() / 1000;
        const tokenHasExpired = expirationTime < now;
        return tokenHasExpired;
    } catch (e) {
        return true;
    }
};

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: NbAuthService, private router: Router) {}

    canActivate() {
        if (!environment.enable_auth) return Observable.of(true);

        this.authService.getToken().subscribe(token => {
            const isOldToken = token.getName() === 'nb:auth:simple:token';
            if (!isOldToken) {
                return;
            }

            if (hasTokenExpired(token.getValue())) {
                localStorage.removeItem('auth_app_token');
            }
        });

        return this.authService.isAuthenticatedOrRefresh().pipe(
            tap(authenticated => {
                if (!authenticated) {
                    sessionStorage.setItem('redirect', window.location.hash);
                    this.router.navigate(['auth/login']);
                }
            })
        );
    }
}
