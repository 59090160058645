import { Component } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { LocationState } from 'app/pages/pages-state/location/location.reducer';
import { selectCurrentLocation } from 'app/pages/pages-state/location/location.selectors';
import { Patch } from 'app/shared/interfaces/patch';
import { OrdrService } from 'app/shared/services/ordr.service';
import { Subscription } from 'rxjs';
import { Location, Settings } from 'app/shared/interfaces/location';
import { updateLocationSuccess } from 'app/pages/pages-state/location/location.actions';
import cloneDeep from 'lodash/cloneDeep';

@Component({
    selector: 'hide-account-status-bar-dialog',
    templateUrl: './hide-account-status-bar-dialog.component.html'
})
export class HideAccountStatusBarDialogComponent {
    locationId: number;
    currentLocationSub: Subscription;
    currentLocation: Location;

    constructor(
        private locationStore: Store<LocationState>,
        public ordrService: OrdrService,
        private toaster: NbToastrService,
        public dialogRef: NbDialogRef<HideAccountStatusBarDialogComponent>
    ) {}

    ngOnInit(): void {
        this.subscribeToCurrentLocation();
    }

    public subscribeToCurrentLocation() {
        this.locationStore.select(selectCurrentLocation).subscribe(currentLocation => {
            if (currentLocation) {
                this.currentLocation = cloneDeep(currentLocation);
                this.locationId = this.currentLocation.id;
            }
        });
    }

    onHideBar(): void {
        this.hideAccountStatusBar();
        this.dialogRef.close();
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    async hideAccountStatusBar() {
        let settings: Settings;

        if (this.currentLocation.settings) {
            settings = { ...this.currentLocation.settings, hideAccountStatusBar: true };
        } else {
            settings = { hideAccountStatusBar: true };
        }

        const settingsJson = JSON.stringify(settings);
        const patchArray: Patch[] = [{ op: 'replace', path: '/settings', value: settingsJson }];
        const duration = 6000;

        try {
            await this.ordrService.patchLocation(patchArray, this.locationId).toPromise();
            this.toaster.success(
                'Account status bar can still be found in Company Profile within Settings',
                'Account status bar is hidden',
                { duration }
            );

            const updatedLocation = cloneDeep(this.currentLocation);
            updatedLocation.settings = settings;
            this.locationStore.dispatch(updateLocationSuccess({ location: updatedLocation }));
        } catch (error) {
            this.toaster.danger('Could not hide account status bar', 'An error occurred');
        }
    }
}
