<nb-layout center class="colored-layout-white">
    <nb-layout-column>
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-md-12 m-auto h-100">
                    <div class="d-flex align-items-center flex-column justify-content-center h-100">
                        <div class="section-spacer d-flex justify-content-center">
                            <favrit-logo></favrit-logo>
                        </div>
                        <div class="text-center section-spacer header-max-width">
                            <h1>Where did you hear about us?</h1>
                            <p>
                                We would love to learn how you heard about us so that we can understand our users
                                better.
                            </p>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="form-container-wrapper">
                                <div class="form-group">
                                    <label class="label" for="source">Select answer</label>
                                    <ng-container *ngIf="referralSources | async as sources">
                                        <nb-select
                                            autofocus
                                            id="source"
                                            fullWidth
                                            placeholder="Select answer"
                                            [(ngModel)]="selectedReferralSource"
                                        >
                                            <nb-option [value]="source" *ngFor="let source of sources"
                                                >{{ source.label }}
                                            </nb-option>
                                        </nb-select>
                                    </ng-container>
                                </div>
                                <div class="form-group section-spacer">
                                    <button nbButton status="primary" (click)="goToNextStep()" fullWidth>
                                        CONTINUE
                                    </button>
                                </div>

                                <div class="text-center">
                                    <div>
                                        Already using Favrit?
                                    </div>
                                    <div>
                                        <a [routerLink]="['/']">Sign in</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>
