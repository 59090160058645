import { SharedModule } from '../../../../../../shared/index';
import { NgModule } from '@angular/core';
import { GetStartedEquipmentSummaryItemComponent } from './get-started-equipment-summary-item.component';

@NgModule({
    imports: [SharedModule],
    declarations: [GetStartedEquipmentSummaryItemComponent],
    exports: [GetStartedEquipmentSummaryItemComponent]
})
export class GetStartedEquipmentSummaryItemModule {}
