<nb-card>
    <nb-card-body
        [ngClass]="{ cursor: !stateAmountCounter && !disabled }"
        (click)="!stateAmountCounter && !disabled && addToCart()"
    >
        <div *ngIf="disabled" class="disabled"></div>
        <div>
            <div class="item-info d-flex">
                <ng-container *ngIf="stateAmountCounter">
                    <div class="d-flex justify-content-center mr-10">
                        <span class="item-price text-color-primary">{{ stateAmountCounter }}</span
                        ><span class="text-color-primary pt-1 pl-1">x</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="!stateAmountCounter">
                    <nb-icon class="mr-10 icon-plus" icon="plus-outline"></nb-icon>
                </ng-container>

                <div>
                    <h5>{{ equipment.name }}</h5>
                    <p class="item-description">{{ equipment.description }}</p>
                    <div class="item-price text-color-primary">
                        {{ equipment.price }} <span class="item-currency"> {{ equipment.currency }}</span>
                    </div>
                </div>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer *ngIf="isItemCartOpen && stateAmountCounter">
        <div class="cart-content d-flex justify-content-between align-items-center">
            <div>Amount: {{ stateAmountCounter }}</div>
            <div>
                <ng-container *ngIf="!stateAmountCounter">
                    <button nbButton (click)="addToCart()" status="primary">Add</button>
                </ng-container>
                <ng-container *ngIf="stateAmountCounter">
                    <div class="btn-group">
                        <button (click)="removeFromCart()" class="btn btn-outline-secondary">
                            <nb-icon icon="minus-outline"></nb-icon>
                        </button>
                        <button (click)="addToCart()" class="btn btn-outline-secondary">
                            <nb-icon icon="plus-outline"></nb-icon>
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </nb-card-footer>
</nb-card>
