import { NbMenuItem } from '@nebular/theme';
import { ActionReducerMap, createSelector } from '@ngrx/store';
import { PlacementQueueItem } from '../../shared/interfaces/placement';
import { locationReducer, LocationState } from './location/location.reducer';
import { pagesReducer } from './pages.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from 'app/store/router/custom-route-serializer';
import { taskReducer, TaskState } from './task/task.reducer';

export interface PagesState {
    menuItems: NbMenuItem[];
    orderLogSearch: {
        searchString: string;
        result: PlacementQueueItem[];
    };
}

export interface AppState {
    pages: PagesState;
    location: LocationState;
    task: TaskState;
    router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<AppState> = {
    pages: pagesReducer,
    location: locationReducer,
    task: taskReducer,
    router: routerReducer
};

export const selectFeature = (state: AppState) => state.pages;

export const selectMenuItems = createSelector(selectFeature, (state: PagesState) => state.menuItems);

export const selectOrderLogSearch = createSelector(selectFeature, (state: PagesState) => state.orderLogSearch);
