<h1 id="title" class="title">Change password</h1>
<p class="sub-title">Please set a new password</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Oh snap!</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>Hooray!</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
</nb-alert>

<form (ngSubmit)="resetPass()" #resetPassForm="ngForm" aria-labelledby="title">
    <div class="form-control-group">
        <label class="label" for="input-password">New Password:</label>
        <input
            nbInput
            [(ngModel)]="user.newPassword"
            #newPassword="ngModel"
            type="password"
            id="input-password"
            name="password"
            class="first"
            placeholder="New Password"
            autofocus
            fullWidth
            [status]="newPassword.dirty ? (newPassword.invalid ? 'danger' : 'success') : ''"
            [required]="getConfigValue('forms.validation.newPassword.required')"
            [minlength]="getConfigValue('forms.validation.newPassword.minLength')"
            [maxlength]="getConfigValue('forms.validation.newPassword.maxLength')"
            [attr.aria-invalid]="newPassword.invalid && newPassword.touched ? true : null"
        />
        <ng-container *ngIf="newPassword.invalid && newPassword.touched">
            <p class="error-message" *ngIf="newPassword.errors?.required">
                Password is required!
            </p>
            <p class="error-message" *ngIf="newPassword.errors?.minlength || newPassword.errors?.maxlength">
                Password should contains from {{ getConfigValue('forms.validation.newPassword.minLength') }} to
                {{ getConfigValue('forms.validation.newPassword.maxLength') }}
                characters
            </p>
        </ng-container>
    </div>

    <div class="form-group">
        <label class="label" for="input-re-password">Confirm Password:</label>
        <input
            nbInput
            [(ngModel)]="user.confirmPassword"
            #rePass="ngModel"
            id="input-re-password"
            name="rePass"
            type="password"
            class="last"
            placeholder="Confirm Password"
            fullWidth
            [status]="
                rePass.touched ? (rePass.invalid || newPassword.value != rePass.value ? 'danger' : 'success') : ''
            "
            [required]="getConfigValue('forms.validation.newPassword.required')"
            [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null"
        />
        <ng-container *ngIf="rePass.touched">
            <p class="error-message" *ngIf="rePass.invalid && rePass.errors?.required">
                Password confirmation is required!
            </p>
            <p class="error-message" *ngIf="newPassword.value != rePass.value && !rePass.errors?.required">
                Password does not match the confirm password.
            </p>
        </ng-container>
    </div>

    <button
        nbButton
        status="success"
        fullWidth
        [disabled]="
            submitted ||
            !resetPassForm.valid ||
            (newPassword.value != rePass.value && !rePass.errors?.required) ||
            disableCTA
        "
        [class.btn-pulse]="submitted"
    >
        Change password
    </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
    <p><a class="text-link" routerLink="/">Back to login</a></p>
    <p><a class="text-link" href="https://web.favrit.com/#pris">Register</a></p>
</section>
