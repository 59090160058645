import { PricingPlanCardModule } from './pricing-plan-card/index';
import { NbLayoutModule, NbProgressBarModule } from '@nebular/theme';
import { SharedModule } from 'app/shared';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetStartedSelectPricingPlanComponent } from './get-started-select-pricing-plan.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [SharedModule, NbLayoutModule, PricingPlanCardModule, NbProgressBarModule, TranslocoModule],
    declarations: [GetStartedSelectPricingPlanComponent]
})
export class GetStartedSelectPricingPlanModule {}
