<ng-container *ngIf="locale === localeId.SV_SE">
    <div class="form-group">
        <h4 class="mb-20">Where do we send your money?</h4>
        <p>We need to know where to send the money your customers pay you through Favrit.</p>
    </div>
</ng-container>

<ng-container [formGroup]="form">
    <ng-container *ngIf="locale === localeId.SV_SE">
        <div class="form-group">
            <nb-radio-group name="sweden" [value]="swedenRadioValue" (valueChange)="handleSwedenRadioChange($event)">
                <nb-radio [value]="swedenRadioValues.GIRO">
                    Bankgiro/Plusgiro
                </nb-radio>
                <nb-radio [value]="swedenRadioValues.ACCOUNT_NUMBER_AND_BANK_NAME">
                    Account number and bank name
                </nb-radio>
            </nb-radio-group>
        </div>
    </ng-container>

    <ng-container [ngSwitch]="locale">
        <div *ngSwitchCase="localeId.NB_NO"></div>
        <div *ngSwitchCase="localeId.SV_SE">
            <ng-container *ngIf="swedenRadioValue === swedenRadioValues.GIRO">
                <div class="form-group">
                    <label class="label" for="giro">Bankgiro/Plusgiro number</label>
                    <input type="text" id="giro" formControlName="bankGiro" fullWidth nbInput />
                </div>
            </ng-container>
            <ng-container *ngIf="swedenRadioValue === swedenRadioValues.ACCOUNT_NUMBER_AND_BANK_NAME">
                <div class="form-group">
                    <label class="label" for="bankName">Bank name</label>
                    <input type="text" id="bankName" formControlName="bankName" fullWidth nbInput />
                </div>
                <div class="form-group">
                    <label class="label" for="accountNumber">Bank account (clearing number + account number)</label>
                    <input type="text" id="accountNumber" formControlName="accountNumber" fullWidth nbInput />
                </div>
            </ng-container>
        </div>
        <div *ngSwitchCase="localeId.DA_DK"></div>
        <div *ngSwitchCase="localeId.EN_GB"></div>
        <div *ngSwitchCase="localeId.FI_FI"></div>
    </ng-container>
</ng-container>
