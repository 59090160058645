import { MetaReducer } from '@ngrx/store';

import * as MetaActions from './meta.actions';

export const clearState = reducer => {
    return (state, action) => {
        if (action.type === MetaActions.logout.type) {
            state = undefined;
        }

        return reducer(state, action);
    };
};

export const metaReducers: MetaReducer<any>[] = [clearState];
