import { Component, Input } from '@angular/core';

@Component({
    selector: 'circular-progress-indicator',
    templateUrl: './circular-progress-indicator.component.html',
    styleUrls: ['./circular-progress-indicator.component.scss']
})
export class CircularProgressIndicatorComponent {
    @Input() percentage: number;

    // TODO: Make sure methods below only run on first page load and when task states update (or something)

    calculateDegrees(percentage: number): number {
        return Math.floor(percentage * 360);
    }

    getTransformStyle(): string {
        const degrees = this.calculateDegrees(this.percentage);
        return `rotate(${degrees}deg)`;
    }
}
