import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { Observable } from 'rxjs';
import { Country, Location } from '../interfaces/location';
import { WS_BASE } from '../constants/urls';
import { catchError, retry } from 'rxjs/operators';
import { SimpleLocality } from '../interfaces/simple-locality';
import { LocationGroup } from '../interfaces/location-group';
import { Patch } from '../interfaces/patch';
import { LocationResult } from '../interfaces/search';
import { SimpleLocationGroup } from '../interfaces/simple-location-group';

@Injectable({
    providedIn: 'root'
})
export class LocalityService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    createNewLocality(name: string, countryCode: string): Observable<SimpleLocality[]> {
        return this.http
            .post<SimpleLocality[]>(WS_BASE + '/v1/localities', { name: name, country: countryCode })
            .pipe(catchError(this.handleError));
    }

    getSimpleLocalities(countryCode?: string): Observable<SimpleLocality[]> {
        let url = `${WS_BASE}/v1/localities/simplified`;

        if (countryCode) {
            url += '?countryCode=' + countryCode;
        }

        return this.http.get<SimpleLocality[]>(url).pipe(retry(3), catchError(this.handleError));
    }

    getSimpleLocality(id: number, includeLocations?: boolean): Observable<SimpleLocality> {
        const showLocations = includeLocations !== undefined ? includeLocations : true;
        return this.http
            .get<SimpleLocality>(`${WS_BASE}/v1/localities/${id}/simplified?includeLocations=${showLocations}`)
            .pipe(retry(3), catchError(this.handleError));
    }

    getFullLocation(id: number): Observable<Location> {
        return this.http.get<Location>(WS_BASE + '/v1/locations/' + id).pipe(retry(3), catchError(this.handleError));
    }

    getLightLocation(id: number | string, includeMenu?: boolean): Observable<Location> {
        const menu = includeMenu !== undefined && includeMenu;
        return this.http
            .get<Location>(`${WS_BASE}/v1/locations/${id}/light?menu=${menu}`)
            .pipe(retry(3), catchError(this.handleError));
    }

    getSimpleLocationGroup(id: number): Observable<SimpleLocationGroup> {
        return this.http
            .get<SimpleLocationGroup>(`${WS_BASE}/v1/location-groups/${id}/simple`)
            .pipe(retry(3), catchError(this.handleError));
    }

    getFullLocationGroup(id: number): Observable<LocationGroup> {
        return this.http
            .get<LocationGroup>(WS_BASE + '/v1/location-groups/' + id)
            .pipe(retry(3), catchError(this.handleError));
    }

    createLocationGroup(locationGroup: LocationGroup) {
        return this.http
            .post<LocationGroup>(WS_BASE + '/v1/location-groups/', locationGroup)
            .pipe(catchError(this.handleError));
    }

    patchLocationGroup(patches: Patch[], locationGroupId: number) {
        const url = WS_BASE + '/v1/location-groups/' + locationGroupId;
        return this.http.patch<Location>(url, patches).pipe(catchError(this.handleError));
    }

    search(query: string): Observable<LocationResult[]> {
        const url = WS_BASE + '/v1/locations/info?search=' + encodeURIComponent(query);
        return this.http.get<LocationResult[]>(url);
    }

    getLimitedSimpleLocality(id: number): Observable<SimpleLocality> {
        return this.http
            .get<SimpleLocality>(`${WS_BASE}/v1/localities/${id}/limited`)
            .pipe(retry(3), catchError(this.handleError));
    }

    getSupportedCountries(): Observable<Country[]> {
        return this.http.get<Country[]>(`${WS_BASE}/v1/countries`).pipe(retry(3), catchError(this.handleError));
    }

    changeLocalityId(locationGroupId: number, localityId: number): Observable<void> {
        const url = WS_BASE + `/v1/location-groups/${locationGroupId}/locality`;
        return this.http.put<void>(url, localityId);
    }
}
