import { UserService } from 'app/shared/services/user.service';
import { AuthenticatedUser } from './../../../../../shared/interfaces/auth/simple-user';
import { GettingStartedState } from './../../state/index';
import { Router, ActivatedRoute } from '@angular/router';
import { OnBoardingService } from 'app/shared/services/on-boarding.service';
import { Component, OnInit } from '@angular/core';
import { FormInitiatorEnum, PricingPlan } from 'app/shared/interfaces/onboarding';
import { Observable } from 'rxjs/internal/Observable';
import { Store } from '@ngrx/store';
import { setPricingPlan } from '../../state/getting-started.actions';

@Component({
    selector: 'app-get-started-select-pricing-plan',
    templateUrl: './get-started-select-pricing-plan.component.html',
    styleUrls: ['./get-started-select-pricing-plan.component.scss']
})
export class GetStartedSelectPricingPlanComponent implements OnInit {
    public pricingPlans: Observable<PricingPlan[]>;
    public user: AuthenticatedUser;

    constructor(
        private userService: UserService,
        private store: Store<{ gettingStarted: GettingStartedState }>,
        private onboardingService: OnBoardingService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    async ngOnInit() {
        await this.setAuthenticatedUser();
        this.setPricingPlans();
    }

    setAuthenticatedUser() {
        return this.userService
            .getAuthenticatedUser()
            .toPromise()
            .then(res => (this.user = res));
    }

    setPricingPlans() {
        if (!this.user?.onboardingSession) return;

        this.pricingPlans = this.onboardingService.getPricingPlans(this.user.onboardingSession.countryCode);
    }

    handlePlanSelected(name: string) {
        this.store.dispatch(setPricingPlan({ planId: name }));

        if (name === 'CUSTOM') {
            this.router.navigate(['contact-us'], {
                relativeTo: this.route.parent,
                queryParams: { formInitiator: FormInitiatorEnum.ONBOARDING_FLOW_ENTERPRISE_PRICING_PLAN }
            });
            return;
        }

        this.router.navigate(['select-equipment'], { relativeTo: this.route.parent });
    }
}
