<div class="col-12 d-flex justify-content-between mb-20">
    <div class="label col-8 pl-0 mr-auto">
        <span class="equipment-qty">{{ equipmentToDisplay.amount }}</span
        ><span>{{ equipmentToDisplay.name }}</span>
    </div>
    <div class="price col-4 p-0 text-right">
        <span class="amount">{{ equipmentToDisplay.amount * equipmentToDisplay.price }}</span>
        <span>{{ equipmentToDisplay.currency }}</span>
    </div>
</div>
