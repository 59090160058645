import { Subtask } from '../../tasks-old/subtask-group/subtask';
import { ProgressItem } from '../progress-items/progress-item/progress-item';

export interface ActionButton {
    text: string;
    confirmText?: string;
    blockedText?: string;
    onClick: () => void;
}

export interface Task {
    title?: string;
    headline: string;
    description?: string;
    tooltip?: string;
    status: ProgressStatus;
    disabled?: boolean;
    disabledReason?: string;
    subtasks?: Subtask[];
    subtasksMustBeCompletedInOrder?: boolean;
    actionButton?: ActionButton;
    pendingImageSrc?: string;
    pendingIconName?: string;
    pendingHeadline?: string;
    pendingDescription?: string;
    completedImageSrc?: string;
    completedIconName?: string;
    completedHeadline?: string;
    completedDescription?: string;
    completedActionButton?: ActionButton;
    includeInAccountStatus?: boolean;
    category?: TaskCategory;
    type: TaskType;
    progressItems?: ProgressItem[]; // TODO: Make non-nullable when all TaskDtoOld is gone
    navigationLink?: string;
    locationId: number;
    prerequisiteTasks?: TaskType[];
}

export enum TaskType {
    ADD_LEGAL_BUSINESS_NAME = 'ADD_LEGAL_BUSINESS_NAME',
    ENABLE_PAYMENTS = 'ENABLE_PAYMENTS',
    ENABLE_PAYOUTS = 'ENABLE_PAYOUTS',
    UPLOAD_LISTING_IMAGE = 'UPLOAD_LISTING_IMAGE',
    UPLOAD_MENU_HEADER_IMAGE = 'UPLOAD_MENU_HEADER_IMAGE',
    SET_UP_MENU = 'SET_UP_MENU',
    LIST_LOCATION = 'LIST_LOCATION',
    PUBLISH_LOCATION = 'PUBLISH_LOCATION'
}

export interface TaskDto {
    locationId: number;
    countryCode: string;
    type: TaskType;
    status: TaskStatus;
    prerequisiteTasks: TaskType[];
    progressItems: ProgressItem[];
    includeInAccountStatus: boolean;
}

export enum TaskStatus {
    ONGOING = 'ONGOING',
    SUBTASKS_ONGOING = 'SUBTASKS_ONGOING', // TODO: Remove when TaskDtoOld is gone
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    NOT_STARTED = 'NOT_STARTED'
}

export enum ProgressStatus {
    ONGOING = 'Ongoing',
    PENDING = 'Pending',
    COMPLETED = 'Completed',
    NOT_STARTED = 'View task'
}

export enum TaskCategory {
    PAYMENT = 'PAYMENT',
    COMPANY = 'COMPANY'
}

export const mapStatus = (status: TaskStatus): ProgressStatus => {
    switch (status) {
        case TaskStatus.COMPLETED:
            return ProgressStatus.COMPLETED;
        case TaskStatus.NOT_STARTED:
            return ProgressStatus.NOT_STARTED;
        case TaskStatus.PENDING:
            return ProgressStatus.PENDING;
        case TaskStatus.ONGOING:
        case TaskStatus.SUBTASKS_ONGOING:
            return ProgressStatus.ONGOING;
    }
};

export const isPaymentTaskCategory = (task: TaskDto): boolean => {
    return (
        task.type === TaskType.ENABLE_PAYOUTS ||
        task.type === TaskType.ENABLE_PAYMENTS ||
        task.type === TaskType.ADD_LEGAL_BUSINESS_NAME
    );
};
