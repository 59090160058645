import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { OnBoardingService } from 'app/shared/services/on-boarding.service';
import { ContactDetailsDto } from 'app/shared/interfaces/contact-details-dto';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CreateUserPublicDto } from 'app/shared/interfaces/create-user-public-dto';

export type FormModel<T> = { [P in keyof T]: [T[P], any?] };

@Component({
    selector: 'app-get-started-enter-email',
    templateUrl: './get-started-enter-email.component.html',
    styleUrls: ['./get-started-enter-email.component.scss']
})
export class GetStartedEnterEmailComponent implements OnInit {
    @ViewChild('firstFocus') firstFocus: ElementRef;
    form = this.initForm();
    public errorMessage;
    public isComponentLoading = false;

    public postModel: CreateUserPublicDto = {
        contactDetails: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: ''
        },
        referralDetails: {
            referralSourceId: this.route.snapshot.queryParams?.referralSourceId
        }
    };

    constructor(
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private onboardingService: OnBoardingService
    ) {}

    ngOnInit() {
        if (!this.route.snapshot.queryParams?.country || !this.route.snapshot.queryParams?.orgNr)
            this.router.navigate(['where-did-you-hear-about-us'], { relativeTo: this.route.parent });

        this.setFocus();
    }

    setFocus() {
        setTimeout(() => {
            this.firstFocus && this.firstFocus.nativeElement.focus();
        });
    }

    private initForm() {
        const form: FormModel<ContactDetailsDto> = {
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required]],
            phoneNumber: ['', [Validators.required]]
        };

        return this.fb.group(form);
    }

    goToNextStep() {
        const country = this.route.snapshot.queryParams?.country;
        const org = this.route.snapshot.queryParams?.orgNr;
        const pricingPlanName = this.route.snapshot.queryParams?.pricingPlanName;

        this.isComponentLoading = true;

        this.postModel.contactDetails = this.form.getRawValue();

        return this.onboardingService.createUser(country, org, pricingPlanName, this.postModel).subscribe(
            res => {
                this.isComponentLoading = false;

                this.router.navigate(['account-created'], {
                    relativeTo: this.route.parent,
                    queryParams: {
                        country: country,
                        orgNr: org,
                        email: this.postModel.contactDetails.email
                    }
                });
            },
            err => {
                this.isComponentLoading = false;
                this.errorMessage = err.error?.message ?? 'Something went wrong.';
            }
        );
    }
}
