import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';

@Injectable({
    providedIn: 'root'
})
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    constructor(private toastrService: NbToastrService, private http: HttpClient) {}

    public initVersionCheck(url) {
        setInterval(() => {
            console.log('checking version');
            this.checkVersion(url);
        }, 600000);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(url) {
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime()).subscribe(
            (response: any) => {
                const hash = response.hash;
                const hashChanged = this.hasHashChanged(this.currentHash, hash);
                // If new version, do something
                if (hashChanged) {
                    console.log('Ny version er ute');
                    this.toastrService.show(
                        'A refresh is required. Please refresh your browser to get the latest version of the admin. Click on this message to dismiss it.',
                        'A new update is out',
                        {
                            duration: 0,
                            status: 'info',
                            icon: 'refresh-outline'
                        }
                    );
                } else {
                    console.log('Ingen ny version');
                }

                // store the new hash so we wouldn't trigger versionChange again
                // only necessary in case you did not force refresh
                this.currentHash = hash;
            },
            err => {
                console.error(err, 'Could not get version');
            }
        );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }

        return currentHash !== newHash;
    }
}
