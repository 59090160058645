import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(returnedError => {
                if (returnedError instanceof HttpErrorResponse) {
                    if (returnedError.status === 409) {
                        throw returnedError;
                    }
                    if (!this.handleAuthError(returnedError)) {
                        throwError(returnedError.error.message);
                    }
                }

                return of(returnedError);
            })
        );
    }

    private handleAuthError(error: HttpErrorResponse): boolean {
        if (error.status !== 401) {
            return false;
        }
        localStorage.removeItem('auth:jwt:token');
        this.router.navigateByUrl('/auth/logout');
        return true;
    }
}
