import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../../../../../../shared/index';
import { NgModule } from '@angular/core';
import { GetStartedContactUsFormComponent } from './get-started-contact-us-form.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [SharedModule, ReactiveFormsModule, TranslocoModule],
    declarations: [GetStartedContactUsFormComponent],
    exports: [GetStartedContactUsFormComponent]
})
export class GetStartedContactUsFormModule {}
