import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthResult, NbAuthService } from '@nebular/auth';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'nb-oauth2-login',
    template: ''
})
export class Login implements OnDestroy {
    private destroy$ = new Subject<void>();
    constructor(private authService: NbAuthService, private router: Router) {}

    ngOnInit() {
        this.authService
            .authenticate('sso')
            .pipe(takeUntil(this.destroy$))
            .subscribe((authResult: NbAuthResult) => {});
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
