import { GetStartedContactUsConfirmationComponent } from './components/contact-us/contact-us-confirmation/get-started-contact-us-confirmation.component';
import { GetStartedSelectEquipmentComponent } from './components/select-equipment/get-started-select-equipment.component';
import { GetStartedSelectPricingPlanComponent } from './components/select-pricing-plan/get-started-select-pricing-plan.component';
import { GetStartedSignupRequestedComponent } from './components/signup-requested/get-started-signup-requested.component';
import { GetStartedBusinessDetailsComponent } from './components/business-details/get-started-business-details.component';
import { GetStartedEnterEmailComponent } from './components/enter-email/get-started-enter-email.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { GetStartedEnterOrgNumberComponent } from './components/enter-org-number/get-started-enter-org-number.component';
import { GetStartedAccountCreatedComponent } from './components/account-created/get-started-account-created.component';
import { GetStartedContactInfoComponent } from './components/contact-info/get-started-contact-info.component';
import { AuthGuard } from 'app/shared/services/auth-guard.service';
import { GetStartedCompleteBusinessDetailsComponent } from './components/complete-business-details/get-started-complete-business-details.component';
import { GetStartedCompleteSignupComponent } from './components/complete-signup/get-started-complete-signup.component';
import { GetStartedSignupCompletedComponent } from './components/signup-completed/get-started-signup-completed.component';

import { GetStartedContactUsFormContainerPrivateComponent } from './components/contact-us/contact-us-form-container-private/get-started-contact-us-form-container-private.component';
import { GetStartedRedirectComponent } from './get-started-redirect.component';
import { GetStartedOrdrReferralComponent } from './components/ordr-referral/get-started-ordr-referral.component';

const routes: Routes = [
    {
        path: '',
        component: GetStartedRedirectComponent
    },
    {
        path: 'where-did-you-hear-about-us',
        component: GetStartedOrdrReferralComponent
    },
    {
        path: 'enter-org-number',
        component: GetStartedEnterOrgNumberComponent
    },
    {
        path: 'enter-email',
        component: GetStartedEnterEmailComponent
    },
    {
        path: 'contact-us',
        component: GetStartedContactUsFormContainerPrivateComponent
    },
    {
        path: 'contact-us-confirmation',
        component: GetStartedContactUsConfirmationComponent
    },
    {
        path: 'account-created',
        component: GetStartedAccountCreatedComponent
    },
    {
        path: 'enter-business-details',
        component: GetStartedBusinessDetailsComponent
    },
    {
        path: 'complete-business-details',
        canActivate: [AuthGuard],
        component: GetStartedCompleteBusinessDetailsComponent
    },
    {
        path: 'enter-contact-info',
        component: GetStartedContactInfoComponent
    },
    {
        path: 'signup-requested',
        component: GetStartedSignupRequestedComponent
    },
    {
        path: 'complete-signup',
        canActivate: [AuthGuard],
        component: GetStartedCompleteSignupComponent
    },
    {
        path: 'signup-completed',
        //canActivate: [AuthGuard],
        component: GetStartedSignupCompletedComponent
    },
    {
        path: 'select-pricing-plan',
        canActivate: [AuthGuard],
        component: GetStartedSelectPricingPlanComponent
    },
    {
        path: 'select-equipment',
        canActivate: [AuthGuard],
        component: GetStartedSelectEquipmentComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GettingStartedRoutingModule {}
