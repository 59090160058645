export const enum LoadState {
    INIT = 'INIT',
    LOADING = 'LOADING',
    LOADED = 'LOADED'
}

export const enum CreateState {
    INIT = 'INIT',
    CREATING = 'CREATING',
    CREATED = 'CREATED'
}

export const enum AddState {
    INIT = 'INIT',
    ADDING = 'ADDING',
    ADDED = 'ADDED'
}

export const enum RemoveState {
    INIT = 'INIT',
    REMOVING = 'REMOVING',
    REMOVED = 'REMOVED'
}

export const enum EditState {
    INIT = 'INIT',
    EDITING = 'EDITING',
    EDITED = 'EDITED'
}

export const enum LinkState {
    INIT = 'INIT',
    LINKING = 'LINKING',
    LINKED = 'LINKED'
}

export const enum UnlinkState {
    INIT = 'INIT',
    UNLINKING = 'UNLINKING',
    UNLINKED = 'UNLINKED'
}

export interface ErrorState {
    errorMessage: string;
}

export type LoadCallState = LoadState | ErrorState;

export type CreateCallState = CreateState | ErrorState;

export type AddCallState = AddState | ErrorState;

export type RemoveCallState = RemoveState | ErrorState;

export type EditCallState = EditState | ErrorState;

export type LinkCallState = LinkState | ErrorState;

export type UnlinkCallState = UnlinkState | ErrorState;

export const selectError = callState => {
    if (callState && (callState as ErrorState).errorMessage !== undefined) {
        return (callState as ErrorState).errorMessage;
    }

    return null;
};
